import React, { useEffect, useState, useMemo } from 'react'
import { 
  Flex, 
  Content, 
  DateTimePickerV2, 
  Button, 
  Popup, 
  Pane, 
  Text, 
  TextInput, 
  Toast,
  TableV2 } from '@leverege/ui-elements'
import { useDispatch } from 'react-redux'
import { DataSources } from '@leverege/ui-attributes'
import exportToCsv from '../utils/TableUtils'
import { fetchCommandHistory } from '../utils/HistoryUtils'

function CommandsHistoryTable( props ) {
  const { objRef } = props
  
  const dispatch = useDispatch()
  const delegate = DataSources.getDelegate( objRef )

  const [ outboundMessagesList, setOutboundMessagesList ] = useState( [] )
  const [ startTime, setStartTime ] = useState( () => {
    const now = new Date()
    const oneWeekAgo = new Date( now - 7 * 24 * 60 * 60 * 1000 )
    return oneWeekAgo
  } )
  const [ loading, setLoading ] = useState( false )
  const [ fileName, setFileName ] = useState( 'exported_data' )

  const handleTimeChange = ( event ) => {
    const { value } = event
    setStartTime( value )
  }

  useEffect( () => {
    const fetchData = async () => {
      try {
        setLoading( true )
        const accessToken = await dispatch( delegate.get( { accessToken : true } ) )
        const messagesList = await fetchCommandHistory( accessToken.accessToken, startTime.valueOf() )
        setOutboundMessagesList( messagesList )
        setLoading( false )
      } catch ( error ) {
        console.error( 'Error fetching command history', error )
        setLoading( false )
      }
    }
    if ( startTime ) {
      fetchData()
    }
    
  }, [ startTime ] )

  const columns = useMemo( () => [
    {
      Header : 'Command',
      accessor : 'message',
    },
    {
      Header : 'Sent',
      accessor : 'ack',
    }
  ], [] )

  const handleFileNameChange = ( event ) => {
    const { value } = event
    setFileName( value )
  }

  const handleExportToCsv = () => {
    const fullFileName = `${fileName}.csv`
    exportToCsv( outboundMessagesList, columns, fullFileName )
    Toast.success( 'Successfully exported' )
  }

  return (
    <Content>
      <Content.Header>
        <Flex variant="itemBar">
          <Flex variant="rowSpaceAround">
            <DateTimePickerV2 value={startTime} onChange={handleTimeChange}/>
            <Popup icon="fa fa-fw fa-download">
              <Pane variant="contentSection">
                <Text variant="title">Export to CSV</Text>
              </Pane>
              <Pane variant="contentSection"> 
                <Flex variant="colM" style={{ height : '100%' }}>
                  <TextInput
                    variant="small"
                    value={fileName}
                    onChange={handleFileNameChange}/>
                </Flex>                
              </Pane>
              <Pane variant="contentSection">
                <Flex variant="colM">
                  <Button variant="primary" onClick={handleExportToCsv}> Export</Button>
                </Flex>
              </Pane>
            </Popup>
          </Flex>
        </Flex>
      </Content.Header>
      <Content.Area>
        <Flex variant="colM">
          <TableV2
            variant="dataTable"
            data={outboundMessagesList}
            columns={columns}
            loading={loading}
            filterable={false}/>
        </Flex>
      </Content.Area>
    </Content>
  )
}

export default CommandsHistoryTable
