import Path from '@leverege/path'

const firmwareFileGetter = ( file ) => {
  return ( data ) => {
  
    const firmwares = new Path( 'data/versionInfo/files' ).get( data )
    const firmware = firmwares && firmwares[file]
    if ( firmware ) {
      return firmware
    }
  
    return 'Not Available'
  }
}
  
const capitalize = str => str?.charAt( 0 )?.toUpperCase() + str?.slice( 1 )

const getWirelessFirmwareVersion = ( sensor, { device } ) => {
  
  if ( sensor.data ) {
    const isS3P = device.networkAliases?.['siren-marine']?.esn
    const { firmwareVersion } = sensor.data
    if ( !firmwareVersion || firmwareVersion === '' ) {
      return 'Not Reported'
    }
  
    if ( !isS3P ) {
      return firmwareVersion
    }
  
    const parsedVersion = splitByLen( firmwareVersion, 2 )
      .map( part => `${part}`.padStart( 2, '0' ) )
      .map( part => part.toUpperCase() )
      .map( part => replaceAll( part, /[^0-9]/, '9' ) )
      .join( '.' )
    return `00.${parsedVersion}`
  } 
  return ''
    
}
  
function replaceAll( str, reg, replacement ) {
  return str.split( '' ).map( p => p.replace( reg, replacement ) ).join( '' )
}
  
function splitByLen( str, len ) {
  return str.split( '' ).reduce( ( acc, value, index ) => {
    const pos = Math.floor( index / len )
    acc[pos] = acc[pos] || ''
    acc[pos] += value
    return acc
  }, [] )
}

function getWirelessESN( sensorData ) {
  return sensorData?.networkAliases?.['siren-marine']?.wireless
}

export { firmwareFileGetter, capitalize, getWirelessFirmwareVersion, getWirelessESN }
