import DataStore from '@leverege/data-store'

const formatTimeStamp = ( timestamp ) => {
  if ( timestamp == null ) return null
  const timeStamp = new Date( timestamp );
  if ( Number.isNaN( timeStamp.getTime() ) ) return null
  return new Intl.DateTimeFormat( 'en-US', {
    year : 'numeric',
    month : '2-digit',
    day : '2-digit',
    hour : '2-digit',
    minute : '2-digit',
    hour12 : false,
    timeZoneName : 'short'
  } ).format( timeStamp )
}

const setupDeviceStore = async ( accessToken ) => {
  const deviceStore = DataStore.createDeviceStore( accessToken )
  await deviceStore.signIn( accessToken.jwt )
  return deviceStore
}

export const fetchCommandHistory = async ( accessToken, startTime ) => {
  try {
    const deviceStore = await setupDeviceStore( accessToken )

    const outboundMessagesRef = deviceStore
      .outbound()
      .orderByChild( null, 'initiated' )
      .startAt( startTime )

    return new Promise( ( resolve, reject ) => {
      outboundMessagesRef.on( 'value', ( snapshot ) => {
        const outboundMessages = snapshot.val()
        if ( outboundMessages ) {
          const messagesList = []
          Object.entries( outboundMessages ).forEach( ( [ key, message ] ) => {
            const messageData = message.message

            if ( messageData && messageData.command ) {
              const filteredMessage = {
                ack : formatTimeStamp( message.ack ),
                initiated : formatTimeStamp( message.initiated ),
                message : messageData.command,
              }

              messagesList.push( {
                id : key,
                ...filteredMessage,
              } )
            }
          } )

          resolve( messagesList )
        } else {
          resolve( [] )
        }
      } )
    } )
  } catch ( error ) {
    console.error( 'Error creating device store or fetching command history:', error )
    throw error
  }
}

export const fetchLatestSetEnvCommand = async ( accessToken ) => {
  try {
    const deviceStore = await setupDeviceStore( accessToken )

    const outboundMessagesRef = deviceStore
      .outbound()
      .orderByChild( null, 'initiated' )
      .limitToLast( 50 )

    const snapshot = await outboundMessagesRef.once( 'value' )
    const outboundMessages = snapshot.val()

    if ( outboundMessages ) {
      const setEnvCommands = Object.values( outboundMessages )
        .map( message => message?.message )
        .filter( messageData => messageData?.command?.startsWith( 'setEnv,' ) )

      if ( setEnvCommands.length > 0 ) {
        setEnvCommands.sort( ( a, b ) => b.initiated - a.initiated )

        const latestSetEnvCommand = setEnvCommands[0]

        return latestSetEnvCommand.command.toLowerCase().replace( 'setenv,', '' )
      }
    }

    return null
  } catch ( error ) {
    console.error( 'Error fetching latest setEnv command:', error )
    return null
  }
}

export const fetchFirmwareHistory = async ( accessToken, startTime ) => {
  try {
    const deviceStore = await setupDeviceStore( accessToken )

    const outboundMessagesRef = deviceStore
      .outbound()
      .orderByChild( null, 'initiated' )
      .startAt( startTime )

    return new Promise( ( resolve, reject ) => {
      outboundMessagesRef.on( 'value', ( snapshot ) => {
        const outboundMessages = snapshot.val()
        if ( outboundMessages ) {
          const messagesList = []
          Object.entries( outboundMessages ).forEach( ( [ key, message ] ) => {
            const messageData = message.message

            if ( messageData && messageData.path === 'vessel/firmware' ) {
              let firmwareId
              if ( typeof messageData.value === 'string' ) {
                firmwareId = messageData.value
              } else if ( typeof messageData.value === 'object' && messageData.value !== null ) {
                firmwareId = messageData.value.firmwareId
              }

              let messageAck
              if ( !message.ack ) {
                messageAck = message.status
              } else {
                messageAck = formatTimeStamp( message.ack )
              }

              const filteredMessage = {
                ack : messageAck,
                sent : formatTimeStamp( message.sent ),
                firmwareVersion : messageData.value.firmwareVersion,
                firmwareId,
              }

              messagesList.push( {
                id : key,
                ...filteredMessage,
              } )
            }
          } )

          resolve( messagesList )
        } else {
          resolve( [] )
        }
      } )
    } )
  } catch ( error ) {
    console.error( 'Error creating device store or fetching firmware history:', error )
    throw error
  }
}
