/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */ 
import { RoutesUtil as Util } from '@leverege/molten/routes'
import {
  Breadcrumbs,
  Button,
  Checkbox,
  Content,
  Dialog,
  Flex,
  Pane,
  Popup,
  TableV2,
  Text,
  Toast,
} from '@leverege/ui-elements'
import { Item } from '@leverege/ui-elements/lib/esm/Popup'
import { TitleBar } from '@leverege/ui-plugin'
import { push } from 'connected-react-router'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setBoatAlertRecipients } from '../../utils/interface'
import { mergeDeep } from '../../utils/objectHelpers'
import BoatAlertRecipientsDetailsForm from './BoatAlertRecipientsDetailsForm'

function BoatAlertRecipientsScreen( props ) {
  const { match, parentItem, dispatch, reloadData } = props
  const {
    id
  } = parentItem

  const networkName = parentItem?.data?.networkId || null
  const name = parentItem?.data?.networkAliases?.[networkName]?.esn || parentItem.data.name
  const alertRecipients = parentItem.data?.data?.alertRecipients || {}
  const [ selectedRecipients, setSelectedRecipients ] = useState( [] )
  const [ showEditModal, setShowEditModal ] = useState( false )

  const mappedAlertRecipients = Object.entries( alertRecipients ).map(
    ( [ k, v ] ) => ( {
      id : k,
      ...v,
    } ),
  )
  const timeout = async () => new Promise( ( res ) => { setTimeout( res, 500 ) } )

  const rootPath = match ? Util.resolve( '/p/:persona/boat', match ) : ''
  const overViewPath = match ?
    Util.resolve( '/p/:persona/boat/:boatId/info', match ) :
    ''

  const onClickBreadcrumb = ( ctx ) => {
    const isRoot = ctx.data?.root
    dispatch( isRoot ? push( rootPath ) : push( overViewPath ) )
  }

  const onCheck = ( e ) => {
    const boatId = e.data.cell.row.original.id
    const shouldCheck = !selectedRecipients.includes( boatId )

    if ( shouldCheck ) {
      setSelectedRecipients( s => [ ...s, boatId ] )
      return
    }

    const idx = selectedRecipients.indexOf( boatId )
    setSelectedRecipients( s => [ ...s.filter( ( _, i ) => i !== idx ) ] )
  }

  const removeRecipients = async ( ids ) => {
    try {
      const newRecipients = mappedAlertRecipients
        .filter( v => !ids.includes( v.id ) )
        .map( v => [ v.id, v ] )

      await setBoatAlertRecipients( id, Object.fromEntries( newRecipients ) )
      await timeout()
      await reloadData()

      setSelectedRecipients( [] )
      Toast.success( `Recipient${ids?.length > 1 ? 's' : ''} removed!` )
    } catch ( e ) {
      Toast.error( 'Something went wrong. Try again later.' )
      console.log( e )
    }
  }

  const saveRecipients = async ( recipient ) => {
    try {
      const hasId = !!recipient?.id
      const newId = mappedAlertRecipients.length ?
        Number( mappedAlertRecipients.sort( ( a, b ) => a.id - b.id )[mappedAlertRecipients.length - 1]?.id ) + 1 :
        1
      const newRecipient = [ ...mappedAlertRecipients, recipient ].map( ( v ) => {
        return [ v?.id || newId, v ]
      } )
      const newRecipients =
        selectedRecipients.length === 0 ?
          null :
          mappedAlertRecipients
            .map( ( r ) => {
              if (
                selectedRecipients.length > 1 &&
                  selectedRecipients.includes( r.id )
              ) {
                return mergeDeep( r, recipient )
              }
              return r.id === recipient.id ? recipient : r
            } )
            .map( ( v ) => {
              return [ v.id, v ]
            } )

      await setBoatAlertRecipients(
        id,
        Object.fromEntries( newRecipients || newRecipient ),
      )
      await timeout()
      await reloadData()

      setSelectedRecipients( [] )
      setShowEditModal( false )
      Toast.success( hasId || selectedRecipients.length > 1 ? 'Recipient updated!' : 'New recipient saved!' )
    } catch ( e ) {
      Toast.error( 'Something went wrong. Try again later.' )
      console.log( e )
    }
  }

  const columns = [
    {
      id : 'targeted',
      Header : '',
      width : 52,
      minWidth : 52,
      maxWidth : 52,
      Cell : x => (
        <Checkbox
          variant="small"
          onChange={onCheck}
          eventData={x}
          value={selectedRecipients.includes( x.cell.row.original.id )}/>
      )
    },
    {
      Header : 'Name',
      accessor : 'name',
    },
    {
      Header : 'Email',
      accessor : 'email.value',
    },
    {
      Header : 'Email Enabled',
      Cell : x => ( x.cell.row.original?.email?.active ? <b>Enabled</b> : 'Disabled' ),
    },
    {
      Header : 'Phone',
      accessor : 'mobile.value',
    },
    {
      Header : 'Phone Enabled',
      Cell : x => ( x.cell.row.original?.mobile?.active ? <b>Enabled</b> : 'Disabled' ),
    },
    {
      Header : 'Daily Status Enabled',
      Cell : x => ( x.cell.row.original?.dailyReportsEnabled ? <b>Enabled</b> : 'Disabled' ),
    },
  ]

  return (
    <Content>
      <Pane variant="topNavBreadcrumbs">
        <Breadcrumbs
          variant="topNavBreadcrumbs"
          onClick={onClickBreadcrumb}
          crumbs={[
            { name : process.env.BOAT_BREADCRUMB_NAME, root : true },
            { name, device : true },
            { name : 'Alert Recipients' },
          ]}/>
      </Pane>
      <Content.Header>
        <TitleBar
          variant="screenTitle"
          title="Alert Recipients"
          icon="fa-solid fa-warning">
          <Popup
            open
            closeOnEscape
            closeOnDocumentClick
            iconOn="fa-solid fa-ellipsis-vertical"
            iconOff="fa-solid fa-ellipsis-vertical">
            <Popup.Header>
              {`${process.env.CUSTOM_ACTIONS_NAME} - Alert Recipients Actions`}
            </Popup.Header>
            {selectedRecipients.length === 0 && (
              <Item
                icon="fa-light fa-plus"
                onClick={() => setShowEditModal( true )}>
                Add Recipient
              </Item>
            )}
            {selectedRecipients.length > 0 && (
              <Item
                icon="fa-light fa-pen"
                onClick={() => setShowEditModal( true )}
                disabled={!selectedRecipients.length}>
                Edit Selected Recipient
              </Item>
            )}
            {selectedRecipients.length >= 1 && (
              <Item
                icon="fa-light fa-trash"
                onClick={() => removeRecipients( selectedRecipients )}>
                Delete {selectedRecipients.length} Selected Recipient
                {selectedRecipients.length > 1 ? 's' : ''}
              </Item>
            )}
          </Popup>
        </TitleBar>
      </Content.Header>
      <Content.Area>
        <TableV2
          variant="dataTable"
          columns={columns}
          data={mappedAlertRecipients}
          filterable={false}
          noDataText="---"/>
        <Dialog
          onClose={() => setShowEditModal( false )}
          closeOnDocumentClick
          closeOnEscape
          show={showEditModal}>
          <Pane style={{ width : 400 }}>
            <Flex
              variant="default"
              justify="space-between"
              align="stretch"
              style={{
                padding : '10px 5px 0px 13px',
                boxShadow : '0px 1px 3px rgba(41, 50, 61, 0.16)',
              }}>
              <Text variant="inspectorTitle" style={{ color : '#28548D' }}>
                Edit Alert Recipient Details
              </Text>
              <Button
                variant="icon"
                style={{ position : 'relative', bottom : '6px' }}
                onClick={() => setShowEditModal( false )}>
                <i className="fa-solid fa-close" />
              </Button>
            </Flex>
            <BoatAlertRecipientsDetailsForm
              initialValues={
                selectedRecipients.length > 1 ?
                  {} :
                  mappedAlertRecipients.find(
                    v => v.id === selectedRecipients[0],
                  ) || {}
              }
              selectedRecipients={selectedRecipients}
              onCancel={() => setShowEditModal( false )}
              onSave={payload => saveRecipients( payload )}/>
          </Pane>
        </Dialog>
      </Content.Area>
    </Content>
  )
}

export default connect( ( state ) => {
  return {}
} )( BoatAlertRecipientsScreen )
