/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useReducer } from 'react'
import { 
  Content,
  TableV2,
  TabPane,
  Checkbox,
  Toast,
  Popup } from '@leverege/ui-elements'
import { Item } from '@leverege/ui-elements/lib/esm/Popup'
import { TitleBar } from '@leverege/ui-plugin'
import { getMetaData, getFirmwareVersions, setOtaFlag } from '../utils/interface'
import ConfirmationDialog from '../components/ConfirmationDialog'
import FirmwareEditMenu from './FirmwareEditMenu'

const S3_PLUS_TARGET = 's3+TargetFirmwareVersion'
const S3P_TARGET = 's3pTargetFirmwareVersion'

const initialState = {
  data : { [S3_PLUS_TARGET] : [], [S3P_TARGET] : [] },
  showDialog : false,
  editData : {},
  firmwareOptions : [],
  showConfirmationDialog : false,
  showDeleteDialog : false,
  previousTarget : '',
  activeTab : { value : S3_PLUS_TARGET },
  isDialogExpanded : false,
  isEditMode : true,
  selectedRows : []
}

const reducer = ( state, action ) => {
  switch ( action.type ) {
    case 'SET_DATA':
      return { ...state, data : action.payload }
    case 'SET_SHOW_DIALOG':
      return { ...state, showDialog : action.payload }
    case 'SET_EDIT_DATA':
      return { ...state, editData : action.payload }
    case 'SET_FIRMWARE_OPTIONS':
      return { ...state, firmwareOptions : action.payload }
    case 'SET_SHOW_CONFIRMATION_DIALOG':
      return { ...state, showConfirmationDialog : action.payload }
    case 'SET_SHOW_DELETE_DIALOG':
      return { ...state, showDeleteDialog : action.payload }
    case 'SET_PREVIOUS_TARGET':
      return { ...state, previousTarget : action.payload }
    case 'SET_ACTIVE_TAB':
      return { ...state, activeTab : action.payload }
    case 'SET_IS_DIALOG_EXPANDED':
      return { ...state, isDialogExpanded : action.payload }
    case 'SET_IS_EDIT_MODE':
      return { ...state, isEditMode : action.payload }
    case 'SET_SELECTED_ROWS':
      return { ...state, selectedRows : action.payload }
    case 'CLEAR_SELECTED_ROWS':
      return { ...state, selectedRows : [] }
    default:
      return state
  }
}

export default function OtaFlagScreen() {
  const [ state, dispatch ] = useReducer( reducer, initialState )

  const fetchData = async () => {
    try {
      const metaData = await getMetaData()
      dispatch( { type : 'SET_DATA', payload : metaData } )
    } catch ( error ) {
      console.error( 'Error fetching metadata:', error )
      Toast.error( 'Failed to fetch metadata' )
    }
  }

  const fetchFirmwareVersions = async () => {
    try {
      const firmwareData = await getFirmwareVersions()
      const filteredData = firmwareData.filter( fw => !fw.data.archived )
      const firmOpts = filteredData.map( fw => ( { version : fw.data.version, id : fw.id } ) )
      dispatch( { type : 'SET_FIRMWARE_OPTIONS', payload : firmOpts } )
    } catch ( error ) {
      console.error( 'Error fetching firmware versions:', error )
      Toast.error( 'Failed to fetch firmware versions' )
    }
  }

  useEffect( () => {
    fetchFirmwareVersions()
    fetchData()
  }, [] )

  const handleRowSelect = ( row ) => {
    const selectedRows = state.selectedRows.includes( row ) ?
      state.selectedRows.filter( r => r !== row ) :
      [ ...state.selectedRows, row ]
    dispatch( { type : 'SET_SELECTED_ROWS', payload : selectedRows } )
  }

  const columns = [
    {
      id : 'targeted',
      Header : '',
      width : 52,
      minWidth : 52,
      maxWidth : 52,
      Cell : ( { row } ) => (
        <Checkbox
          variant="small"
          eventData={row}
          value={state.selectedRows.includes( row.original )}
          onChange={() => handleRowSelect( row.original )}/>
      )
    },
    { Header : 'Major', accessor : 'major' },
    { Header : 'Minor', accessor : 'minor' },
    { Header : 'Target', accessor : 'target' }
  ]

  const handleEdit = async () => {
    if ( state.selectedRows.length === 1 ) {
      const data = state.selectedRows[0]
      dispatch( { type : 'SET_EDIT_DATA', payload : { ...data, tabId : state.activeTab.value } } )
      dispatch( { type : 'SET_SHOW_DIALOG', payload : true } )
      dispatch( { type : 'SET_PREVIOUS_TARGET', payload : data.target } )
      dispatch( { type : 'SET_IS_EDIT_MODE', payload : true } )
    }
  }

  const handleAddNewRow = () => {
    dispatch( {
      type : 'SET_EDIT_DATA',
      payload : {
        major : '',
        minor : '',
        target : '',
        targetId : '',
        tabId : state.activeTab.value
      }
    } )
    dispatch( { type : 'SET_SHOW_DIALOG', payload : true } )
    dispatch( { type : 'SET_IS_EDIT_MODE', payload : false } )
  }

  const handleCloseDialog = () => {
    dispatch( { type : 'SET_SHOW_DIALOG', payload : false } )
    dispatch( { type : 'SET_IS_DIALOG_EXPANDED', payload : false } )
  }

  const handleTabClick = ( tab ) => {
    dispatch( { type : 'SET_ACTIVE_TAB', payload : tab } )
    dispatch( { type : 'CLEAR_SELECTED_ROWS' } )
  }

  const handleSubmit = ( editData ) => {
    dispatch( { type : 'SET_EDIT_DATA', payload : editData } )
    dispatch( { type : 'SET_SHOW_CONFIRMATION_DIALOG', payload : true } )
  }

  const handleDelete = () => {
    dispatch( { type : 'SET_SHOW_DELETE_DIALOG', payload : true } )
  }

  const updateMetaData = async ( newMetaData, confirmationDialog, successMessages, ErrorMessage ) => {
    try {
      await setOtaFlag( newMetaData )
      dispatch( { type : 'SET_SHOW_DELETE_DIALOG', payload : false } )
      dispatch( { type : confirmationDialog, payload : false } )
      dispatch( { type : 'CLEAR_SELECTED_ROWS' } )
      Toast.success( successMessages )
    } catch ( error ) {
      console.error( ErrorMessage, error )
      Toast.error( ErrorMessage, error )
    }
  }

  const handleConfirmDelete = async () => {
    const newMetaData = { ...state.data }
    const targetKey = state.activeTab.value
    newMetaData[targetKey] = newMetaData[targetKey].filter( item => !state.selectedRows.includes( item ) )

    await updateMetaData( newMetaData, 'SET_SHOW_DELETE_DIALOG', 'OTA flag deleted', 'Error deleting OTA flag:' )
  }

  const handleConfirmSave = async () => {
    const newMetaData = { ...state.data }
    const updateTarget = item => (
      item.major === state.editData.major && item.minor === state.editData.minor ?
        { ...item, target : state.editData.target, targetId : state.editData.targetId } :
        item
    )
    const targetKey = state.editData.tabId === S3_PLUS_TARGET ? S3_PLUS_TARGET : S3P_TARGET
    
    if ( state.isEditMode ) {
      newMetaData[targetKey] = newMetaData[targetKey].map( updateTarget )
    } else {
      const { tabId, ...newEntry } = state.editData
      newMetaData[targetKey] = [ ...newMetaData[targetKey], newEntry ]
    }

    await updateMetaData( newMetaData, 'SET_SHOW_CONFIRMATION_DIALOG', 'OTA flag updated', 'Error setting OTA flag:' )
  }

  return (
    <Content>
      <Content.Header>
        <TitleBar
          variant="screenTitle"
          title="OTA Flag"
          icon="fa-solid fa-microchip">
          <Popup
            open
            closeOnEscape
            closeOnDocumentClick
            iconOn="fa-solid fa-ellipsis-vertical"
            iconOff="fa-solid fa-ellipsis-vertical">
            <Popup.Header>
              OTA Flag actions
            </Popup.Header>
            <Item
              icon="fa-light fa-plus"
              onClick={handleAddNewRow}>
              Add New OTA Flag
            </Item>
            {state.selectedRows.length === 1 && (
              <Item
                icon="fa-light fa-edit"
                onClick={handleEdit}>
                Edit OTA Flag
              </Item>
            )}
            {state.selectedRows.length > 0 && (
              <Item icon="fa-light fa-trash" onClick={handleDelete}>
                Delete OTA Flag
              </Item>
            )}
          </Popup>
        </TitleBar>
      </Content.Header>
      <Content.Area>
        {state.data ? (
          <TabPane
            activeTab={state.activeTab.value}
            onTabClicked={handleTabClick}>
            <TabPane.Tab
              tabId={S3_PLUS_TARGET}
              tabName="S3+ Target Firmware Version">
              <TableV2 
                variant="dataTable"
                data={state.data[S3_PLUS_TARGET]}
                columns={columns}
                filterable={false}
                sortable={false}/>
            </TabPane.Tab>
            <TabPane.Tab
              tabId={S3P_TARGET}
              tabName="S3P Target Firmware Version">
              <TableV2
                variant="dataTable"
                data={state.data[S3P_TARGET]}
                columns={columns}
                filterable={false}
                sortable={false}/>
            </TabPane.Tab>
          </TabPane>
        ) : (
          <p>Loading...</p>
        )}
        {state.showDialog && (
          <FirmwareEditMenu
            show={state.showDialog}
            onClose={handleCloseDialog}
            onSubmit={handleSubmit}
            firmwareOptions={state.firmwareOptions}
            editData={state.editData}
            isDialogExpanded={state.isDialogExpanded}
            dispatch={dispatch}
            isEditMode={state.isEditMode}
            data={state.data}
            activeTab={state.activeTab.value}/>
        )}
        <ConfirmationDialog
          show={state.showConfirmationDialog}
          headerText="Confirm Firmware Target Change"
          bodyText={state.isEditMode ? 
            `Are you sure you want to set the firmware target from ${state.previousTarget} to ${state.editData.target}?` :
            'Are you sure you want to add this new OTA flag'} 
          onConfirm={handleConfirmSave}
          onDeny={() => dispatch( { type : 'SET_SHOW_CONFIRMATION_DIALOG', payload : false } )}/>
        <ConfirmationDialog
          show={state.showDeleteDialog}
          headerText="Confirm Delete"
          bodyText="Are you sure you want to delete the selected OTA flag(s)?"
          onConfirm={handleConfirmDelete}
          onDeny={() => dispatch( { type : 'SET_SHOW_DELETE_DIALOG', payload : false } )}/>
      </Content.Area>
    </Content>
  )
}
