import Molten from '@leverege/molten'
import { GlobalState, UI } from '@leverege/ui-redux'
import debounce from 'lodash.debounce'
import { useEffect } from 'react'

function GlobalstarHiddenDataViewer( { parentItem, item, ...rest } ) {
  const systemId = Molten.getConfig( 'api/systemId' )

  const getSubscriptionInfo = debounce( async () => {
    const ownerEmail = parentItem?.data?.data?.email
    const boatId = item?.id

    if ( !ownerEmail || !boatId ) return;

    const data = await GlobalState.dispatch( async ( dispatch, getState, { api } ) => {
      try {
        const response = await api.interface( systemId, 'boat' ).obj( boatId ).grp( 'globalstar' ).list()
        const items = response?.items 
        if ( items.length ) {
          const { data } = items[0]
          const gbId = data.mobileId
          return api.system( systemId ).srv( 'globalstar' ).get( `${gbId}/subscription/${ownerEmail}` )
        }

        return response
      } catch ( error ) {
        console.error( error )
        console.log( 'Error trying to fetch Globalstar subscription', error )
      }
    } )

    GlobalState.dispatch( UI.set( 'globalstarSubscriptionInfo', data ) )
  } )

  useEffect( () => getSubscriptionInfo(), [] )
  
  return null
}

export default {
  type : 'GlobalstarHiddenDataViewer',
  name : 'Globalstar Subscripion Info',
  matches : [ { objectType : 'boat' } ],
  icon : 'fa-solid fa-grid-2',
  location : 'hidden',
  handles : () => true,
  component : GlobalstarHiddenDataViewer,
}
