import React from 'react'
import DevicesUnderUserScreen from './DeviceUnderUserScreen'

export default {
  id : 'siren.users.devices',
  matches : { client : 'Main' },
  exact : true,
  path : '/customers/devicesUnderUser/:userId',
  default : false,
  render : ctx => React.createElement( DevicesUnderUserScreen, ctx )
}  
