/* eslint-disable import/no-extraneous-dependencies */
import { Breadcrumbs, Content, Dialog, Pane, Popup, Flex, Text } from '@leverege/ui-elements'
import { Item } from '@leverege/ui-elements/lib/esm/Popup'
import { TitleBar } from '@leverege/ui-plugin'
import React, { useState } from 'react'
import { RoutesUtil as Util } from '@leverege/molten/routes'
import { GlobalState, } from '@leverege/ui-redux'
import { push } from 'connected-react-router'
import CommandsHistoryTable from '../../components/CommandHistoryTable'
import DeviceCommandsOverview from '../../components/DeviceCommandsOverview'

function DeviceCommandsScreen( props ) {
  const { parentItem, match } = props
  const objRef = {
    type : parentItem.type,
    id : parentItem.id,
    ref : parentItem.ref.substring( 0, parentItem.ref.lastIndexOf( '/' ) )
  }

  const networkName = parentItem?.data?.networkId || null
  const name = parentItem?.data?.networkAliases?.[networkName]?.esn || parentItem.data.name
  const [ showCommandModal, setShowCommandModal ] = useState( false )

  const rootPath = match ? Util.resolve( '/p/:persona/boat', match ) : ''
  const overViewPath = match ? Util.resolve( '/p/:persona/boat/:boatId/info', match ) : ''

  const onClickBreadCrumb = ( ctx ) => {
    const isRoot = ctx.data?.root
    GlobalState.dispatch( isRoot ? push( rootPath ) : push( overViewPath ) )
  }

  const handleCloseDialog = () => {
    setShowCommandModal( false )
  }

  return (
    <Content>
      <Pane variant="topNavBreadcrumbs">
        <Breadcrumbs
          variant="topNavBreadcrumbs"
          onClick={onClickBreadCrumb}
          crumbs={[ 
            { name : process.env.BOAT_BREADCRUMB_NAME, root : true }, 
            { name, device : true }, 
            { name : 'Device Commands History' }
          ]}/>
      </Pane>
      <Content.Header>
        <TitleBar
          variant="screenTitle"
          title="Device Commands"
          icon="fa-solid fa-command">
          <Popup
            open
            closeOnEscape
            closeOnDocumentClick
            iconOn="fa-solid fa-ellipsis-vertical"
            iconOff="fa-solid fa-ellipsis-vertical">
            <Popup.Header>
              {`${process.env.CUSTOM_ACTIONS_NAME} - Device Commands`}
            </Popup.Header>
            <Item
              icon="fa-light fa-plus"
              onClick={() => setShowCommandModal( true )}>
              Send command
            </Item>
          </Popup>
        </TitleBar>
      </Content.Header>
      <Content.Area>
        <CommandsHistoryTable objRef={objRef}/>
        <Dialog
          onClose={handleCloseDialog}
          closeOnDocumentClick
          closeOnEscape
          show={showCommandModal}>
          <Pane style={{ width : 400 }}>
            <Flex 
              justify="space-between"
              style={{
                padding : '8px 16px 8px 16px',
                boxShadow : '0px 2px 3px rgba(41, 50, 61, 0.16)',
                borderBottom : '2px solid #e7eaef'
              }}>
              <Text variant="dialogTitle" icon="fa-solid fa-command">Device Commands</Text>
            </Flex>
            <DeviceCommandsOverview 
              parentItem={parentItem} 
              objRef={objRef}
              onCloseDialog={handleCloseDialog}/>
          </Pane>
          
        </Dialog>
      </Content.Area>
    </Content>
  )
}

export default DeviceCommandsScreen
