export default function exportToCsv( data, columns, filename ) {
  const escapeCsvValue = ( val ) => {
    const strVal = String( val )
    if ( strVal && ( strVal.includes( ',' ) || strVal.includes( '\n' ) || strVal.includes( '"' ) ) ) {
      return `"${strVal.replace( /"/g, '""' )}"`
    }
    return val
  }

  const headers = columns.map( col => col.Header )
  const rows = data.map( row => columns.map( column => escapeCsvValue( row[column.accessor] ) ).join( ',' )
  )

  const csvContent = [
    headers.join( ',' ),
    ...rows
  ].join( '\n' )

  const encodedUri = encodeURI( `data:text/csv;charset=utf-8,${csvContent}` )
  const link = document.createElement( 'a' )
  link.setAttribute( 'href', encodedUri )
  link.setAttribute( 'download', filename )
  document.body.appendChild( link )
  link.click()
  document.body.removeChild( link )
}
