/* eslint-disable no-console */
import React, { useState } from 'react'
import { Dialog, Flex, Text, Selector, Button, TextInput, Toast, Pane, Label } from '@leverege/ui-elements'

function FirmwareEditMenu( { 
  show,
  onClose,
  onSubmit,
  firmwareOptions,
  editData,
  isDialogExpanded,
  dispatch,
  isEditMode,
  activeTab,
  data
} ) {
  const [ localEditData, setLocalEditData ] = useState( editData )

  const handleSave = async () => {
    try {
      const existingFlags = data[activeTab].some(
        item => item.major === localEditData.major && item.minor === localEditData.minor
      )
      if ( !isEditMode && existingFlags ) {
        Toast.error( 'Major and Minor values must be unique.' )
        return
      }
      onSubmit( localEditData )
    } catch ( error ) {
      console.error( 'Error saving OTA flag:', error )
      Toast.error( 'Error saving OTA flag:', error )
    }
  }

  const dialogBodyStyle = {
    gap : 4,
    padding : 10,
    background : '#f3f5f6',
    boxShadow : '0px 1px 3px rgba(41, 50, 61, 0.16)',
    borderBottom : '1px solid #f3f5f6',
    borderTop : '1px solid #f3f5f6'
  }

  const dialogFooterBody = {
    float : 'right',
    gap : 14
  }

  return (
    <Dialog show={show} onClose={onClose} onSubmit={onSubmit}>
      <Pane style={{ width : 400 }}>
        <Flex
          justify="space-between"
          style={{
            padding : '8px 16px 8px 16px',
            boxShadow : '0px 2px 3px rgba(41, 50, 61, 0.16)',
            borderBottom : '2px solid #e7eaef'
          }}>
          <Text 
            variant="dialogTitle" 
            icon="fa-solid fa-microchip">
            {isEditMode ? 'Edit OTA Flag' : 'New OTA Flag'}
          </Text>
        </Flex>
        <Flex variant="colM">
          <Flex
            variant="col"
            style={dialogBodyStyle}>
            <Flex variant="colS" style={{ height : isDialogExpanded ? '425px' : 'initial' }}>
              <Label>Major:</Label>
              <TextInput 
                value={localEditData.major} 
                disabled={isEditMode}
                onChange={e => setLocalEditData( { ...localEditData, major : e.value } )} />
              <Label>Minor:</Label>
              <TextInput 
                value={localEditData.minor} 
                disabled={isEditMode}
                onChange={e => setLocalEditData( { ...localEditData, minor : e.value } )} />
              <Label>Target:</Label>
              <Selector
                values={firmwareOptions}
                value={localEditData.targetId}
                nameKey="version"
                valueKey="id"
                placeholder="Select Firmware Version"
                onChange={( e ) => {
                  const selectedFirmware = firmwareOptions.find( fw => fw.id === e.value );
                  setLocalEditData( {
                    ...localEditData,
                    target : selectedFirmware.version,
                    targetId : selectedFirmware.id
                  } )
                }}/>
            </Flex>
          </Flex>
          <div style={{ padding : '10px 16px 10px 16px' }}>
            <Flex
              alignSelf="flex-end"
              style={dialogFooterBody}>
              <Flex>
                <Button onClick={onClose} variant="secondary">Cancel</Button>
              </Flex>
              <Flex>
                <Button variant="primary" onClick={handleSave}>Save Changes</Button>
              </Flex>
            </Flex>
          </div>
        </Flex>
      </Pane>
    </Dialog>
  )
}

export default FirmwareEditMenu
