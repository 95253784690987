/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import { Content, Toast, Flex, TextInput, Button, TableV2 } from '@leverege/ui-elements'
import { TitleBar } from '@leverege/ui-plugin'
import { GlobalState } from '@leverege/ui-redux'
import { push } from 'connected-react-router'
import { getBuildUsers, getUserByEmail } from '../utils/interface'
import TableFooter from '../components/TableFooter'

const formatTimeStamp = ( timestamp ) => {
  if ( timestamp == null ) return null
  const timeStamp = new Date( timestamp )
  if ( Number.isNaN( timeStamp.getTime() ) ) return null
  return new Intl.DateTimeFormat( 'en-US', {
    year : 'numeric',
    month : '2-digit',
    day : '2-digit',
    hour : '2-digit',
    minute : '2-digit',
    hour12 : false,
    timeZoneName : 'short'
  } ).format( timeStamp )
}

function CustomersScreen( props ) {
  const [ userData, setUserData ] = useState( [] )
  const [ searchInput, setSearchInput ] = useState( '' )
  const [ loading, setLoading ] = useState( false )
  const [ currentPage, setCurrentPage ] = useState( 0 )
  const [ pageSize, setPageSize ] = useState( 100 )
  const [ pages, setPages ] = useState( null )

  const handleNavigate = ( userId ) => {
    GlobalState.dispatch( push( `/customers/devicesUnderUser/${userId}` ) )
  }

  const handleSearch = async () => {
    const filtered = await getUserByEmail( searchInput )
    setUserData( filtered )
  }

  const columns = [
    {
      Header : 'Email',
      Cell : x => (
        <Button 
          variant="linkTable" 
          onClick={() => handleNavigate( x.cell.row.original.userId )}>
          {x.cell.row.original.email}
        </Button>
      )
    },
    {
      Header : 'Username',
      accessor : 'username'
    },
    {
      Header : 'Name',
      accesor : 'name'
    },
    {
      Header : 'Last Login',
      accessor : 'lastLogin'
    },
    {
      Header : 'Created',
      accessor : 'created'
    }
  ]

  useEffect( () => {
    const fetchData = async () => {
      setLoading( true )
      try {
        const data = await getBuildUsers( pageSize, currentPage + 1 )
        const users = data.items?.map( user => ( {
          email : user.email,
          name : user.name,
          username : user.username || user.id,
          created : formatTimeStamp( user.created ),
          lastLogin : formatTimeStamp( user.lastLogin ),
          lastVerify : formatTimeStamp( user.lastVerify ),
          userId : user.id,
          id : user.id
        } ) )
        setUserData( users )
        const totalUsers = data.total
        setPages( Math.ceil( totalUsers / pageSize ) )
      } catch ( error ) {
        console.error( 'Error fetching data', error )
        Toast.error( 'Error fetching customer data' )
      } finally {
        setLoading( false )
      }
    }

    fetchData()
  }, [ currentPage, pageSize ] )

  const searchInputStyle = {
    width : '300px',
  }

  return (
    <Content>
      <Content.Header>
        <TitleBar
          variant="screenTitle"
          title="Customers"
          icon="fa-solid fa-users">
          <Flex variant="screenTitle" justifyContent="center">
            <TextInput 
              placeHolder="search by email"
              value={searchInput}
              onChange={e => setSearchInput( e.value )}
              variant="formInput"
              style={searchInputStyle}/>
            <Button variant="secondary" onClick={handleSearch}>Search</Button>
          </Flex>
        </TitleBar>
      </Content.Header>
      <Content.Area>
        <TableV2
          variant="dataTable"
          data={userData}
          columns={columns}
          loading={loading}
          paginate
          FooterComponent={() => (
            <TableFooter
              pageIndex={currentPage}
              pageSize={pageSize}
              canNextPage={currentPage < pages - 1}
              canPreviousPage={currentPage > 0}
              setPageSize={( size ) => {
                setPageSize( size )
                setCurrentPage( 0 )
              }}
              gotoPage={newPageIndex => setCurrentPage( newPageIndex )}/>
          )}/>
      </Content.Area>
    </Content>
  )
}

export default CustomersScreen
