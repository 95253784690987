import Theme from './Theme.css'
import Body from './Body.css'
import BodySupport from './BodySupport'
import Breadcrumbs from './Breadcrumbs.css'
import BreadcrumbsSupport from './BreadcrumbsSupport'
import Button from './Button.css'
import ButtonSupport from './ButtonSupport'
import Checkbox from './Checkbox.css'
import CheckboxSupport from './CheckboxSupport'
import Combobox from './Combobox.css'
import ComboboxSupport from './ComboboxSupport'
import CompositeTextInput from './CompositeTextInput.css'
import CompositeTextInputSupport from './CompositeTextInputSupport'
import DataBlock from './DataBlock.css'
import DataBlockSupport from './DataBlockSupport'
import DateTimePicker from './DateTimePicker.css'
import DateTimePickerSupport from './DateTimePickerSupport'
import DateTimePickerV2 from './DateTimePickerV2.css'
import DateTimePickerV2Support from './DateTimePickerV2Support'
import DateTimeRangePicker from './DateTimeRangePicker.css'
import DateTimeRangePickerSupport from './DateTimeRangePickerSupport'
import Dropdown from './Dropdown.css'
import DropdownSupport from './DropdownSupport'
import ElementSupport from './ElementSupport'
import Flex from './Flex.css'
import FlexSupport from './FlexSupport'
import Label from './Label.css'
import LabelSupport from './LabelSupport'
import List from './List.css'
import ListSupport from './ListSupport'
import MultiSelect from './MultiSelect.css'
import MultiSelectSupport from './MultiSelectSupport'
import MultiSelector from './MultiSelector.css'
import MultiSelectorSupport from './MultiSelectorSupport'
import Pane from './Pane.css'
import PaneSupport from './PaneSupport'
import Popup from './Popup.css'
import PopupSupport from './PopupSupport'
import Portal from './Portal.css'
import PortalSupport from './PortalSupport'
import ProgressBar from './ProgressBar.css'
import ProgressBarSupport from './ProgressBarSupport'
import PropertyGrid from './PropertyGrid.css'
import PropertyGridSupport from './PropertyGridSupport'
import RadioButton from './RadioButton.css'
import RadioButtonSupport from './RadioButtonSupport'
import Slider from './Slider.css'
import SliderSupport from './SliderSupport'
import SplitPane from './SplitPane.css'
import SplitPaneSupport from './SplitPaneSupport'
import TabPane from './TabPane.css'
import TabPaneSupport from './TabPaneSupport'
import Table from './Table.css'
import TableSupport from './TableSupport'
import TableV2 from './TableV2.css'
import TableV2Support from './TableV2Support'
import Text from './Text.css'
import TextSupport from './TextSupport'
import TextArea from './TextArea.css'
import TextAreaSupport from './TextAreaSupport'
import TextInput from './TextInput.css'
import TextInputSupport from './TextInputSupport'
import Toast from './Toast.css'
import ToastSupport from './ToastSupport'
import Toggle from './Toggle.css'
import ToggleSupport from './ToggleSupport'
import ToggleButton from './ToggleButton.css'
import ToggleButtonSupport from './ToggleButtonSupport'
import VTable from './VTable.css'
import VTableSupport from './VTableSupport'


function addScript( src ) {
  const script = document.createElement( "script" )
  script.crossOrigin = "anonymous"
  script.src = src
  document.head.appendChild( script )
}

addScript( 'https://kit.fontawesome.com/8846456f0e.js' )


const styles = {
  Body,
  Breadcrumbs,
  Button,
  Checkbox,
  Combobox,
  CompositeTextInput,
  DataBlock,
  DateTimePicker,
  DateTimePickerV2,
  DateTimeRangePicker,
  Dropdown,
  Flex,
  Label,
  List,
  MultiSelect,
  MultiSelector,
  Pane,
  Popup,
  Portal,
  ProgressBar,
  PropertyGrid,
  RadioButton,
  Slider,
  SplitPane,
  TabPane,
  Table,
  TableV2,
  Text,
  TextArea,
  TextInput,
  Toast,
  Toggle,
  ToggleButton,
  VTable
}
const supports = {
  Body : BodySupport,
  Breadcrumbs : BreadcrumbsSupport,
  Button : ButtonSupport,
  Checkbox : CheckboxSupport,
  Combobox : ComboboxSupport,
  CompositeTextInput : CompositeTextInputSupport,
  DataBlock : DataBlockSupport,
  DateTimePicker : DateTimePickerSupport,
  DateTimePickerV2 : DateTimePickerV2Support,
  DateTimeRangePicker : DateTimeRangePickerSupport,
  Dropdown : DropdownSupport,
  Element : ElementSupport,
  Flex : FlexSupport,
  Label : LabelSupport,
  List : ListSupport,
  MultiSelect : MultiSelectSupport,
  MultiSelector : MultiSelectorSupport,
  Pane : PaneSupport,
  Popup : PopupSupport,
  Portal : PortalSupport,
  ProgressBar : ProgressBarSupport,
  PropertyGrid : PropertyGridSupport,
  RadioButton : RadioButtonSupport,
  Slider : SliderSupport,
  SplitPane : SplitPaneSupport,
  TabPane : TabPaneSupport,
  Table : TableSupport,
  TableV2 : TableV2Support,
  Text : TextSupport,
  TextArea : TextAreaSupport,
  TextInput : TextInputSupport,
  Toast : ToastSupport,
  Toggle : ToggleSupport,
  ToggleButton : ToggleButtonSupport,
  VTable : VTableSupport
}
const color = {
  "base025": "#f9fafb",
  "base050": "#f4f7fd",
  "base1": "rgba( 128, 128, 128, 1.0 )",
  "base100": "#e7eaef",
  "base200": "#ced6de",
  "base300": "#acb7c3",
  "base400": "#95a2b2",
  "base500": "#67798e",
  "base600": "#495a6e",
  "base700": "#394756",
  "base800": "#29323d",
  "brand025": "#ebf1fd",
  "brand050": "#d8e3fc",
  "brand100": "#bed0f9",
  "brand200": "#8cabf4",
  "brand300": "#5e87ee",
  "brand400": "#3868e5",
  "brand500": "#194edc",
  "brand600": "#0039cf",
  "brand700": "#002fbf",
  "brand800": "#0024ac",
  "brand900": "#001b98",
  "danger050": "#ffebed",
  "danger100": "#fdd8dd",
  "danger500": "#cb3c48",
  "danger700": "#aa2a30",
  "danger900": "#65171c",
  "detailEvenRow": "rgba(243, 245, 246, 0.46)",
  "detailOddRow": "transparent",
  "dropTarget": "#e7eaef",
  "graphBackground": "#ffffff",
  "graphSeries1": "#42c6c8",
  "graphSeries2": "#7d63f7",
  "graphSeries3": "#382cdd",
  "graphSeries4": "#f8d83f",
  "graphSeries5": "#48bd69",
  "graphSeries6": "#ea7b59",
  "graphSeries7": "#e788d2",
  "graphThreshold": "#aa2a30",
  "graphXAxisLabel": "#394756",
  "graphXAxisLine": "#ced6de",
  "graphXTickLabel": "#394756",
  "graphYAxisLabel": "#394756",
  "graphYAxisLine": "#ced6de",
  "graphYTickLabel": "#394756",
  "linkHover": "#0044cc",
  "linkNormal": "#0f5dfa",
  "linkVisited": "#5288f4",
  "secondaryBrand050": "#ddf8f8",
  "secondaryBrand100": "#baf2f2",
  "secondaryBrand500": "#30d8d8",
  "secondaryBrand700": "#1d9a9a",
  "shadowBase": "rgba(41, 50, 61, 0.16)",
  "success050": "#edfdf8",
  "success100": "#dafbf0",
  "success300": "#1ae5a1",
  "success500": "#15b982",
  "success700": "#0e815a",
  "success900": "#084934",
  "tableRollover": "#ebf1fd",
  "tableRolloverSelected": "#8cabf4",
  "tableSelected": "#bed0f9",
  "tableSorted": "rgba(190, 208, 249, 0.2)",
  "tertiaryBrandA": "#15b982",
  "tertiaryBrandA300": "#ff9f5f",
  "tertiaryBrandA500": "#f67a28",
  "tertiaryBrandB": "#ffb647",
  "tertiaryBrandB300": "#fdcd84",
  "tertiaryBrandB500": "#ffb647",
  "tertiaryBrandC": "#f67a28",
  "tertiaryBrandC300": "#42d3a3",
  "tertiaryBrandC500": "#15b982",
  "tertiaryBrandD": "#0f5dfa",
  "tertiaryBrandD300": "#4f89ff",
  "tertiaryBrandD500": "#0f5dfa",
  "warning050": "#fef3eb",
  "warning100": "#fde7d8",
  "warning500": "#f67a28",
  "warning700": "#c45308",
  "warning900": "#8d3b04",
  "white": "#ffffff"
}
const icon = {
  "aggregations-bar-chart": "fak fa-lv-aggregations-bar-chart",
  "api-access-key": "fak fa-lv-api-access-key-large",
  "arrow-left": "fak fa-lv-arrow-left",
  "arrow-right": "fak fa-lv-arrow-right",
  "associated-id": "fak fa-lv-associated-id",
  "bulk-create": "fak fa-lv-bulk-create",
  "calendar": "fak fa-lv-calendar",
  "camera": "fak fa-lv-camera",
  "cancel": "fak fa-lv-cancel",
  "center-address": "fak fa-lv-center-address",
  "check": "fak fa-lv-check",
  "chevron-down-large": "fak fa-lv-chevron-down-large",
  "chevron-down-small": "fak fa-lv-chevron-down-small",
  "chevron-left-large": "fak fa-lv-chevron-left-large",
  "chevron-left-small": "fak fa-lv-chevron-left-small",
  "chevron-right-large": "fak fa-lv-chevron-right-large",
  "chevron-right-small": "fak fa-lv-chevron-right-small",
  "chevron-up-large": "fak fa-lv-chevron-up-large",
  "chevron-up-small": "fak fa-lv-chevron-up-small",
  "clear": "fak fa-lv-clear",
  "close": "fak fa-lv-cancel",
  "conditionSelector": "fa fa-fw fa-dog",
  "configuration": "fak fa-lv-configuration",
  "copy": "fa fa-copy",
  "copy-to-clipboard": "fak fa-lv-copy",
  "create": "fak fa-lv-create",
  "current-location": "fak fa-lv-current-location",
  "dash": "fak fa-lv-dash",
  "dashboard": "fak fa-lv-dashboard",
  "delete": "fak fa-lv-delete",
  "details": "fak fa-lv-details",
  "details-filled": "fak fa-lv-details-filled",
  "edit": "fak fa-lv-edit",
  "filter": "fak fa-lv-filter",
  "gear": "fak fa-lv-gear",
  "grabberIcon": "fak fa-lv-rearrange",
  "help": "fak fa-lv-help",
  "help-filled": "fak fa-lv-help-filled",
  "hide": "fak fa-lv-hide",
  "history-table-list": "fak fa-lv-history-table-list",
  "history-xy-chart": "fak fa-lv-history-xy-chart",
  "image": "fak fa-lv-image",
  "info": "fak fa-lv-info",
  "info-filled": "fak fa-lv-info-filled",
  "layout": "fak fa-lv-layout",
  "load-form": "fak fa-lv-load-form",
  "log-out": "fak fa-lv-log-out",
  "low-battery": "fak fa-lv-low-battery",
  "lvg-logo": "fak fa-lv-lvg-logo",
  "map": "fak fa-lv-map",
  "menu": "fak fa-lv-menu",
  "more": "fak fa-lv-more",
  "more-h": "fa fa-ellipsis fa-fw",
  "multi-image": "fak fa-lv-multi-image",
  "overrideFormIcon": "fak fa-lv-refresh",
  "pair": "fak fa-lv-pair",
  "placeholder": "fak fa-lv-placeholder",
  "rearrange": "fak fa-lv-rearrange",
  "refresh": "fak fa-lv-refresh",
  "remove": "fak fa-lv-remove",
  "reset": "fak fa-lv-reset",
  "satellite": "fak fa-lv-satellite",
  "save": "fak fa-lv-save",
  "save-to": "fak fa-lv-save-to",
  "search": "fak fa-lv-search",
  "settings": "fak fa-lv-settings",
  "show": "fak fa-lv-show",
  "single-image": "fak fa-lv-single-image",
  "stop": "fak fa-lv-stop",
  "success": "fak fa-lv-success",
  "table-list": "fak fa-lv-table-list",
  "time": "fak fa-lv-time",
  "transfer": "fak fa-lv-transfer",
  "trash": "fak fa-lv-delete",
  "unpair": "fak fa-lv-unpair",
  "upload": "fak fa-lv-upload",
  "user": "fak fa-lv-user",
  "warning-filled": "fak fa-lv-warning-filled"
}
const size = {
  "borderRadiusMedium": "4px",
  "borderRadiusSmall": "2px",
  "fontBody": "16px",
  "fontBodySmall": "15px",
  "fontButtonMedium": "15px",
  "fontButtonSmall": "12px",
  "fontCaption": "13px",
  "fontCaptionSmall": "12px",
  "fontH1": "20px",
  "fontH2": "18px",
  "fontH3": "16px",
  "large": "16px",
  "medium": "8px",
  "negative": "-4px",
  "pad12": "12px",
  "padLarge": "16px",
  "padMedium": "8px",
  "padSmall": "4px",
  "padXLarge": "24px",
  "padXXLarge": "32px",
  "portalMaxWidth": "504px",
  "small": "4px"
}
const resources = { color, icon, size }

export default { styles, supports, resources }