module.exports = {
  "hierarchy": {},
  "props": {
    "default": {
      "data-error": true,
      "dateFormat": null,
      "rangeChangeType": null,
      "timeFormat": null
    }
  }
}