import React from 'react'
import OtaFlagScreen from './OtaFlagScreen'

export default {
  id : 'siren.firmware.ota.customers',
  matches : { client : 'Main' },
  exact : true,
  path : '/otaFlag/',
  default : false,
  render : ctx => React.createElement( OtaFlagScreen, ctx )
}  
