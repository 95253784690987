// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider_driy2 {
  width: 100px;
  height: 20px;
  outline: none;
  border-radius: 20px;
  background-color: var( --color-base300 );
  -webkit-appearance: none;
}
.slider_driy2:focus {
  background-color: var( --color-base400 );
}
.slider_driy2:hover {
  background-color: var( --color-base400 );
}
.slider_driy2:active {
  background-color: var( --color-base600 );
}
.slider_driy2[data-disabled=true] {
  background-color: var( --color-base025 );
}
.slider_driy2::-webkit-slider-thumb {
  width: 18px;
  height: 18px;
  outline: none;
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 50%;
  margin-bottom: 1px;
  background-color: var( --color-white );
  -webkit-appearance: none;
}
.slider_driy2::-moz-range-thumb {
  width: 18px;
  height: 18px;
  outline: none;
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 50%;
  margin-bottom: 1px;
  background-color: var( --color-white );
  -webkit-appearance: none;
}`, "",{"version":3,"sources":["webpack://./src/theme/Slider.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,wBAAwB;AAC1B;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,sCAAsC;EACtC,wBAAwB;AAC1B;AACA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,sCAAsC;EACtC,wBAAwB;AAC1B","sourcesContent":[".slider {\n  width: 100px;\n  height: 20px;\n  outline: none;\n  border-radius: 20px;\n  background-color: var( --color-base300 );\n  -webkit-appearance: none;\n}\n.slider:focus {\n  background-color: var( --color-base400 );\n}\n.slider:hover {\n  background-color: var( --color-base400 );\n}\n.slider:active {\n  background-color: var( --color-base600 );\n}\n.slider[data-disabled=true] {\n  background-color: var( --color-base025 );\n}\n.slider::-webkit-slider-thumb {\n  width: 18px;\n  height: 18px;\n  outline: none;\n  margin-top: 1px;\n  margin-left: 1px;\n  margin-right: 1px;\n  border-radius: 50%;\n  margin-bottom: 1px;\n  background-color: var( --color-white );\n  -webkit-appearance: none;\n}\n.slider::-moz-range-thumb {\n  width: 18px;\n  height: 18px;\n  outline: none;\n  margin-top: 1px;\n  margin-left: 1px;\n  margin-right: 1px;\n  border-radius: 50%;\n  margin-bottom: 1px;\n  background-color: var( --color-white );\n  -webkit-appearance: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": `slider_driy2`
};
export default ___CSS_LOADER_EXPORT___;
