import React from 'react'
import DeviceCommandsScreen from './DeviceCommandsScreen'

export default {
  id : 'siren.boat.DeviceCommands.GroupScreen',
  matches : { client : 'ItemScreen', path : 'boat', objectType : 'boat' },
  exact : true,
  path : '/p/:persona/boat/:boatId/DeviceCommands',
  default : false,
  render : ctx => React.createElement( DeviceCommandsScreen, ctx )
}
