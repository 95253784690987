module.exports = {
  "hierarchy": {
    "popup": [
      "radioButton-popup"
    ],
    "twoFactorMethodRadio": [
      "radioButton-twoFactorMethodRadio"
    ],
    "mobileUserNavBarNested": [
      "radioButton-mobileUserNavBarNested"
    ],
    "none": [
      "radioButton-none"
    ]
  },
  "props": {
    "default": {
      "iconOn": "fa fa-circle"
    },
    "popup": {
      "iconOn": "fa fa-circle"
    },
    "twoFactorMethodRadio": {
      "iconOn": "fa fa-circle"
    },
    "mobileUserNavBarNested": {
      "iconOn": "fa fa-circle"
    },
    "none": {
      "iconOn": "fa fa-circle"
    }
  }
}