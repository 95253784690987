module.exports = {
  "hierarchy": {
    "pagination": [
      "combobox-pagination"
    ]
  },
  "props": {
    "default": {
      "selectIcon": "fak fa-lv-chevron-down-small"
    },
    "pagination": {
      "selectIcon": "fak fa-lv-chevron-down-small"
    }
  }
}