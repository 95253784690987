import React from 'react'
import CustomersScreen from './CustomersScreen'

export default {
  id : 'siren.users.customers',
  matches : { client : 'Main' },
  exact : true,
  path : '/customers/',
  default : false,
  render : ctx => React.createElement( CustomersScreen, ctx )
}  
