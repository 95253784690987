module.exports = {
  "hierarchy": {
    "perPage": [
      "textInput-perPage"
    ],
    "formInput": [
      "textInput-formInput"
    ],
    "attributeTableStringEditor": [
      "textInput-attributeTableStringEditor"
    ],
    "password": [
      "textInput-formInput",
      "textInput-password"
    ],
    "inputGrow": [
      "textInput-inputGrow"
    ],
    "multiSelector": [
      "textInput-multiSelector"
    ],
    "small": [
      "textInput-small"
    ],
    "attributeStringEditor": [
      "textInput-attributeStringEditor"
    ],
    "attributeEditor": [
      "textInput-attributeEditor"
    ],
    "undefined": [
      "textInput-undefined"
    ],
    "undefinedRight": [
      "textInput-undefined",
      "textInput-undefinedRight"
    ],
    "comparator": [
      "textInput-comparator"
    ]
  },
  "props": {
    "default": {
      "icon": ""
    },
    "perPage": {
      "icon": ""
    },
    "formInput": {
      "icon": ""
    },
    "attributeTableStringEditor": {
      "icon": ""
    },
    "password": {
      "icon": "",
      "hidePasswordIcon": "fa fa-eye-slash fa-fw",
      "showPasswordIcon": "fa fa-eye fa-fw",
      "showPasswordOptions": {
        "hidePasswordIcon": "",
        "showPasswordIcon": ""
      }
    },
    "inputGrow": {
      "icon": ""
    },
    "multiSelector": {
      "icon": ""
    },
    "small": {
      "icon": ""
    },
    "attributeStringEditor": {
      "icon": ""
    },
    "attributeEditor": {
      "icon": ""
    },
    "undefined": {
      "icon": ""
    },
    "undefinedRight": {
      "icon": ""
    },
    "comparator": {
      "icon": ""
    }
  }
}