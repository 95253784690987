const formatTimeStamp = ( timestamp ) => {
  if ( timestamp == null ) return null
  const timeStamp = new Date( timestamp )
  if ( Number.isNaN( timeStamp.getTime() ) ) return null
  return new Intl.DateTimeFormat( 'en-US', {
    year : 'numeric',
    month : '2-digit',
    day : '2-digit',
    hour : '2-digit',
    minute : '2-digit',
    hour12 : false,
    timeZoneName : 'short'
  } ).format( timeStamp )
}

const ENGINES_LABELS = {
  1 : [
    'Engine'
  ],
  2 : [
    'Port',
    'Starboard'
  ],
  3 : [
    'Port',
    'Center',
    'Starboard'
  ],
  4 : [
    'Port',
    'Port Center',
    'Stbd Center',
    'Stbd'
  ],
  5 : [
    'Engine P',
    'Engine Ps',
    'Engine C',
    'Engine Ss',
    'Engine S'
  ],
  6 : [
    'Engine P',
    'Engine Ps',
    'Engine Cent P',
    'Engine Cent S',
    'Engine Ss',
    'Engine S'
  ]
}

const getLabel = model => model.label ?? null
const getClassCode = model => model.device?.classCode ?? null
const getFunctionCode = model => model.device?.functionCode ?? null
const getManufacturerCode = model => model.device?.manufacturerCode ?? null
const getNmeaChannelDisplay = model => model.display ?? null

const getNmeaAlias = ( alias, type, totalEngines, engineCount ) => {
  if ( !alias && type === 'engine' ) {
    return ENGINES_LABELS[totalEngines] && ENGINES_LABELS[totalEngines][engineCount];
  }
  return alias
}

const getNmeaData = ( nmea ) => {
  if ( !nmea || typeof nmea !== 'object' ) return []
  const activeNmeaTypes = [ 'engine', 'tank', 'wind', 'environmental', 'location', 'generator', 'subchannel' ]

  const engines = Object.values( nmea ).filter( x => Object.prototype.hasOwnProperty.call( x, 'engine' ) )
  const totalEngines = engines.length
  let engineCount = 0

  return Object.keys( nmea )
    .filter( 
      channel => channel !== 'active' &&
      channel !== 'lastPayloadType' &&
      channel !== 'YMCBPSScanInfo' &&
      channel !== 'YMCEngineParameters'
    )
    .map( ( channel ) => {
      const nmeaItem = nmea[channel]
      const activeNmeaType = activeNmeaTypes.find( t => Object.prototype.hasOwnProperty.call( nmeaItem, t ) )
      const alias = getNmeaAlias( nmeaItem.alias, activeNmeaType, totalEngines, engineCount ) || null
      if ( activeNmeaType === 'engine' ) {
        engineCount++
      }

      return {
        channelNumber : channel,
        type : activeNmeaType === 'subchannel' ? 'switchback' : activeNmeaType,
        label : getLabel( nmeaItem ),
        showInApp : getNmeaChannelDisplay( nmeaItem ),
        alias,
        classCode : getClassCode( nmeaItem ),
        functionCode : getFunctionCode( nmeaItem ),
        manufacturerCode : getManufacturerCode( nmeaItem ),
        lastUpdate : formatTimeStamp( nmeaItem.msTime ) || null,
        pgns : nmeaItem.pgns ?? null,
        id : channel
      }
    } )
}

module.exports = {
  getNmeaData
}
