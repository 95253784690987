import DailyReportsLink from './DailyReportsLink'
import DailyReportsRoute from './DailyReportsRoute'

const install = ( molten ) => {
  molten.addPlugin( 'Route', DailyReportsRoute )
  molten.addPlugin( 'Action', DailyReportsLink )
}

export default {
  install
}
