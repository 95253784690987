module.exports = {
  "hierarchy": {
    "dataTable": [
      "table-dataTable"
    ],
    "history": [
      "table-dataTable",
      "table-history"
    ]
  },
  "props": {
    "default": {
      "filterButtonIconOff": "fa fa-filter",
      "filterButtonIconOn": "fa fa-filter",
      "filterButtonTextOff": "",
      "filterButtonTextOn": "",
      "filterButtonVariant": "linkSmall|link",
      "filterCancelButtonText": "Cancel",
      "filterCancelButtonVariant": "linkDestructive",
      "filterCheckboxVariant": "small",
      "filterClearButtonVariant": "linkDestructive",
      "filterOkayButtonText": "Apply",
      "filterPaneVariant": "tableFilter",
      "filterable": false,
      "paginate": false,
      "paginationDropdownVariant": "small",
      "paginationNextButtonIcon": "fa fa-chevron-right",
      "paginationPreviousButtonIcon": "fa fa-chevron-left",
      "sortable": false
    },
    "dataTable": {
      "filterButtonIconOff": "fa fa-filter",
      "filterButtonIconOn": "fa fa-filter",
      "filterButtonTextOff": "",
      "filterButtonTextOn": "",
      "filterButtonVariant": "linkSmall|link",
      "filterCancelButtonText": "Cancel",
      "filterCancelButtonVariant": "linkDestructive",
      "filterCheckboxVariant": "small",
      "filterClearButtonVariant": "linkDestructive",
      "filterOkayButtonText": "Apply",
      "filterPaneVariant": "tableFilter",
      "filterable": true,
      "paginate": true,
      "paginationDropdownVariant": "small",
      "paginationNextButtonIcon": "fa fa-chevron-right",
      "paginationPreviousButtonIcon": "fa fa-chevron-left",
      "sortable": true
    },
    "history": {
      "filterButtonIconOff": "fa fa-filter",
      "filterButtonIconOn": "fa fa-filter",
      "filterButtonTextOff": "",
      "filterButtonTextOn": "",
      "filterButtonVariant": "linkSmall|link",
      "filterCancelButtonText": "Cancel",
      "filterCancelButtonVariant": "linkDestructive",
      "filterCheckboxVariant": "small",
      "filterClearButtonVariant": "linkDestructive",
      "filterOkayButtonText": "Apply",
      "filterPaneVariant": "tableFilter",
      "filterable": true,
      "paginate": false,
      "paginationDropdownVariant": "small",
      "paginationNextButtonIcon": "fa fa-chevron-right",
      "paginationPreviousButtonIcon": "fa fa-chevron-left",
      "sortable": true
    }
  }
}