// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portal_L7bSn {
  background-color: rgba(0, 0, 0, 0.25);
}
.portal_L7bSn.portal-dialog_RUojJ {
  background: linear-gradient(0deg, rgba( 255, 255, 255, 0.25) 0.00%, rgba( 128, 128, 128, 0.25) 100.00%), rgba(0, 0, 0, 0.25);
}
.portal_L7bSn.portal-inPlaceAttributeEditor_FdMfr {
  background-color: transparent;
}
.portal_L7bSn.portal-contextMenu_pRLOY {
  background-color: rgba(0, 0, 0, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/theme/Portal.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;AACA;EACE,4HAA4H;AAC9H;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,qCAAqC;AACvC","sourcesContent":[".portal {\n  background-color: rgba(0, 0, 0, 0.25);\n}\n.portal.portal-dialog {\n  background: linear-gradient(0deg, rgba( 255, 255, 255, 0.25) 0.00%, rgba( 128, 128, 128, 0.25) 100.00%), rgba(0, 0, 0, 0.25);\n}\n.portal.portal-inPlaceAttributeEditor {\n  background-color: transparent;\n}\n.portal.portal-contextMenu {\n  background-color: rgba(0, 0, 0, 0.25);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portal": `portal_L7bSn`,
	"portal-dialog": `portal-dialog_RUojJ`,
	"portal-inPlaceAttributeEditor": `portal-inPlaceAttributeEditor_FdMfr`,
	"portal-contextMenu": `portal-contextMenu_pRLOY`
};
export default ___CSS_LOADER_EXPORT___;
