export default {
  id : 'boat.wireless.sensor.itemCreateHook',
  matches : {
    objectType : 'wireless',
    path : 'boat.wireless',
  },
  hook : async ( device ) => {
    const wirelessIdObj = device.data.find( item => item.path === 'wirelessId' )
    const wirelessId = wirelessIdObj.value

    const newName = `Siren Marine Wireless ${wirelessId}`
    device.name = newName

    return device
  }
}
