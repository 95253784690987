module.exports = {
  "hierarchy": {
    "conditionTags": [
      "multiSelector-conditionTags"
    ],
    "conditionText": [
      "multiSelector-conditionText"
    ],
    "condition": [
      "multiSelector-condition"
    ],
    "test": [
      "multiSelector-test"
    ],
    "conditionSelector": [
      "multiSelector-condition",
      "multiSelector-conditionSelector"
    ],
    "comparator": [
      "multiSelector-conditionTags",
      "multiSelector-comparator"
    ]
  },
  "props": {
    "default": {
      "selectionType": "tags",
      "checkVariant": "multiSelector|small",
      "popupVariant": "multiSelector|selector",
      "removeButtonVariant": "multiSelector",
      "tagInputVariant": "multiSelector",
      "toggleVariant": "multiSelector|selector"
    },
    "conditionTags": {
      "selectionType": "tags",
      "checkVariant": "multiSelector|small",
      "popupVariant": "multiSelector|selector",
      "removeButtonVariant": "multiSelector",
      "tagInputVariant": "multiSelector",
      "toggleVariant": "multiSelector|selector"
    },
    "conditionText": {
      "selectionType": "text",
      "checkVariant": "multiSelector|small",
      "popupVariant": "multiSelector|selector",
      "removeButtonVariant": "multiSelector",
      "tagInputVariant": "multiSelector",
      "toggleVariant": "multiSelector|selector"
    },
    "condition": {
      "selectionType": "text",
      "checkVariant": "multiSelector|small",
      "popupVariant": "multiSelector|selector",
      "removeButtonVariant": "multiSelector",
      "tagInputVariant": "multiSelector",
      "toggleVariant": "multiSelector|selector"
    },
    "test": {
      "selectionType": "tags",
      "checkVariant": "multiSelector|small",
      "popupVariant": "multiSelector|selector",
      "removeButtonVariant": "multiSelector",
      "tagInputVariant": "multiSelector",
      "toggleVariant": "multiSelector|selector"
    },
    "conditionSelector": {
      "selectionType": "tags",
      "checkVariant": "multiSelector|small",
      "popupVariant": "multiSelector|selector",
      "removeButtonVariant": "multiSelector",
      "tagInputVariant": "multiSelector",
      "toggleVariant": "multiSelector|selector",
      "allowAdd": true
    },
    "comparator": {
      "selectionType": "tags",
      "checkVariant": "multiSelector|small",
      "popupVariant": "multiSelector|selector",
      "removeButtonVariant": "multiSelector",
      "tagInputVariant": "multiSelector",
      "toggleVariant": "multiSelector|selector"
    }
  }
}