import { LinkAction } from '@leverege/molten/routes'

export default LinkAction.create( {
  id : 'boat.link.firmwareHistory',
  name : 'Firmware History',
  icon : 'fa-solid fa-microchip',
  layout : {
    sort : 'c.2'
  },
  matches : {
    client : 'ItemScreen',
    use : 'navBar',
    objectType : 'boat',
    path : 'boat'
  },
  path : '/p/:persona/boat/:boatId/FirmwareHistory'
} )
