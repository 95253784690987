/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useCallback } from 'react'
import { 
  Flex, 
  Content, 
  DateTimePickerV2, 
  Button, 
  Popup, 
  Pane, 
  Text, 
  TextInput, 
  Toast,
  TableV2 } from '@leverege/ui-elements'
import exportToCsv from '../utils/TableUtils'
import { getDeviceEvents } from '../utils/EventsUtils'
import TableFooter from './TableFooter'
import useSort from '../utils/hooks/useSort'

function EventsLogTable( props ) {
  const { deviceId } = props

  const [ eventData, setEventData ] = useState( [] )
  const [ loading, setLoading ] = useState( false )
  const [ perPage, setPerPage ] = useState( 20 )
  const [ startIndex, setStartIndex ] = useState( 0 )
  const { handleSort, sort } = useSort()

  const [ startTime, setStartTime ] = useState( () => {
    const now = new Date()
    const oneWeekAgo = new Date( now - 7 * 24 * 60 * 60 * 1000 )
    return oneWeekAgo
  } )
  const [ endTime, setEndTime ] = useState( () => {
    const now = new Date()
    return now
  } )
  const [ fileName, setFileName ] = useState( 'exported_data' )
  const [ hasNextPage, setHasNextPage ] = useState( false )

  const handleStartTimeChange = ( event ) => {
    const { value } = event
    setStartTime( value )
  }

  const handleEndTimeChange = ( event ) => {
    const { value } = event
    setEndTime( value )
  }

  const columns = [
    {
      Header : 'Event Name',
      accessor : 'eventName',
    },
    {
      Header : 'Event Code',
      accessor : 'eventCode',
    },
    {
      Header : 'Event Type',
      accessor : 'eventType',
    },
    {
      Header : ( ) => {
        return (
          <Flex onClick={() => handleSort( 'time' )}>
            <span>Time</span>
          </Flex>
        )
      },
      accessor : 'eventDate'
    }
  ]

  const handleFileNameChange = ( event ) => {
    const { value } = event
    setFileName( value )
  }

  const handleExportToCsv = async () => {
    const fullFileName = `${fileName}.csv`
    try {
      const allEvents = await getDeviceEvents( deviceId, startTime, endTime, 10000, 1 )
      exportToCsv( allEvents, columns, fullFileName )
      Toast.success( 'Successfully exported' )
    } catch ( error ) {
      console.error( 'Error', error )
      Toast.error( 'Failed to export', error )
    }
  }

  const fetchData = useCallback( async () => {
    setLoading( true )
    try {
      const currentPageIndex = Math.floor( startIndex / perPage ) + 1
      const events = await getDeviceEvents( deviceId, startTime, endTime, perPage, currentPageIndex, sort )
      setEventData( events )
      setHasNextPage( events.length === perPage )
    } catch ( error ) {
      console.error( 'Error', error )
      Toast.error( 'Failed to Load Events', error )
    } finally {
      setLoading( false )
    }
  }, [ deviceId, startTime, endTime, perPage, startIndex, sort ] )

  useEffect( () => {
    fetchData()
  }, [ fetchData ] )

  return (
    <Content>
      <Content.Header>
        <Flex variant="itemBar">
          <Flex variant="rowS" align="center">
            <Text variant="actionBar">Start Date:</Text>
            <DateTimePickerV2 
              value={startTime} 
              onChange={handleStartTimeChange} 
              max={endTime}/>
            <Text variant="actionBar">End Date:</Text>
            <DateTimePickerV2 
              value={endTime} 
              onChange={handleEndTimeChange} 
              min={startTime}/>
            <Popup icon="fa fa-fw fa-download">
              <Pane variant="contentSection">
                <Text variant="title">Export to CSV</Text>
              </Pane>
              <Pane variant="contentSection"> 
                <Flex variant="colM" style={{ height : '100%' }}>
                  <TextInput
                    variant="small"
                    value={fileName}
                    onChange={handleFileNameChange}/>
                </Flex>                
              </Pane>
              <Pane variant="contentSection">
                <Flex variant="colM">
                  <Button variant="primary" onClick={handleExportToCsv}>Export</Button>
                </Flex>
              </Pane>
            </Popup>
          </Flex>
        </Flex>
      </Content.Header>
      <Content.Area>
        <TableV2
          variant="dataTable"
          data={eventData}
          columns={columns}
          loading={loading}
          filterable={false}
          paginate
          FooterComponent={() => (
            <TableFooter
              pageIndex={startIndex / perPage}
              pageSize={perPage}
              canNextPage={hasNextPage}
              canPreviousPage={startIndex > 0}
              setPageSize={( size ) => {
                setPerPage( size )
                setStartIndex( 0 )
              }}
              gotoPage={( newPageIndex ) => {
                setStartIndex( newPageIndex * perPage )
              }}/>
          )}/>
      </Content.Area>
    </Content>
  )
}

export default EventsLogTable
