// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label_Bcix_ {
  font: 600 13px/20px Inter;
  color: var( --color-base700 );
}
.label_Bcix_.label-inputTitle_UUZ5d {
  font: 600 13px/20px Inter;
  color: var( --color-base700 );
}
.label_Bcix_.label-inputTitle_UUZ5d.label-checkboxTitle__IAHE {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.label_Bcix_.label-inputCaption_pNvRG {
  font: 13px/20px Inter;
  color: #394756;
}
.label_Bcix_.label-touchToolbarLabel_I7GxM {
  font: normal 15px/24px Inter;
  color: #394756;
}
.label_Bcix_.label-checkboxLabel_ZAm5N {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.label_Bcix_.label-mobileUserNavBarNested_jW29_ {
  color: var( --color-base700 );
  font-size: 16px;
  line-height: 24px;
  padding-top: var( --size-pad12 );
  padding-left: var( --size-padMedium );
  padding-bottom: var( --size-pad12 );
}
.label_Bcix_.label-checkboxLabelWrap_Kj2pY {
  font: normal 15px/24px Inter;
  color: #394756;
  overflow: unset;
  white-space: normal;
  text-overflow: unset;
}
.label_Bcix_.label-subtitle_NsdX5 {
  font: bold 16px/24px Inter;
  color: #394756;
  padding-top: var( --size-padLarge );
  padding-bottom: var( --size-padLarge );
}
.label_Bcix_.label-captionSmallBold_B1s_z {
  font: 600 12px/18px Inter;
  color: #394756;
}`, "",{"version":3,"sources":["webpack://./src/theme/Label.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,6BAA6B;AAC/B;AACA;EACE,yBAAyB;EACzB,6BAA6B;AAC/B;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE,4BAA4B;EAC5B,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,6BAA6B;EAC7B,eAAe;EACf,iBAAiB;EACjB,gCAAgC;EAChC,qCAAqC;EACrC,mCAAmC;AACrC;AACA;EACE,4BAA4B;EAC5B,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,0BAA0B;EAC1B,cAAc;EACd,mCAAmC;EACnC,sCAAsC;AACxC;AACA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".label {\n  font: 600 13px/20px Inter;\n  color: var( --color-base700 );\n}\n.label.label-inputTitle {\n  font: 600 13px/20px Inter;\n  color: var( --color-base700 );\n}\n.label.label-inputTitle.label-checkboxTitle {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.label.label-inputCaption {\n  font: 13px/20px Inter;\n  color: #394756;\n}\n.label.label-touchToolbarLabel {\n  font: normal 15px/24px Inter;\n  color: #394756;\n}\n.label.label-checkboxLabel {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.label.label-mobileUserNavBarNested {\n  color: var( --color-base700 );\n  font-size: 16px;\n  line-height: 24px;\n  padding-top: var( --size-pad12 );\n  padding-left: var( --size-padMedium );\n  padding-bottom: var( --size-pad12 );\n}\n.label.label-checkboxLabelWrap {\n  font: normal 15px/24px Inter;\n  color: #394756;\n  overflow: unset;\n  white-space: normal;\n  text-overflow: unset;\n}\n.label.label-subtitle {\n  font: bold 16px/24px Inter;\n  color: #394756;\n  padding-top: var( --size-padLarge );\n  padding-bottom: var( --size-padLarge );\n}\n.label.label-captionSmallBold {\n  font: 600 12px/18px Inter;\n  color: #394756;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `label_Bcix_`,
	"label-inputTitle": `label-inputTitle_UUZ5d`,
	"label-checkboxTitle": `label-checkboxTitle__IAHE`,
	"label-inputCaption": `label-inputCaption_pNvRG`,
	"label-touchToolbarLabel": `label-touchToolbarLabel_I7GxM`,
	"label-checkboxLabel": `label-checkboxLabel_ZAm5N`,
	"label-mobileUserNavBarNested": `label-mobileUserNavBarNested_jW29_`,
	"label-checkboxLabelWrap": `label-checkboxLabelWrap_Kj2pY`,
	"label-subtitle": `label-subtitle_NsdX5`,
	"label-captionSmallBold": `label-captionSmallBold_B1s_z`
};
export default ___CSS_LOADER_EXPORT___;
