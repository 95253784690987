/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import { FilterSourceModel, FilterSourcesModel } from '@leverege/molten/filters'
import { ToggleButton } from '@leverege/ui-elements'
import isEqual from 'fast-deep-equal'

const archiveFilter = {
  type : 'logical',
  operator : 'and',
  conditions : [ { field : 'data/archived', type : 'equals', value : true } ],
}

const defaultFilter = {
  type : 'logical',
  operator : 'or',
  conditions : [ 
    { field : 'data/archived', type : 'exists', not : true },
    { field : 'data/archived', type : 'equals', value : false }
  ]
}

const filterName = 'archiveFilter'

export default {
  id : 'firmware.action.quick.filter.archive.firmware',
  name : 'Archived Firmware Quick Filter',
  matches : {
    client : 'GroupScreen',
    objectType : 'firmware',
    use : 'sortFilterBar'
  },
  handles : () => true,
  customComponent : ( { context } ) => {
    const { filters, onFilterChange } = context
    let active = FilterSourcesModel.getSource( filters, filterName ) || FilterSourceModel.create()

    useEffect( () => {
      active = FilterSourceModel.setFilter( active, defaultFilter )
      onFilterChange( { value : active, data : filterName } )
    }, [] )

    const onChange = () => {
      if ( isEqual( active?.filter, archiveFilter ) ) {
        active = FilterSourceModel.setFilter( active, defaultFilter )
      } else {
        active = FilterSourceModel.setFilter( active, archiveFilter )
      }
      onFilterChange( { value : active, data : filterName } )
    }
    
    return (
      <ToggleButton value={isEqual( active?.filter, archiveFilter )} onChange={onChange}>
        Archived
      </ToggleButton>
    )
  }
}
