import React, { useState, useEffect, useCallback } from 'react'
import { Flex, Table, TableV2, Toast } from '@leverege/ui-elements'
import { getDeviceEvents } from '../../../utils/EventsUtils'
import TableFooter from '../../../components/TableFooter'
import useSort from '../../../utils/hooks/useSort'

function WirelessSensorsEventsLogsDataViewer( { item } ) {
  const deviceId = item?.id
  const [ eventData, setEventData ] = useState( [] )
  const { handleSort, sort } = useSort()
  const [ loading, setLoading ] = useState( false )
  const [ perPage, setPerPage ] = useState( 20 )
  const [ startIndex, setStartIndex ] = useState( 0 )
  const [ startTime ] = useState( () => {
    const now = new Date()
    const oneWeekAgo = new Date( now - 7 * 24 * 60 * 60 * 1000 )
    return oneWeekAgo
  } )
  const [ endTime ] = useState( () => {
    const now = new Date()
    return now
  } )

  const [ hasNextPage, setHasNextPage ] = useState( false )

  const fetchData = useCallback( async () => {
    setLoading( true )
    try {
      const currentPageIndex = Math.floor( startIndex / perPage ) + 1
      const events = await getDeviceEvents( deviceId, startTime, endTime, perPage, currentPageIndex, sort )
      setEventData( events )
      setHasNextPage( events.length === perPage )
    } catch ( error ) {
      console.error( 'Error', error )
      Toast.error( 'Failed to Load Events', error )
    } finally {
      setLoading( false )
    }
  }, [ deviceId, startTime, endTime, perPage, startIndex, sort ] )

  useEffect( () => {
    fetchData()
  }, [ fetchData ] )

  const columns = [
    {
      Header : () => {
        return (
          <Flex onClick={() => handleSort( 'time' )}>
            <span>Time</span>
          </Flex>
        )
      },
      accessor : 'eventDate'
    },
    {
      Header : 'Event Name',
      accessor : 'eventName',
    },
    {
      Header : 'Event Code',
      accessor : 'eventCode',
    },
  ]

  return (
    <TableV2
      variant="dataTable"
      data={eventData}
      columns={columns}
      loading={loading}
      loadingMessage="Loading events..."
      filterable={false}
      sortable={false}
      paginate
      FooterComponent={() => (
        <TableFooter
          pageIndex={startIndex / perPage}
          pageSize={perPage}
          canNextPage={hasNextPage}
          canPreviousPage={startIndex > 0}
          setPageSize={( size ) => {
            setPerPage( size )
            setStartIndex( 0 )
          }}
          gotoPage={( newPageIndex ) => {
            setStartIndex( newPageIndex * perPage )
          }}/>
      )}/>
  );
}

export default { 
  type : 'WirelessSensorsEventsLogsDataViewer',
  name : 'Wireless Sensors Event Logs',
  matches : [ { objectType : 'wireless' } ],
  location : [ 'card' ],
  handles : () => true,
  render : ( props ) => {
    return React.createElement( WirelessSensorsEventsLogsDataViewer, props )
  },
  component : WirelessSensorsEventsLogsDataViewer,
  id : 'siren.wireless.WirelessSensorsEventsLogsDataViewer' 
} 
