module.exports = {
  "hierarchy": {
    "iconBig": [
      "toggleButton-iconBig"
    ],
    "iconMedium": [
      "toggleButton-iconBig",
      "toggleButton-iconMedium"
    ],
    "iconSmall": [
      "toggleButton-iconBig",
      "toggleButton-iconMedium",
      "toggleButton-iconSmall"
    ],
    "actions": [
      "toggleButton-actions"
    ],
    "nav": [
      "toggleButton-nav"
    ],
    "navSubItem": [
      "toggleButton-nav",
      "toggleButton-navSubItem"
    ],
    "navCollapsed": [
      "toggleButton-nav",
      "toggleButton-navCollapsed"
    ],
    "navSecondary": [
      "toggleButton-nav",
      "toggleButton-navSecondary"
    ],
    "navSecondaryMobile": [
      "toggleButton-nav",
      "toggleButton-navSecondary",
      "toggleButton-navSecondaryMobile"
    ],
    "mobile": [
      "toggleButton-mobile"
    ],
    "mobileSearch": [
      "toggleButton-mobile",
      "toggleButton-mobileSearch"
    ],
    "mobileDateRange": [
      "toggleButton-mobileDateRange"
    ],
    "selector": [
      "toggleButton-actions",
      "toggleButton-selector"
    ],
    "itemBar": [
      "toggleButton-itemBar"
    ],
    "topNavBar": [
      "toggleButton-topNavBar"
    ],
    "sideNavBar": [
      "toggleButton-sideNavBar"
    ],
    "topNavBarMenu": [
      "toggleButton-topNavBar",
      "toggleButton-hNavBar",
      "toggleButton-topNavBarMenu"
    ],
    "navBar": [
      "toggleButton-navBar"
    ],
    "hNavBar": [
      "toggleButton-topNavBar",
      "toggleButton-hNavBar"
    ],
    "navBarMenu": [
      "toggleButton-topNavBar",
      "toggleButton-hNavBar",
      "toggleButton-topNavBarMenu",
      "toggleButton-navBarMenu"
    ],
    "hNavBarMenu": [
      "toggleButton-topNavBar",
      "toggleButton-hNavBar",
      "toggleButton-topNavBarMenu",
      "toggleButton-hNavBarMenu"
    ],
    "settings": [
      "toggleButton-iconBig",
      "toggleButton-iconMedium",
      "toggleButton-settings"
    ],
    "actionBar": [
      "toggleButton-actionBar"
    ],
    "link": [
      "toggleButton-link"
    ],
    "mapActionBar": [
      "toggleButton-actionBar",
      "toggleButton-mapActionBar"
    ],
    "mobileNavBar": [
      "toggleButton-sideNavBar",
      "toggleButton-mobileNavBar"
    ],
    "mobileUserNavBar": [
      "toggleButton-sideNavBar",
      "toggleButton-mobileNavBar",
      "toggleButton-mobileUserNavBar"
    ],
    "dashboardEdit": [
      "toggleButton-link",
      "toggleButton-dashboardEdit"
    ],
    "attributeTableParentEditor": [
      "toggleButton-actions",
      "toggleButton-selector",
      "toggleButton-attributeTableParentEditor"
    ],
    "sideNavBarSmall": [
      "toggleButton-sideNavBar",
      "toggleButton-sideNavBarSmall"
    ],
    "touchToolbarPopupToggle": [
      "toggleButton-link",
      "toggleButton-touchToolbarPopupToggle"
    ],
    "twoStepToggle": [
      "toggleButton-twoStepToggle"
    ],
    "condition": [
      "toggleButton-link",
      "toggleButton-condition"
    ],
    "sideNavBarCollapse": [
      "toggleButton-sideNavBarCollapse"
    ],
    "sideNavBarCollapseSmall": [
      "toggleButton-sideNavBarCollapse",
      "toggleButton-sideNavBarCollapseSmall"
    ],
    "layoutActionMobileOptBar": [
      "toggleButton-sideNavBar",
      "toggleButton-mobileNavBar",
      "toggleButton-layoutActionMobileOptBar"
    ],
    "layoutActionBarSubmenu": [
      "toggleButton-layoutActionBarSubmenu"
    ],
    "layoutSelector": [
      "toggleButton-actions",
      "toggleButton-selector",
      "toggleButton-layoutSelector"
    ],
    "mobileNavBarNested": [
      "toggleButton-sideNavBar",
      "toggleButton-mobileNavBar",
      "toggleButton-mobileNavBarNested"
    ],
    "itemBarMobile": [
      "toggleButton-itemBar",
      "toggleButton-itemBarMobile"
    ],
    "inputSelector": [
      "toggleButton-actions",
      "toggleButton-selector",
      "toggleButton-inputSelector"
    ],
    "multiSelector": [
      "toggleButton-actions",
      "toggleButton-selector",
      "toggleButton-multiSelector"
    ],
    "mapActionBarMenu": [
      "toggleButton-mapActionBarMenu"
    ],
    "mobileUserNavBarNested": [
      "toggleButton-sideNavBar",
      "toggleButton-mobileNavBar",
      "toggleButton-mobileNavBarNested",
      "toggleButton-mobileUserNavBarNested"
    ],
    "mobileActionBar": [
      "toggleButton-mobileActionBar"
    ],
    "userTopNavBarMenu": [
      "toggleButton-topNavBar",
      "toggleButton-hNavBar",
      "toggleButton-topNavBarMenu",
      "toggleButton-userTopNavBarMenu"
    ],
    "sortFilterBar": [
      "toggleButton-sortFilterBar"
    ],
    "filter": [
      "toggleButton-sortFilterBar",
      "toggleButton-filter"
    ],
    "logicConditionSelector": [
      "toggleButton-conditionSelector",
      "toggleButton-logicConditionSelector"
    ],
    "logicConditionOperator": [
      "toggleButton-actions",
      "toggleButton-selector",
      "toggleButton-logicConditionOperator"
    ],
    "linkNoPad": [
      "toggleButton-link",
      "toggleButton-linkNoPad"
    ],
    "sort": [
      "toggleButton-sortFilterBar",
      "toggleButton-sort"
    ],
    "linkNeutral": [
      "toggleButton-link",
      "toggleButton-linkNeutral"
    ],
    "linkNeutralNoPad": [
      "toggleButton-link",
      "toggleButton-linkNeutral",
      "toggleButton-linkNeutralNoPad"
    ],
    "conditionSelector": [
      "toggleButton-conditionSelector"
    ],
    "comparator": [
      "toggleButton-conditionSelector",
      "toggleButton-comparator"
    ],
    "quaternary": [
      "toggleButton-quaternary"
    ]
  },
  "props": {
    "default": {},
    "iconBig": {},
    "iconMedium": {},
    "iconSmall": {},
    "actions": {
      "iconOff": "lv lv-chevron-down",
      "iconOn": "lv lv-chevron-up",
      "iconPosition": ""
    },
    "nav": {},
    "navSubItem": {},
    "navCollapsed": {},
    "navSecondary": {},
    "navSecondaryMobile": {
      "imageOpts": {
        "height": "32px",
        "width": "32px"
      }
    },
    "mobile": {},
    "mobileSearch": {},
    "mobileDateRange": {},
    "selector": {
      "iconOff": "fak fa-lv-chevron-down-small",
      "iconOn": "fak fa-lv-chevron-up-small",
      "iconPosition": ""
    },
    "itemBar": {
      "iconOpts": {
        "style": {
          "fontSize": "18px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "topNavBar": {
      "imageOpts": {
        "height": "24",
        "width": "24"
      }
    },
    "sideNavBar": {
      "imageOpts": {
        "width": "20"
      }
    },
    "topNavBarMenu": {
      "imageOpts": {
        "height": "24",
        "width": "24"
      }
    },
    "navBar": {},
    "hNavBar": {
      "imageOpts": {
        "height": "24",
        "width": "24"
      }
    },
    "navBarMenu": {
      "imageOpts": {
        "height": "24",
        "width": "24"
      }
    },
    "hNavBarMenu": {
      "imageOpts": {
        "height": "24",
        "width": "24"
      }
    },
    "settings": {},
    "actionBar": {
      "iconOpts": {
        "style": {
          "fontSize": "16px"
        }
      },
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "link": {},
    "mapActionBar": {
      "iconOpts": {
        "style": {
          "fontSize": "16px"
        }
      },
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "mobileNavBar": {
      "imageOpts": {
        "width": "20"
      }
    },
    "mobileUserNavBar": {
      "imageOpts": {
        "width": "20"
      }
    },
    "dashboardEdit": {},
    "attributeTableParentEditor": {
      "iconOff": "fak fa-lv-chevron-down-small",
      "iconOn": "fak fa-lv-chevron-up-small",
      "iconPosition": ""
    },
    "sideNavBarSmall": {
      "imageOpts": {
        "width": "20"
      }
    },
    "touchToolbarPopupToggle": {},
    "twoStepToggle": {},
    "condition": {},
    "sideNavBarCollapse": {
      "icon": "@icon:chevron-left-large",
      "iconOff": "@icon:chevron-left-large",
      "iconOn": "@icon:chevron-left-large",
      "imageOpts": {
        "width": "24px"
      }
    },
    "sideNavBarCollapseSmall": {
      "icon": "@icon:chevron-right-large",
      "iconOff": "@icon:chevron-right-large",
      "iconOn": "@icon:chevron-right-large",
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "layoutActionMobileOptBar": {
      "imageOpts": {
        "width": "20"
      }
    },
    "layoutActionBarSubmenu": {},
    "layoutSelector": {
      "iconOff": "v",
      "iconOn": "@icon:chevronDown",
      "iconPosition": "",
      "icon": "@icon:chevronDown"
    },
    "mobileNavBarNested": {
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "itemBarMobile": {
      "iconOpts": {
        "style": {
          "fontSize": "18px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "inputSelector": {
      "iconOff": "fak fa-lv-chevron-down-small",
      "iconOn": "fak fa-lv-chevron-up-small",
      "iconPosition": ""
    },
    "multiSelector": {
      "iconOff": "fak fa-lv-chevron-down-small",
      "iconOn": "fak fa-lv-chevron-up-small",
      "iconPosition": ""
    },
    "mapActionBarMenu": {
      "imageOpts": {
        "height": "",
        "width": "20px"
      }
    },
    "mobileUserNavBarNested": {
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      },
      "iconOpts": {
        "style": {
          "fontSize": "24px"
        }
      }
    },
    "mobileActionBar": {},
    "userTopNavBarMenu": {
      "imageOpts": {
        "height": "24",
        "width": "24"
      }
    },
    "sortFilterBar": {},
    "filter": {},
    "logicConditionSelector": {
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "logicConditionOperator": {
      "iconOff": "fak fa-lv-chevron-down-small",
      "iconOn": "fak fa-lv-chevron-up-small",
      "iconPosition": ""
    },
    "linkNoPad": {},
    "sort": {},
    "linkNeutral": {},
    "linkNeutralNoPad": {},
    "conditionSelector": {},
    "comparator": {},
    "quaternary": {}
  }
}