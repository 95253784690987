/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import Molten from '@leverege/molten'
import { GlobalState, UI } from '@leverege/ui-redux'
import debounce from 'lodash.debounce'
import { useEffect } from 'react'

function FleetGroupHiddenDataViewer( props ) {
  const systemId = Molten.getConfig( 'api/systemId' )
  const {
    item : { id : boatId },
  } = props
  const debouncedUpdateBoatIds = debounce( async () => {
    try {
      const { items : boats } = await GlobalState.dispatch(
        ( dispatch, getState, { api } ) => {
          return api
            .interface( systemId, 'fleetGroup' )
            .obj( '*' )
            .grp( 'boat' )
            .list( { perPage : 9999 } )
        },
      )

      const { items : fleets } = await GlobalState.dispatch(
        ( dispatch, getState, { api } ) => {
          return api.interface( systemId, 'fleetGroup' ).search( { perPage : 9999 } )
        },
      )

      const mappedFleetGroups = fleets
        .map( ( fleetGroup ) => {
          if ( boats.find( b => b.fleetGroup === fleetGroup.id ) ) {
            return {
              fleetGroupId : fleetGroup.id,
              fleetGroupName : fleetGroup?.data?.name,
              boatIds : boats
                .filter( b => b.fleetGroup === fleetGroup.id )
                .map( v => v['fleetGroup/boat'] ),
            }
          }
          return undefined
        } )
        .map( ( b ) => {
          if ( b?.boatIds?.includes( boatId ) ) return b
          return undefined
        } )
        .filter( v => !!v )

      GlobalState.dispatch( UI.set( 'boatsFleetGroups', mappedFleetGroups ) )
    } catch ( e ) {
      console.error( e )
    }
  }, 500 )

  useEffect( () => {
    debouncedUpdateBoatIds()
  }, [ boatId ] )

  return null
}

export default {
  type : 'BoatFleetGroupHiddenDataViewer',
  name : 'Fleet info hidden data viewer component',
  matches : [ { objectType : 'boat' } ],
  location : 'hidden',
  handles : () => true,
  component : FleetGroupHiddenDataViewer,
  id : 'siren.boat.FleetGroupHiddenDataViewer',
}
