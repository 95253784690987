import { Dialogs } from '@leverege/ui-elements'
import RetryFirmwareDownloadRenderer from './RetryFirmwareDownloadRenderer'

export default {
  id : 'wireless.download.retry',
  name : 'Retry Firmware Downloads',
  layout : {
    sort : 'z',
  },
  matches : {
    client : [ 'GroupScreen', 'TableDataViewer' ],
    use : [ 'contextMenu', 'mobileActionBar' ],
  },
  appearance : () => ( {
    icon : 'fa-light fa-refresh',
    name : 'Retry Firmware Downloads',
  } ),
  handles : () => true,
  perform : props => Dialogs.show( {
    props,
    component : RetryFirmwareDownloadRenderer
  } )
}
