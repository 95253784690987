import { 
  Content,
  Flex,
  DateTimePickerV2,
  TableV2,
  Toast,
  Popup,
  TextInput,
  Button,
  Text,
  Pane } from '@leverege/ui-elements'
import React, { useEffect, useState } from 'react'
import { Relationship, GroupDelegate } from '@leverege/molten/imagine'
import { DataSources } from '@leverege/ui-attributes'
import { useDispatch } from 'react-redux'
import { fetchFirmwareHistory } from '../utils/HistoryUtils'
import exportToCsv from '../utils/TableUtils'

function FirmwareHistory( props ) {
  const { objRef } = props
  
  const dispatch = useDispatch()
  const delegate = DataSources.getDelegate( objRef )

  const relationship = Relationship.getByPath( 'firmware' )
  const groupDelegate = new GroupDelegate( {
    relationship : relationship.createResolved( delegate.match ),
    match : delegate.match,
    queryName : 'delegate.search'
  } )

  const [ outboundMessagesList, setOutboundMessagesList ] = useState( [] )
  const [ startTime, setStartTime ] = useState( () => {
    const now = new Date()
    const oneWeekAgo = new Date( now - 7 * 24 * 60 * 60 * 1000 )
    const oneWeekAgoDateOnly = new Date( oneWeekAgo.getFullYear(), oneWeekAgo.getMonth(), oneWeekAgo.getDate() )
    return oneWeekAgoDateOnly
  } )
  const [ loading, setLoading ] = useState( false )
  const [ fileName, setFileName ] = useState( 'exported_data' )

  const columns = [
    {
      Header : 'Firmware Version',
      accessor : 'firmwareName',
    },
    {
      Header : 'Firmware Type',
      accessor : 'firmwareType'
    },
    {
      Header : 'Sent',
      accessor : 'ack',
    }
  ]

  const handleFileNameChange = ( filename ) => {
    setFileName( fileName )
  }

  const handleTimeChange = ( event ) => {
    const { value } = event
    const dateOnly = new Date( value.getFullYear(), value.getMonth(), value.getDate() )
    setStartTime( dateOnly )
  }

  const handleExportToCsv = () => {
    const fullFileName = `${fileName}.csv`
    exportToCsv( outboundMessagesList, columns, fullFileName )
    Toast.success( 'Successfully exported' )
  }

  const getFirmwareType = ( firmwareItem ) => {
    const binaries = firmwareItem?.data?.binaries

    if ( !binaries ) return ''

    if ( binaries.main?.path ) return 'main'
    if ( binaries.repeater?.path ) return 'repeater'
    if ( binaries.leaf?.path ) return 'leaf'
    if ( binaries.host?.path ) return 'host'

    return ''
  }

  const fetchFirmwareBlueprintData = async () => {
    try {
      const list = await dispatch( groupDelegate.list() )
      return list.items
    } catch ( error ) {
      console.error( 'Error fetching stuff', error )
      return null
    }
  }  

  const fetchData = async ( firmwareList ) => {
    try {
      setLoading( true )
      const accessToken = await dispatch( delegate.get( { accessToken : true } ) )
      const messagesList = await fetchFirmwareHistory( accessToken.accessToken, startTime.valueOf() )
      const enrichedMessagesList = messagesList.map( ( message ) => {
        const firmwareItem = firmwareList.find( item => item.id === message.firmwareId )
        const firmwareType = getFirmwareType( firmwareItem )

        return {
          ...message,
          firmwareName : firmwareItem?.data?.name || '',
          firmwareType
        }
      } )

      setOutboundMessagesList( enrichedMessagesList )
      setLoading( false )
    } catch ( error ) {
      console.error( 'Error fetching firmware history', error )
      Toast.error( 'Error fetching firmware history' )
      setLoading( false )
    }
    
  }

  useEffect( () => {
    const fetchDataInOrder = async () => {
      const firmwareList = await fetchFirmwareBlueprintData()
      if ( startTime ) {
        await fetchData( firmwareList )
      }
    };

    fetchDataInOrder()

  }, [ startTime ] )

  return (
    <Content>
      <Content.Header>
        <Flex variant="itemBar">
          <Flex variant="rowSpaceAround">
            <DateTimePickerV2 value={startTime} onChange={handleTimeChange}/>
            <Popup icon="fa fa-fw fa-download">
              <Pane variant="contentSection">
                <Text variant="title">Export to CSV</Text>
              </Pane>
              <Pane variant="contentSection"> 
                <Flex variant="colM" style={{ height : '100%' }}>
                  <TextInput
                    variant="small"
                    value={fileName}
                    onChange={handleFileNameChange}/>
                </Flex>                
              </Pane>
              <Pane variant="contentSection">
                <Flex variant="colM">
                  <Button variant="primary" onClick={handleExportToCsv}> Export</Button>
                </Flex>
              </Pane>
            </Popup>
          </Flex>
        </Flex>
      </Content.Header>
      <Content.Area>
        <Flex variant="colM">
          <TableV2
            variant="dataTable"
            data={outboundMessagesList}
            columns={columns}
            loading={loading}
            filterable={false}/>
        </Flex>
      </Content.Area>
    </Content>
  )

}

export default FirmwareHistory
