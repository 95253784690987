/* eslint-disable import/no-extraneous-dependencies */
import { RoutesUtil as Util } from '@leverege/molten/routes'
import { Breadcrumbs, Content, Flex, TableV2, Pane, Popup, Toast } from '@leverege/ui-elements'
import { Item } from '@leverege/ui-elements/lib/esm/Popup'
import { TitleBar, Toolbar } from '@leverege/ui-plugin'
import { GlobalState } from '@leverege/ui-redux'
import { push } from 'connected-react-router'
import React, { useEffect, useMemo, useState } from 'react'
import { deleteCanBus } from '../../utils/interface'
import ConfirmationDialog from '../../components/ConfirmationDialog'

const formatTimeStamp = ( timestamp ) => {
  const timeStamp = new Date( timestamp )
  return new Intl.DateTimeFormat( 'en-US', {
    year : 'numeric',
    month : '2-digit',
    day : '2-digit',
    hour : '2-digit',
    minute : '2-digit',
    hour12 : false,
    timeZoneName : 'short'
  } ).format( timeStamp )
}

function BoatCANBusScreen( props ) {
  const { match, parentItem } = props
  const { nmea } = props?.parentItem?.data?.data?.vessel || {}
  const networkName = parentItem?.data?.networkId || null
  const name = parentItem?.data?.networkAliases?.[networkName]?.esn || parentItem.data.name
  const boatId = parentItem?.data?.id || null
  const [ showConfirmation, setShowConfirmation ] = useState( false )

  const [ columns, setColumns ] = useState( [] )
  const mappedData = useMemo( () => {
    const data = nmea?.YMCBPSScanInfo || {}
    return Object.entries( data ).filter( ( v ) => {
      const id = Number( v[0] )
      return !Number.isNaN( id )
    } ).map( ( [ k, { timestamp, ...rest } ] ) => {
      return {
        channelNumber : k,
        ...rest,
        timeStamp : formatTimeStamp( timestamp )
      }
    } ) ?? []
  }, [ nmea ] )
  
  const rootPath = match ? Util.resolve( '/p/:persona/boat', match ) : ''
  const overViewPath = match ? Util.resolve( '/p/:persona/boat/:boatId/info', match ) : ''

  const onClickBreadcrumb = ( ctx ) => {
    const isRoot = ctx.data?.root
    GlobalState.dispatch( isRoot ? push( rootPath ) : push( overViewPath ) )
  }

  const onConfirmDeleteCanBus = async () => {
    try {
      await deleteCanBus( boatId )
      setShowConfirmation( false )
      Toast.success( 'BPS scan data deleted' )
    } catch ( error ) {
      console.error( 'Error deleting CAN bus data', error )
      Toast.error( 'Error deleting CAN bus data' )
    }
  }

  const onDeleteCanBus = () => {
    setShowConfirmation( true )
  }

  const onDenyDelete = () => {
    setShowConfirmation( false )
  }

  useEffect( () => {
    const newColumns = mappedData.reduce( ( acc, v ) => {
      const columns = Object.entries( v || {} )
        .filter( ( [ k, v ] ) => !k.includes( 'Length' ) && typeof v !== 'object' )
        .map( ( [ k, v ] ) => ( { Header : k, accessor : k } ) )
      if ( columns.length > acc.length ) {
        return columns 
      }
      return acc
    }, [] )
    
    setColumns( newColumns )
  }, [ mappedData ] )

  return ( 
    <Content>
      <Pane variant="topNavBreadcrumbs">
        <Breadcrumbs 
          variant="topNavBreadcrumbs"
          onClick={onClickBreadcrumb}
          crumbs={[ 
            { name : process.env.BOAT_BREADCRUMB_NAME, root : true },
            { name, device : true },
            { name : 'CAN' }
          ]}/>
      </Pane>
      <Content.Header>
        <TitleBar
          variant="screenTitle"
          title="CAN"
          icon="fa-solid fa-network-wired">
          { mappedData.length > 0 && (
            <Popup
              open
              closeOnEscape
              closeOnDocumentClick
              iconOn="fa-solid fa-ellipsis-vertical"
              iconOff="fa-solid fa-ellipsis-vertical">
              <Popup.Header>
                Siren Device - CAN Actions
              </Popup.Header>
              <Item
                icon="fa-light fa-trash"
                onClick={onDeleteCanBus}>
                Delete BPS scan data
              </Item>
            </Popup>
          )}
          <Toolbar
            variant="actionBar"
            prefer="icon"
            matchContext={{}}
            context={props} />
        </TitleBar>
      </Content.Header>
      <Content.Area>
        <Flex variant="colM" style={{ height : '100%' }}>
          <TableV2
            variant="dataTable"
            data={mappedData}
            columns={columns}
            filterable={false}
            sortable={false}/>
        </Flex>
        <ConfirmationDialog
          headerText="Confirm Delete"
          bodyText="Are you sure you want to delete BPS scan data?"
          show={showConfirmation}
          onConfirm={onConfirmDeleteCanBus}
          onDeny={onDenyDelete} />
      </Content.Area>
    </Content>
  )
}
export default BoatCANBusScreen 
