/* eslint-disable no-throw-literal */
import {
  Button,
  Dialog,
  Dialogs,
  Flex,
  Text,
  TextInput,
  Toast,
} from '@leverege/ui-elements'
import React, { useMemo, useState } from 'react'
import { setBoatFirmwareToBoat } from '../utils/interface'

export function FirmwareSendVersionMenuItem( props ) {
  const { show, onClose, context } = props
  const { target } = context
  const [ deviceESNS, setDeviceESNS ] = useState( '' )
  const [ isLoading, setIsLoading ] = useState( false )
  const [ submitted, setSubmitted ] = useState( false )

  const hasESNError = useMemo( () => !deviceESNS, [ deviceESNS ] )

  const onConfirm = async () => {
    try {
      setIsLoading( true )
      setSubmitted( true )
      if ( hasESNError ) return

      const esns = deviceESNS.split( ',' ).filter( v => !!v.trim().length )

      const responses = await Promise.allSettled(
        esns.map( esn => setBoatFirmwareToBoat( esn, target[0]?.id ) ),
      )

      const fulfilled = responses
        .map( ( v, i ) => {
          if ( v.status === 'fulfilled' ) return i
          return undefined
        } )
        .filter( v => v !== undefined )
      const rejected = responses
        .map( ( v, i ) => {
          if ( v.status === 'rejected' ) return i
          return undefined
        } )
        .filter( v => v !== undefined )

      if ( fulfilled.length ) {
        Toast.success(
          <>
            <div>Firmware version sent</div>
            <Text
              variant="contentInfo"
              style={{ color : '#39475695', fontSize : 14 }}>
              Version successfully sent for {fulfilled.length} device
              {fulfilled.length > 1 ? 's' : ''}, check your{' '}
              {fulfilled.length > 1 ?
                'devices, they should' :
                'device, it should'}{' '}
              report back the version once updated.
            </Text>
            <small>
              Accepted ESNS: {fulfilled.map( i => esns[i] ).join( ', ' )}
            </small>
          </>, {
            autoClose : 5000
          }
        )
      }
      
      onClose()

      if ( rejected.length ) throw ( { rejected, esns } )
    } catch ( { rejected, esns } ) {
      await new Promise( ( res ) => { setTimeout( res, 500 ) } )
      Toast.error(
        <>
          <div>Error updating boat firmware version.</div>
          <small>Rejected ESNS: {rejected.map( i => esns[i] ).join( ', ' )}</small>
        </>, {
          autoClose : false,
          closeOnClick : false
        }
      )
    } finally {
      setIsLoading( false )
    }
  }

  return (
    <Dialog show={show} onClose={onClose}>
      <Flex
        direction="column"
        align="stretch"
        gap={12}
        style={{
          padding : 20,
          background : '#F9FAFB',
          boxShadow : '0px 1px 3px rgba(41, 50, 61, 0.16)',
        }}>
        <Text variant="dialogTitle">Sending selected Firmware to Device</Text>
        <Flex variant="colS" align="stretch">
          <Text variant="label">Name</Text>
          <TextInput
            style={{
              height : 40,
              border : '1px solid #CED6DE',
              borderRadius : '4px',
            }}
            disabled
            value={target[0]?.data?.data?.name}/>
        </Flex>
        <Flex variant="colS" align="stretch">
          <Text variant="label">
            Send firmware to Devices ESN (Comma separated values)
          </Text>
          <TextInput
            style={{
              height : 40,
              border : '1px solid #CED6DE',
              borderRadius : '4px',
            }}
            hint="Device ESNS to send the firmware to"
            value={deviceESNS}
            onChange={( { value } ) => setDeviceESNS( value )}/>
          {submitted && hasESNError && (
            <Text style={{ color : '#ff0000' }}>Please, use a valid ESN.</Text>
          )}
        </Flex>
        <Button variant="secondary" onClick={onConfirm} disabled={isLoading}>
          Send to Device
        </Button>
      </Flex>
    </Dialog>
  )
}

export default {
  name : 'Send Firmware to Devices',
  id : 'firmware.group.actions',
  layout : {
    sort : 'z',
  },
  matches : {
    client : 'GroupScreen',
    objectType : 'firmware',
    path : 'firmware',
    use : 'contextMenu',
    usedIn : 'toolbar',
  },
  appearance : () => ( {
    icon : 'fa-light fa-paper-plane-top',
    name : 'Send Firmware to Devices',
  } ),
  handles : ( { target } ) => target.length === 1,
  perform : props => Dialogs.show( {
    props,
    component : FirmwareSendVersionMenuItem,
  } ),
}
