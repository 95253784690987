import Molten from '@leverege/molten'
import Config from './Config'
import PluginSetup from './PluginSetup'

import './global-style.css'

function start( opts = { } ) {

  const cfg = Config.create( opts )

  Molten.init( cfg )
  
  // Install your plugins
  PluginSetup.install( Molten )

  // Create an App and start it
  Molten.create( )
}

window.Application = { start }
