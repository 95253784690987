module.exports = {
  "hierarchy": {
    "dashboard": [
      "checkbox-dashboard"
    ],
    "agreementsCheckbox": [
      "checkbox-agreementsCheckbox"
    ],
    "none": [
      "checkbox-none"
    ]
  },
  "props": {
    "default": {
      "iconOn": "fa fa-check",
      "labelVariant": "checkboxLabel"
    },
    "dashboard": {
      "iconOn": "fa fa-circle",
      "labelVariant": "checkboxLabel"
    },
    "agreementsCheckbox": {
      "iconOn": "fa fa-check",
      "labelVariant": "checkboxLabelWrap"
    },
    "none": {
      "iconOn": "fa fa-check",
      "labelVariant": "checkboxLabel",
      "variants": null
    }
  }
}