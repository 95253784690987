import { GlobalState } from '@leverege/ui-redux'

export default function limitToRolesMutator( type, plugin, molten ) {
  const { id } = plugin

  if ( !id ) {
    return plugin
  }
  
  const roles = molten.getConfig( 'actions' )?.[id]?.limitToRoles

  if ( roles ) {
    console.log( 'LimitToRolesMutator active for plugin: ', plugin )

    return {
      ...plugin,
      handles : ( context ) => {
        const activeRoleName = GlobalState.get()?.ui?.activePersona?.name

        if ( typeof roles === 'string' ) {
          return roles === activeRoleName && plugin.handles( context )
        }

        if ( Array.isArray( roles ) ) {
          return roles.includes( activeRoleName ) && plugin.handles( context )
        }

        console.warn( 'Improperly formatted roles list for plugin', plugin )
        return plugin.handles( context )
      }
    }
  }

  return plugin
}
