/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { Button, Flex, Pane, Selector, Text, Toast, Label } from '@leverege/ui-elements'
import { useDispatch } from 'react-redux'
import { DataSources } from '@leverege/ui-attributes'
import ConfirmationDialog from './ConfirmationDialog'
import { fetchLatestSetEnvCommand } from '../utils/HistoryUtils'

function DeviceCommandsOverview( props ) {
  const { parentItem, objRef, onCloseDialog } = props
  const dispatch = useDispatch()
  const delegate = DataSources.getDelegate( objRef )

  const initialLowPowerMode = parentItem?.data?.data?.vessel?.lowPower?.powerMode ?? ''
  const hasLowPowerCommand = process.env.HAS_LOW_POWER_COMMAND === 'true'

  const [ lowPowerMode, setLowPowerMode ] = useState( initialLowPowerMode )
  const [ deviceEnvironment, setDeviceEnvironment ] = useState( '' )

  // next mode/enviroment states
  const [ nextLowPowerMode, setNextLowPowerMode ] = useState( 'off' )
  const [ nextDeviceEnvironment, setNextDeviceEnviornment ] = useState( 'production' )

  // dialog states
  const [ showDialog, setShowDialog ] = useState( false )
  const [ lowPowerDialog, setLowPowerDialog ] = useState( false )
  const [ deviceEnvironmentDialog, setDeviceEnviornmentDialog ] = useState( false )
  const [ resetDeviceDialog, setResetDeviceDialog ] = useState( false )
  const [ versionRequestDialog, setVersionRequestDialog ] = useState( false )
  const [ backtapDialog, setBacktapDialog ] = useState( false )
  const [ wirelessDialog, setWirelessDialog ] = useState( false )

  const lowPowerModes = [
    { name : 'Off', value : 'off' },
    { name : 'On', value : 'on' },
    { name : 'Auto', value : 'auto' }
  ]

  const deviceEnvironments = [
    { name : 'Production', value : 'production' },
    { name : 'Staging', value : 'staging' },
    { name : 'Development', value : 'development' }
  ]

  const sendCommand = async ( command ) => {
    try {
      await dispatch( delegate.messages().send( { command } ) )
      console.log( `Sent command: ${command}` )
      Toast.success( `Successfully sent command: ${command}` )
    } catch ( err ) {
      console.log( 'check error send command' )
      console.error( `Error sending command: ${err.message}` )
      Toast.error( 'Error Sending command' )
    }
  }

  const sendDeviceMsg = async ( path, value ) => {
    try {
      await dispatch( delegate.messages().send( { path, value } ) )
      console.log( 'Message sent' )
      Toast.success( 'Successfully sent message' )
    } catch ( err ) {
      console.log( 'check error send message' )
      console.error( `Error sending message: ${err.message}` )
      Toast.error( 'Error Sending message' )
    }
  }

  const onLowPowerModeConfirmCommand = () => {
    setLowPowerMode( nextLowPowerMode )
    sendCommand( `setLowPowerMode,${nextLowPowerMode}` )
    setShowDialog( false )
    setLowPowerDialog( false )
  }

  const onDeviceEnviornmentConfirmCommand = () => {
    setDeviceEnvironment( nextDeviceEnvironment )
    sendCommand( `setEnv,${nextDeviceEnvironment}` )
    setShowDialog( false )
    setDeviceEnviornmentDialog( false )
  }

  const onDeviceResetConfirmCommand = () => {
    sendCommand( 'reset' )
    setShowDialog( false )
    setResetDeviceDialog( false )
  }

  const onDeviceCheckUpdatesConfirmCommand = () => {
    sendCommand( 'checkIn' )
    setShowDialog( false )
    setVersionRequestDialog( false )
  }

  const onDeviceSyncConfirmCommand = () => {
    sendCommand( 'backtap' )
    setShowDialog( false )
    setBacktapDialog( false )
  }

  const onDeviceSyncWirelessCommand = () => {
    sendDeviceMsg( 'wirelessSensorList', '' )
    setShowDialog( false )
    setWirelessDialog( false )
  }

  const onCancelCommand = () => {
    setShowDialog( false )
    setLowPowerDialog( false )
    setDeviceEnviornmentDialog( false )
    setResetDeviceDialog( false )
    setVersionRequestDialog( false )
  }

  const handleLowPowerModeChange = ( mode ) => {
    setNextLowPowerMode( mode.value )
    setLowPowerDialog( true )
    setShowDialog( true )
  }

  const handleDeviceEnvironmentChange = ( env ) => {
    setNextDeviceEnviornment( env.value )
    setDeviceEnviornmentDialog( true )
    setShowDialog( true )
  }

  const handleDeviceReset = () => {
    setResetDeviceDialog( true )
    setShowDialog( true )
  }

  const handleVersionRequest = () => {
    setVersionRequestDialog( true )
    setShowDialog( true )
  }

  const handleBackTap = () => {
    setBacktapDialog( true )
    setShowDialog( true )
  }

  const handleWirelessSync = () => {
    setWirelessDialog( true )
    setShowDialog( true )
  }

  useEffect( () => {
    const fetchData = async () => {
      try {
        const accessToken = await dispatch( delegate.get( { accessToken : true } ) )
        const latestSetEnvCommand = await fetchLatestSetEnvCommand( accessToken.accessToken )
        setDeviceEnvironment( latestSetEnvCommand )
      } catch ( error ) {
        console.error( 'Error fetching latest setEnv command:', error )
      }
    }
    fetchData()
  }, [] )

  const paneStyles = {
    padding : '8px',
  }

  const dialogBodyStyle = {
    gap : 4,
    padding : 10,
    background : '#f3f5f6',
    boxShadow : '0px 1px 3px rgba(41, 50, 61, 0.16)',
    borderBottom : '1px solid #f3f5f6',
    borderTop : '1px solid #f3f5f6'
  }

  const dialogFooterBody = {
    float : 'right',
    gap : 14
  }

  return (
    <Flex variant="colM">
      <Flex
        variant="col"
        style={dialogBodyStyle}>
        {hasLowPowerCommand && (
          <Pane variant="contentSection" layout="flex:colS" style={paneStyles}>
            <Label>Set Low Power mode to</Label>
            <Selector
              values={lowPowerModes}
              value={lowPowerMode}
              onChange={handleLowPowerModeChange}
              nameKey="name"
              valueKey="value"
              showFilter={false}/>
          </Pane>
        )}

        <Pane variant="contentSection" layout="flex:colS" style={paneStyles}>
          <Text>Point Device to Environment</Text>
          <Selector
            values={deviceEnvironments}
            value={deviceEnvironment}
            onChange={handleDeviceEnvironmentChange}
            nameKey="name"
            valueKey="value"
            showFilter={false}/>
        </Pane>

        <Pane variant="contentSection" layout="flex:colS" style={paneStyles}>
          <Button
            onClick={handleDeviceReset}
            variant="secondary">
            Reset Device
          </Button>
        </Pane>

        <Pane variant="contentSection" layout="flex:colS" style={paneStyles}>
          <Button
            onClick={handleVersionRequest}
            variant="secondary">
            Version Request
          </Button>
        </Pane>

        <Pane variant="contentSection" layout="flex:colS" style={paneStyles}>
          <Button
            onClick={handleBackTap}
            variant="secondary">
            BackTap
          </Button>
        </Pane>

        <Pane variant="contentSection" layout="flex:colS" style={paneStyles}>
          <Button
            onClick={handleWirelessSync}
            variant="secondary">
            Sync Wireless Pairing Tables
          </Button>
        </Pane>

        {lowPowerDialog && (
          <ConfirmationDialog
            show={showDialog}
            headerText="Command Confirmation"
            bodyText={`Are you sure you want to set Low Power Mode to ${nextLowPowerMode}?`}
            onDeny={() => onCancelCommand()}
            onConfirm={() => onLowPowerModeConfirmCommand()}/>
        )}
        {deviceEnvironmentDialog && (
          <ConfirmationDialog
            show={showDialog}
            headerText="Command Confirmation"
            bodyText={`Are you sure you want to point the Device to ${nextDeviceEnvironment}?`}
            onDeny={() => onCancelCommand()}
            onConfirm={() => onDeviceEnviornmentConfirmCommand()}/>
        )}
        {resetDeviceDialog && (
          <ConfirmationDialog
            show={showDialog}
            headerText="Command Confirmation"
            bodyText="Are you sure you want to reset the Device?"
            onDeny={() => onCancelCommand()}
            onConfirm={() => onDeviceResetConfirmCommand()}/>
        )}
        {versionRequestDialog && (
          <ConfirmationDialog
            show={showDialog}
            headerText="Command Confirmation"
            bodyText="Are you sure you want to do a version request?"
            onDeny={() => onCancelCommand()}
            onConfirm={() => onDeviceCheckUpdatesConfirmCommand()}/>
        )}
        {backtapDialog && (
          <ConfirmationDialog
            show={showDialog}
            headerText="Command Confirmation"
            bodyText="Are you sure you want to backtap"
            onDeny={() => onCancelCommand()}
            onConfirm={() => onDeviceSyncConfirmCommand()}/>
        )}
        {wirelessDialog && (
          <ConfirmationDialog
            show={showDialog}
            headerText="Command Confirmation"
            bodyText="Are you sure you want to sync wireless pairing tables"
            onDeny={() => onCancelCommand()}
            onConfirm={() => onDeviceSyncWirelessCommand()}/>
        )}
      </Flex>
      <div style={{ padding : '10px 16px 10px 16px' }}>
        <Flex
          alignSelf="flex-end"
          style={dialogFooterBody}>
          <Flex>
            <Button
              variant="secondary"
              onClick={onCloseDialog}>
              Cancel
            </Button>
          </Flex>
        </Flex>
      </div>
      
    </Flex>
  )
}

export default DeviceCommandsOverview
