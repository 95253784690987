import { Button, Checkbox, Flex, Text, TextInput } from '@leverege/ui-elements'
import React, { useMemo, useState } from 'react'

function BoatAlertRecipientsDetailsForm( {
  initialValues,
  selectedRecipients,
  onCancel,
  onSave,
} ) {
  const [ isLoading, setIsLoading ] = useState( false )
  const onlyOneRecipientSelected = selectedRecipients.length <= 1
  const [ values, setValues ] = useState(
    Object.entries( initialValues || {} ).length ?
      initialValues :
      {
        mobile : {
          active : false,
        },
        dailyReportsEnabled : false,
        email : {
          active : false,
        },
      },
  )

  const [ submitted, setSubmitted ] = useState( false )

  const hasNameError = useMemo(
    () => onlyOneRecipientSelected &&
      !!values?.name &&
      ( !values?.name || !values?.name === '' ),
    [ values?.name, onlyOneRecipientSelected ],
  )
  const hasEmailError = useMemo(
    () => onlyOneRecipientSelected &&
      !!values?.email?.value &&
      !String( values?.email?.value )
        .toLowerCase()
        .match( /^\S+@\S+\.\S+$/ ),
    [ values?.email?.value, onlyOneRecipientSelected ],
  )
  const hasPhoneError = useMemo(
    () => onlyOneRecipientSelected &&
      !!values?.mobile?.value &&
      !String( values?.mobile?.value )
        .toLowerCase()
        .match( /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im ),
    [ values?.mobile?.value, onlyOneRecipientSelected ],
  )

  const handleSave = async () => {
    try {
      setIsLoading( true )
      setSubmitted( true )
      if ( hasEmailError | hasNameError | hasPhoneError ) return
      await onSave( values )
    } finally {
      setIsLoading( false )
    }
  }

  return (
    <Flex direction="column" align="stretch">
      <Flex
        variant="col"
        align="stretch"
        style={{
          gap : 12,
          padding : 10,
          background : '#f3f5f6',
          boxShadow : '0px 1px 3px rgba(41, 50, 61, 0.16)',
        }}>
        <Flex variant="colS" align="stretch">
          <Text variant="label">Name</Text>
          <TextInput
            style={{
              height : 40,
              border : '1px solid #CED6DE',
              borderRadius : '4px',
            }}
            disabled={!onlyOneRecipientSelected}
            value={String( values?.name || '' )}
            hint={
              onlyOneRecipientSelected ?
                'Add Name' :
                'Cannot bulk apply boat Name changes'
            }
            onChange={( { value } ) => setValues( s => ( { ...s, name : value } ) )}/>
          {submitted && hasNameError && (
            <Text style={{ color : '#ff0000' }}>Name is a required field.</Text>
          )}
        </Flex>

        <Flex variant="colS" align="stretch">
          <Text variant="label">Email</Text>
          <Flex align="center" gap={10}>
            <TextInput
              disabled={!onlyOneRecipientSelected}
              value={values?.email?.value}
              hint={
                onlyOneRecipientSelected ?
                  'Add Email (Format name@domain)' :
                  'Cannot bulk apply Email changes'
              }
              onChange={( { value } ) => setValues( s => ( {
                ...s,
                email : {
                  ...s.email,
                  value,
                  active : !value ? false : s?.email?.active,
                },
              } ) )}/>
            <Flex gap={8} align="center">
              <Checkbox
                value={values?.email?.active}
                onChange={( { value } ) => setValues( s => ( {
                  ...s,
                  email : { ...s.email, active : value },
                } ) )}/>
              <Text variant="label">Enabled</Text>
            </Flex>
          </Flex>
          {submitted && hasEmailError && (
            <Text style={{ color : '#ff0000' }}>
              Please, use a valid email address.
            </Text>
          )}
        </Flex>
        <Flex variant="colS" align="stretch">
          <Text variant="label">Phone</Text>
          <Flex align="center" gap={10}>
            <TextInput
              disabled={!onlyOneRecipientSelected}
              value={values?.mobile?.value}
              hint={
                onlyOneRecipientSelected ?
                  'Add Phone (Format (XXX)XXX-XXXX)' :
                  'Cannot bulk apply phone changes'
              }
              onChange={( { value } ) => setValues( s => ( {
                ...s,
                mobile : {
                  ...s.mobile,
                  value,
                  active : !value ? false : s?.mobile?.active,
                },
              } ) )}/>
            <Flex gap={8} align="center">
              <Checkbox
                value={values?.mobile?.active}
                onChange={( { value } ) => setValues( s => ( {
                  ...s,
                  mobile : { ...s.mobile, active : value },
                } ) )}/>
              <Text variant="label">Enabled</Text>
            </Flex>
          </Flex>
          {submitted && hasPhoneError && (
            <Text style={{ color : '#ff0000' }}>
              Please, use a valid phone number.
            </Text>
          )}
        </Flex>
        <Flex variant="colS" align="stretch">
          <Text variant="label">Daily Status</Text>
          <Flex align="center" gap={8}>
            <Checkbox
              value={values?.dailyReportsEnabled}
              onChange={( { value } ) => setValues( s => ( {
                ...s,
                dailyReportsEnabled : value,
              } ) )}/>
            <Text variant="label">Enabled</Text>
          </Flex>
        </Flex>
      </Flex>
      <div style={{ padding : 12 }}>
        <Flex alignSelf="flex-end" style={{ float : 'right', gap : 14 }}>
          <Flex>
            <Button
              variant="secondary"
              style={{ width : 108 }}
              onClick={onCancel}>
              Cancel
            </Button>
          </Flex>
          <Flex>
            <Button variant="primary" onClick={handleSave} disabled={isLoading}>
              Save Changes
            </Button>
          </Flex>
        </Flex>
      </div>
    </Flex>
  )
}

export default BoatAlertRecipientsDetailsForm
