/* eslint-disable no-throw-literal */
import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  Text,
  Toast,
  Content,
} from '@leverege/ui-elements'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { GlobalState } from '@leverege/ui-redux'
import Molten from '@leverege/molten'
import S from './RetryFirmwareDownload.css'
import {
  capitalize,
  getWirelessESN,
  firmwareFileGetter,
  getWirelessFirmwareVersion,
} from '../../utils/FirmwareUtils'

const hostFirmware = firmwareFileGetter( 'host' )
const sensorFirmware = firmwareFileGetter( 'leaf' )
const repeaterFirmware = firmwareFileGetter( 'repeater' )

export function RetryFirmwareDownload( { show, onClose, context } ) {
  const deviceId = context?.clientProps?.parentItem?.id
  const systemId = Molten.getConfig( 'api/systemId' )
  const [ selectedFirmware, setSelectedFirmware ] = useState( null )
  const [ selectedSensorIds, setSelectedSensorIds ] = useState( {} )
  const [ devices, setDevices ] = useState( [] )
  const [ currentDevice, setCurrentDevice ] = useState( {} )

  const [ loading, setLoading ] = useState( !devices )
  const [ showRetryDialog, setShowRetryDialog ] = useState( false )

  useEffect( () => {
    const loadWirelessDevices = async () => {
      const { items : devices } = await GlobalState.dispatch(
        ( dispatch, getState, { api } ) => api.interface( systemId, 'boat' )
          .obj( deviceId )
          .grp( 'wireless' )
          .list( { perPage : 9999 } )
      )
      setDevices( devices )
    }

    const loadDevice = async () => {
      const device = await GlobalState.dispatch(
        ( dispatch, getState, { api } ) => api.interface( systemId, 'boat' )
          .obj( deviceId )
          .get()
      )
      setCurrentDevice( device )
    }

    loadDevice()
    loadWirelessDevices()
  }, [ systemId, deviceId ] )

  const firmwareVersion = useMemo( () => {
    const wirelessSensor = sensorFirmware( currentDevice )
    const wirelessHost = hostFirmware( currentDevice )
    const wirelessRepeater = repeaterFirmware( currentDevice )

    return {
      sensor : wirelessSensor !== 'Not Available' ? `Version ${wirelessSensor}` : 'Not Available',
      host : wirelessHost !== 'Not Available' ? `Version ${wirelessHost}` : 'Not Available',
      repeater : wirelessRepeater !== 'Not Available' ? `Version ${wirelessRepeater}` : 'Not Available',
    }
  }, [ currentDevice ] )

  const onCancelCommand = () => {
    setShowRetryDialog( false )
  }

  const toggleSelectedFirmware = useCallback( ( firmwareType ) => {
    if ( selectedFirmware === firmwareType ) {
      setSelectedFirmware( null )
    } else {
      setSelectedFirmware( firmwareType )
      
      if ( firmwareType !== 'sensor' ) {
        setSelectedSensorIds( {} )
      } else {
        
        const sensorIds = devices.reduce( ( acc, sensor ) => {
          const esn = getWirelessESN( sensor )
          acc[esn] = true
          return acc
        }, {} )
        setSelectedSensorIds( sensorIds )
      }
    }
  }, [ selectedFirmware, devices ] )

  const toggleSelectedSensor = useCallback( ( sensorId ) => {
    setSelectedSensorIds( prev => ( {
      ...prev,
      [sensorId] : !prev[sensorId],
    } ) )
  }, [] )

  const sendRetryCommand = useCallback( async ( payload ) => {
    await GlobalState.dispatch(
      async ( dispatch, getState, { api } ) => {
        await api.interface( systemId, 'boat' )
          .obj( deviceId )
          .messages()
          .send( {
            path : 'wirelessSensor/firmware/retry',
            value : payload,
          } )
      }
    )
  }, [ systemId, deviceId ] )

  const onConfirmRetry = () => {
    let payload
    if ( selectedFirmware === 'host' ) {
      payload = {
        hostId : '1'.repeat( 16 )
      }
    } else if ( selectedFirmware === 'repeater' ) {
      payload = {
        repeaterId : '1'.repeat( 16 )
      }
    } else if ( selectedFirmware === 'sensor' ) {
      payload = {
        sensorIds : Object.keys( selectedSensorIds )
      }
    }
    if ( payload ) {
      sendRetryCommand( payload )
      Toast.success( 'Message sent' )
      onClose()
    }
  }

  return (
    <Dialog show={show} onClose={onClose}>
      <Flex
        direction="column"
        align="stretch"
        gap={12}
        style={{
          padding : 20,
          background : '#F9FAFB',
          boxShadow : '0px 1px 3px rgba(41, 50, 61, 0.16)',
        }}>
        <Text variant="dialogTitle">Retry Firmware Download</Text>
        <Flex variant="colS" align="stretch">
          <Text variant="label">
            Select the Firmwares that you want to retry.
            <br />
            The unit will automatically retry the latest firmware version.
          </Text>
        </Flex>

        {[ 'host', 'repeater', 'sensor' ].map( f => (
          <Flex key={f} variant="colS" direction="row" justify="space-between">
            <Flex variant="colS" direction="row">
              <Checkbox
                variant="row"
                value={selectedFirmware === f}
                onChange={() => toggleSelectedFirmware( f )}/>
              <Text variant="label">Wireless {capitalize( f )}</Text>
            </Flex>
            <Text>{firmwareVersion[f]}</Text>
          </Flex>
        ) )}

        {devices.length > 0 && selectedFirmware === 'sensor' && (
          <Content.Area className={S.sensorList}>
            {devices.map( ( sensor ) => {
              const esn = getWirelessESN( sensor )
              return (
                <Flex key={sensor?.id} direction="row" justify="space-between" className={S.firmwareTypeItem}>
                  <Flex direction="row" align="center">
                    <Checkbox
                      variant="row"
                      value={!!selectedSensorIds[esn]}
                      onChange={() => toggleSelectedSensor( esn )}/>
                    <Text className={S.firmwareTitle}>{sensor?.data?.name}</Text>
                    <Text className={S.firmwareSubtitle}>{esn}</Text>
                  </Flex>
                  <Text className={S.firmwareSubtitle}>
                    {getWirelessFirmwareVersion( sensor, { device : currentDevice } )}
                  </Text>
                </Flex>
              )
            } )}
          </Content.Area>
        )}

        <Flex variant="colS" justify="flex-end" direction="row">
          <Button variant="secondary" onClick={onClose}>Cancel</Button>
          <Button variant="primary" disabled={!selectedFirmware?.length} onClick={!selectedFirmware?.length ? () => {} : () => onConfirmRetry()}>Retry</Button>
        </Flex>
      </Flex>
    </Dialog>
  )
}

export default RetryFirmwareDownload
