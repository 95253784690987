import React, { useCallback, useEffect, useState } from 'react';
import Papa from 'papaparse';
import { Content, Dialog, Flex, Table } from '@leverege/ui-elements';
import { TitleBar } from '@leverege/ui-plugin';
import { formatFileName } from '../utils/FormatFileName'

const formatTimeStamp = ( timestamp ) => {
  if ( timestamp == null ) return null
  const timeStamp = new Date( timestamp )
  if ( Number.isNaN( timeStamp.getTime() ) ) return null
  return new Intl.DateTimeFormat( 'en-US', {
    year : 'numeric',
    month : '2-digit',
    day : '2-digit',
    hour : '2-digit',
    minute : '2-digit',
    hour12 : false,
    timeZoneName : 'short'
  } ).format( timeStamp )
}

function CSVViewer( { showModal, onClose, items, fetchNewReportUrl } ) {
  const [ data, setData ] = useState( [] );
  const [ loading, setLoading ] = useState( true );
  
  const fetchData = useCallback( async () => {
    setLoading( true );
    try {
      const url = await fetchNewReportUrl( items.id )
      const response = await fetch( url );
      const csv = await response.text();
      const parsedData = Papa.parse( csv, { header : true } ).data;
      const formattedData = parsedData.map( row => ( {
        ...row,
        'Last Update' : formatTimeStamp( row['Last Update'] ),
      } ) )
      setData( formattedData );
    } catch ( error ) {
      console.error( 'Failed to load data:', error );
    } finally {
      setLoading( false );
    }
  }, [ items ] );

  useEffect( () => {
    if ( showModal ) {
      fetchData();
    }
  }, [ fetchData, showModal ] );

  const headers = data.length > 0 ? Object.keys( data[0] ).map( key => ( {
    Header : key,
    accessor : key,
  } ) ) : [];

  const calculateMinColumnWidth = ( header ) => {
    const headerWidth = header.Header.length * 10;
    const maxWidthInData = Math.max(
      ...data.map( row => ( row[header.accessor] || '' ).toString().length * 7 ) 
    );
    return Math.max( headerWidth, maxWidthInData, 100 ); 
  };

  return (
    <Dialog onClose={onClose} closeOnDocumentClick closeOnEscape show={showModal}>
      <Content.Header>
        <TitleBar
          variant="screenTitle"
          title={formatFileName( items )}/>
      </Content.Header>
      <Content.Area>
        <Flex style={{ width : 1400, height : 800 }}>
          <Table
            data={data}
            columns={headers.map( header => ( {
              ...header,
              width : calculateMinColumnWidth( header ),
            } ) )}
            loading={loading}
            loadingText={<Flex className="defaultFlex" justify="center"><i className="fa fa-spinner fa-spin fa-2x fa-fw" /></Flex>}/>
        </Flex>
        {/* <Table
          data={data}
          columns={headers.map( header => ( {
            ...header,
            width : calculateMinColumnWidth( header ),
          } ) )}
          loading={loading}
          loadingText={<Flex className="defaultFlex" justify="center"><i className="fa fa-spinner fa-spin fa-2x fa-fw" /></Flex>}/> */}
      </Content.Area>
    </Dialog>
  );
}

export default CSVViewer;
