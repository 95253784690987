/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import Molten from '@leverege/molten'
import { GlobalState, UI } from '@leverege/ui-redux'
import debounce from 'lodash.debounce'
import { useEffect } from 'react'

function FleetHiddenDataViewer( props ) {
  const systemId = Molten.getConfig( 'api/systemId' )
  const {
    item : { id : boatId },
  } = props
  const debouncedUpdateBoatIds = debounce( async () => {
    try {
      const { items : boats } = await GlobalState.dispatch(
        ( dispatch, getState, { api } ) => {
          return api
            .interface( systemId, 'fleet' )
            .obj( '*' )
            .grp( 'boat' )
            .obj( boatId )
            .get()
        },
      )

      const { items : fleets } = await GlobalState.dispatch(
        ( dispatch, getState, { api } ) => {
          return api.interface( systemId, 'fleet' ).search( { perPage : 9999 } )
        },
      )

      const mappedFleets = fleets
        .map( ( fleet ) => {
          if ( boats.find( b => b.fleet === fleet.id ) ) {
            return {
              fleetId : fleet.id,
              fleetName : fleet?.data?.name,
              boatIds : boats
                .filter( b => b.fleet === fleet.id )
                .map( v => v['fleet/boat'] ),
            }
          }
          return undefined
        } )
        .filter( v => !!v && !!v?.fleetName )

      GlobalState.dispatch( UI.set( 'boatFleets', mappedFleets ) )
    } catch ( e ) {
      console.error( e )
    }
  }, 500 )

  useEffect( () => {
    debouncedUpdateBoatIds()
  }, [ boatId ] )

  return null
}

export default {
  type : 'BoatFleetHiddenDataViewer',
  name : 'Fleet info hidden data viewer component',
  matches : [ { objectType : 'boat' } ],
  location : 'hidden',
  handles : () => true,
  component : FleetHiddenDataViewer,
  id : 'siren.boat.FleetHiddenDataViewer',
}
