// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dialog_AflVZ {
  background: #ffffff;
  box-shadow: 0 16px 32px rgba(41, 50, 61, 0.16), 0 0 1px rgba(41, 50, 61, 0.32);
  border-radius: 8px;
}

.content_d5PnH {
  width: auto;
  height: auto;

  .header_sfveL {
    max-width: 600px;
    margin: 16px 8px 5px;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #3e4243;
  }

  .contentArea_WnuEB {
    max-width: 600px;
    margin: 5px 16px 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #717476;
  }

  .footer_Q7MFb {
    margin-bottom: 8px;
  }
}

.sensorList_BLudl {
  height: 180px;
  max-height: 180px;
  margin-top: -8px;
  padding: 0px 16px 0px 8px;
  overflow-x: hidden;
  overflow-y: auto;
}

.firmwareTypeItem_GFAhB {
  padding: 16px 0px;
}

.firmwareSubtitle_qnony {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-left: 8px;
}

.firmwareTitle_glDBT {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/wireless/actions/RetryFirmwareDownload.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;EACnB,8EAA8E;EAC9E,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;;EAEZ;IACE,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["\n.dialog {\n  background: #ffffff;\n  box-shadow: 0 16px 32px rgba(41, 50, 61, 0.16), 0 0 1px rgba(41, 50, 61, 0.32);\n  border-radius: 8px;\n}\n\n.content {\n  width: auto;\n  height: auto;\n\n  .header {\n    max-width: 600px;\n    margin: 16px 8px 5px;\n    font-style: normal;\n    font-weight: 800;\n    font-size: 20px;\n    line-height: 24px;\n    color: #3e4243;\n  }\n\n  .contentArea {\n    max-width: 600px;\n    margin: 5px 16px 8px;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 16px;\n    line-height: 24px;\n    color: #717476;\n  }\n\n  .footer {\n    margin-bottom: 8px;\n  }\n}\n\n.sensorList {\n  height: 180px;\n  max-height: 180px;\n  margin-top: -8px;\n  padding: 0px 16px 0px 8px;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.firmwareTypeItem {\n  padding: 16px 0px;\n}\n\n.firmwareSubtitle {\n  font-style: normal;\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 24px;\n  margin-left: 8px;\n}\n\n.firmwareTitle {\n  font-style: normal;\n  font-weight: 800;\n  font-size: 18px;\n  line-height: 24px;\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": `dialog_AflVZ`,
	"content": `content_d5PnH`,
	"header": `header_sfveL`,
	"contentArea": `contentArea_WnuEB`,
	"footer": `footer_Q7MFb`,
	"sensorList": `sensorList_BLudl`,
	"firmwareTypeItem": `firmwareTypeItem_GFAhB`,
	"firmwareSubtitle": `firmwareSubtitle_qnony`,
	"firmwareTitle": `firmwareTitle_glDBT`
};
export default ___CSS_LOADER_EXPORT___;
