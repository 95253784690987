module.exports = {
  "hierarchy": {
    "mobileItemScreen": [
      "breadcrumbs-mobileItemScreen"
    ]
  },
  "props": {
    "default": {
      "separator": "|"
    },
    "mobileItemScreen": {
      "separator": "|"
    }
  }
}