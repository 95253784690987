module.exports = {
  "hierarchy": {
    "hollow": [
      "dateTimePicker-hollow"
    ],
    "test": [
      "dateTimePicker-test"
    ],
    "timeOnly": [
      "dateTimePicker-timeOnly"
    ]
  },
  "props": {
    "default": {},
    "hollow": {},
    "test": {},
    "timeOnly": {}
  }
}