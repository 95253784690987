import { LinkAction } from '@leverege/molten/routes'

const screenPath = '/p/:persona/boat/:boatId/nmea'
let nmeaName = 'NMEA'

if ( process.env.NMEA_PAGE_NAME ) {
  nmeaName = process.env.NMEA_PAGE_NAME
}
export default LinkAction.create( {
  path : screenPath,
  name : nmeaName, 
  id : 'siren.boat.link.DeviceNMEA',
  icon : 'fa-solid fa-engine',
  layout : {
    sort : 'c.6'
  },
  matches : {
    client : 'ItemScreen',
    use : 'navBar',
    objectType : 'boat',
    path : 'boat'
  }
} )
