const { useState } = require( 'react' );

const useSort = () => {
  const [ sort, setSort ] = useState( [ [ 'time', 'DESC' ] ] )
  const handleSort = async ( columnId ) => {
    const newSortDirection =
            sort?.[0]?.[1] === 'desc' ? 'asc' : 'desc';
    setSort( [ [ columnId, newSortDirection ] ] );
  }

  return { handleSort, sort, setSort }
}

export default useSort
