/* eslint-disable import/no-extraneous-dependencies */
import { diff } from '@leverege/array-util'
import Molten from '@leverege/molten'
import { GlobalState, UI } from '@leverege/ui-redux'
import debounce from 'lodash.debounce'
import { useEffect, useState } from 'react'
import { getParentBoats } from '../../utils/interface'

function ESNHiddenDataViewer( props ) {
  const systemId = Molten.getConfig( 'api/systemId' )
  const { data : { items } } = props
  const [ parentBoatIds, setParentBoatIds ] = useState( [] )
  
  const debouncedUpdateBoatIds = debounce( async () => {
    const boatIds = items?.map( wireless => wireless?.data?.data?.boat?.id )
    const newParentBoatIds = [ ...new Set( boatIds ) ].filter( Boolean )
    
    if ( !isEqual( parentBoatIds, newParentBoatIds ) ) {
      const mappedBoats = await getParentBoats( systemId, newParentBoatIds )
      setParentBoatIds( newParentBoatIds )
      GlobalState.dispatch( UI.set( 'boats', mappedBoats ) )
    }
  }, 500 )
  
  useEffect( () => {
    debouncedUpdateBoatIds()
  }, [ items ] )

  return null
}

function isEqual( arr1, arr2 ) {
  const { added, removed } = diff( arr1, arr2 )
  return added.length === 0 && removed.length === 0
}

export default { 
  type : 'ESNHiddenDataViewer',
  name : 'ESN info hidden data viewer component',
  matches : [ { objectType : 'wireless' } ],
  location : 'hidden',
  handles : () => true,
  component : ESNHiddenDataViewer,
  id : 'siren.wireless.ESNHiddenDataViewer' 
} 
