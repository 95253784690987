import React from 'react'
import { useSelector } from 'react-redux'

export default function ParentESN( boatId ) {
  const parentBoats = useSelector( state => state.ui.boats )

  if ( !Object.values( parentBoats || {} )?.length ) return <i className="fa-solid fa-spinner-third fa-spin" />
  
  return ( parentBoats?.[boatId] || '-' )
}
