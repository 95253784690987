module.exports = {
  "hierarchy": {
    "rowS": [
      "flex-rowS"
    ],
    "rowM": [
      "flex-rowM"
    ],
    "rowSpaceBetween": [
      "flex-rowSpaceBetween"
    ],
    "rowMEnd": [
      "flex-rowM",
      "flex-rowMEnd"
    ],
    "col": [
      "flex-col"
    ],
    "colS": [
      "flex-col",
      "flex-colS"
    ],
    "colM": [
      "flex-col",
      "flex-colM"
    ],
    "colL": [
      "flex-col",
      "flex-colL"
    ],
    "colXL": [
      "flex-col",
      "flex-colXL"
    ],
    "colXXL": [
      "flex-col",
      "flex-colXXL"
    ],
    "dialogButtons": [
      "flex-rowM",
      "flex-rowMEnd",
      "flex-dialogButtons"
    ],
    "hNavBar": [
      "flex-hNavBar"
    ],
    "vNavBar": [
      "flex-vNavBar"
    ],
    "itemBar": [
      "flex-itemBar"
    ],
    "screenTitle": [
      "flex-screenTitle"
    ],
    "screenTitleControls": [
      "flex-screenTitleControls"
    ],
    "topNavBar": [
      "flex-topNavBar"
    ],
    "topNavControls": [
      "flex-topNavControls"
    ],
    "sideNav": [
      "flex-sideNav"
    ],
    "loadingScreen": [
      "flex-loadingScreen"
    ],
    "tableToolbar": [
      "flex-tableToolbar"
    ],
    "rowL": [
      "flex-rowM",
      "flex-rowL"
    ],
    "dialogTitle": [
      "flex-screenTitle",
      "flex-dialogTitle"
    ],
    "dialogTitleControls": [
      "flex-screenTitleControls",
      "flex-dialogTitleControls"
    ],
    "tableHeader": [
      "flex-rowM",
      "flex-tableHeader"
    ],
    "aggBucketVertical": [
      "flex-aggBucketVertical"
    ],
    "aggBucketHorizontal": [
      "flex-aggBucketHorizontal"
    ],
    "mobileMainHeaderControls": [
      "flex-screenTitleControls",
      "flex-mobileMainHeaderControls"
    ],
    "colSpaceBetween": [
      "flex-colSpaceBetween"
    ],
    "rowMCenter": [
      "flex-rowM",
      "flex-rowMCenter"
    ],
    "colMCenter": [
      "flex-col",
      "flex-colM",
      "flex-colMCenter"
    ],
    "aggregationBucketFlexHorizontal": [
      "flex-aggBucketHorizontal",
      "flex-aggregationBucketFlexHorizontal"
    ],
    "colSCenter": [
      "flex-col",
      "flex-colS",
      "flex-colSCenter"
    ],
    "colMStart": [
      "flex-col",
      "flex-colM",
      "flex-colMStart"
    ],
    "colSStart": [
      "flex-col",
      "flex-colS",
      "flex-colSStart"
    ],
    "rowMMiddle": [
      "flex-rowM",
      "flex-rowMCenter",
      "flex-rowMMiddle"
    ],
    "mobileNavRoutes": [
      "flex-col",
      "flex-colM",
      "flex-mobileNavRoutes"
    ],
    "sideNavBarCollapse": [
      "flex-sideNavBarCollapse"
    ],
    "footerItemBar": [
      "flex-itemBar",
      "flex-footerItemBar"
    ],
    "layoutActionBar": [
      "flex-layoutActionBar"
    ],
    "center": [
      "flex-center"
    ],
    "aggregationsContainerToolbar": [
      "flex-tableToolbar",
      "flex-aggregationsContainerToolbar"
    ],
    "mobileOptNavTitle": [
      "flex-mobileOptNavTitle"
    ],
    "mobileOptNavTitleControls": [
      "flex-mobileOptNavTitleControls"
    ],
    "sidenavbarcollapse": [
      "flex-sidenavbarcollapse"
    ],
    "pairDetail": [
      "flex-pairDetail"
    ],
    "bulkCreateResults": [
      "flex-bulkCreateResults"
    ],
    "rowXXL": [
      "flex-rowXXL"
    ],
    "rowMStart": [
      "flex-rowM",
      "flex-rowMStart"
    ],
    "sideNavControls": [
      "flex-sideNavControls"
    ],
    "rowSpaceBetweenCenter": [
      "flex-rowSpaceBetween",
      "flex-rowSpaceBetweenCenter"
    ],
    "rowSpaceAround": [
      "flex-rowSpaceAround"
    ],
    "rowStretch": [
      "flex-rowStretch"
    ],
    "sortFilterBar": [
      "flex-sortFilterBar"
    ],
    "preConditionBar": [
      "flex-preConditionBar"
    ],
    "filterBar": [
      "flex-filterBar"
    ],
    "conditionRow": [
      "flex-conditionRow"
    ]
  },
  "props": {
    "default": {},
    "rowS": {},
    "rowM": {},
    "rowSpaceBetween": {},
    "rowMEnd": {},
    "col": {},
    "colS": {},
    "colM": {},
    "colL": {},
    "colXL": {},
    "colXXL": {},
    "dialogButtons": {},
    "hNavBar": {},
    "vNavBar": {},
    "itemBar": {},
    "screenTitle": {},
    "screenTitleControls": {},
    "topNavBar": {},
    "topNavControls": {},
    "sideNav": {},
    "loadingScreen": {},
    "tableToolbar": {},
    "rowL": {},
    "dialogTitle": {},
    "dialogTitleControls": {},
    "tableHeader": {},
    "aggBucketVertical": {},
    "aggBucketHorizontal": {},
    "mobileMainHeaderControls": {},
    "colSpaceBetween": {},
    "rowMCenter": {},
    "colMCenter": {},
    "aggregationBucketFlexHorizontal": {},
    "colSCenter": {},
    "colMStart": {},
    "colSStart": {},
    "rowMMiddle": {},
    "mobileNavRoutes": {},
    "sideNavBarCollapse": {},
    "footerItemBar": {},
    "layoutActionBar": {},
    "center": {},
    "aggregationsContainerToolbar": {},
    "mobileOptNavTitle": {},
    "mobileOptNavTitleControls": {},
    "sidenavbarcollapse": {},
    "pairDetail": {},
    "bulkCreateResults": {},
    "rowXXL": {},
    "rowMStart": {},
    "sideNavControls": {},
    "rowSpaceBetweenCenter": {},
    "rowSpaceAround": {},
    "rowStretch": {},
    "sortFilterBar": {},
    "preConditionBar": {},
    "filterBar": {},
    "conditionRow": {}
  }
}