module.exports = {
  "hierarchy": {
    "small": [
      "list-small"
    ],
    "dataListItem": [
      "list-dataListItem"
    ],
    "mobileDetail": [
      "list-mobileDetail"
    ],
    "groupData": [
      "list-groupData"
    ],
    "detailItem": [
      "list-detailItem"
    ],
    "mobileSuggestions": [
      "list-mobileSuggestions"
    ],
    "suggestions": [
      "list-suggestions"
    ],
    "pairing": [
      "list-pairing"
    ],
    "operatingHoursOverrides": [
      "list-operatingHoursOverrides"
    ]
  },
  "props": {
    "default": {
      "icon": "fa fa-check"
    },
    "small": {
      "icon": "fa fa-check"
    },
    "dataListItem": {
      "icon": "fa fa-check"
    },
    "mobileDetail": {
      "icon": "fa fa-check"
    },
    "groupData": {
      "icon": "fa fa-check"
    },
    "detailItem": {
      "icon": "fa fa-check"
    },
    "mobileSuggestions": {
      "icon": "fa fa-check"
    },
    "suggestions": {
      "icon": "fa fa-check"
    },
    "pairing": {
      "icon": "fa fa-check"
    },
    "operatingHoursOverrides": {
      "icon": "fa fa-check"
    }
  }
}