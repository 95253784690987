import { LinkAction } from '@leverege/molten/routes'

export default LinkAction.create( {
  id : 'boat.link.DeviceCommandsHistory',
  name : 'Device Commands History',
  icon : 'fa-solid fa-command',
  layout : {
    sort : 'c.1',
    sectionName : 'Device Management'
  },
  matches : {
    client : 'ItemScreen',
    use : 'navBar',
    objectType : 'boat',
    path : 'boat'
  },
  path : '/p/:persona/boat/:boatId/DeviceCommands'
} )
