/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import BoatsFleets from '../utils/BoatsFleets'

export default {
  path : 'boat',
  objectType : 'boat',
  attrName : 'Boat Fleet',
  handles : () => true,
  renderer : () => React.createElement( BoatsFleets )
}
