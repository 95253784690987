import Molten from '@leverege/molten'
import { GlobalState, UI } from '@leverege/ui-redux'
import debounce from 'lodash.debounce'
import { useEffect } from 'react'

function SubscriptionHiddenDataViewer( { parentItem, ...rest } ) {
  const systemId = Molten.getConfig( 'api/systemId' )

  const getSubscriptionInfo = debounce( async () => {
    const email = parentItem?.data?.data?.email
    if ( !email ) return;

    const data = await GlobalState.dispatch( ( dispatch, getState, { api } ) => {
      try {
        const response = api.system( systemId ).srv( 'shopelf' ).get( email )
        return response
      } catch ( error ) {
        console.error( error )
      }
    } )

    GlobalState.dispatch( UI.set( 'subscriptionInfo', data ) )
  } )

  useEffect( () => getSubscriptionInfo(), [] )
  
  return null
}

export default {
  type : 'SubscriptionHiddenDataViewer',
  name : 'Subscripion Info',
  matches : [ { objectType : 'boat' } ],
  icon : 'fa-solid fa-grid-2',
  location : 'hidden',
  handles : () => true,
  component : SubscriptionHiddenDataViewer,
}
