import React from 'react';
import { Flex, Button, NumericInput } from '@leverege/ui-elements'

function TableFooter( { pageIndex, pageSize, canNextPage, canPreviousPage, setPageSize, gotoPage } ) {
  return (
    <Flex justify="space-between" align="center" style={{ padding : '10px' }}>
      <Flex align="center">
        <span>Rows:</span>
        <NumericInput
          value={pageSize}
          onChange={value => setPageSize( Number( value.value ) )}
          min={1}
          style={{ margin : '0 8px' }}/>
      </Flex>

      <Flex variant="rowS" align="center">
        <Button
          variant="secondary"
          icon="fak fa-lv-chevron-left-small"
          onClick={() => gotoPage( pageIndex - 1 )}
          disabled={!canPreviousPage}/>
        <Button
          variant="secondary"
          icon="fak fa-lv-chevron-right-small"
          onClick={() => gotoPage( pageIndex + 1 )}
          disabled={!canNextPage}/>
      </Flex>
    </Flex>
  )
}

export default TableFooter
