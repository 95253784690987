// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs_gHBqO {
  font: normal normal 12px/24px Inter;
  color: var( --color-base500 );
  padding-top: var( --size-padSmall );
  padding-left: 0px;
  padding-bottom: var( --size-padSmall );
}
.breadcrumbs_gHBqO > .link_IZlRc {
  font: normal 15px/24px var( --fontFamily-primary );
  color: var( --color-base500 );
  padding: 0px;
  outline: none;
  display: block;
  transition: background-color var( --time-medium );
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.breadcrumbs_gHBqO > .link_IZlRc:hover {
  font: normal 15px/24px var( --fontFamily-primary );
  color: var( --color-linkNormal );
}
.breadcrumbs_gHBqO > .link_IZlRc:focus {
  font: normal 15px/24px var( --fontFamily-primary );
  color: var( --color-base500 );
}
.breadcrumbs_gHBqO > .link_IZlRc:active {
  font: normal 15px/24px var( --fontFamily-primary );
  color: var( --color-linkNormal );
}
.breadcrumbs_gHBqO > .separator_WqqHG {
  color: var( --color-base300 );
  padding: var( --size-padSmall ) var( --size-padMedium );
  font-size: 16px;
}
.breadcrumbs_gHBqO > .current_D1mtA {
  font: 600 15px/24px Inter;
  color: var( --color-base600 );
}
.breadcrumbs_gHBqO.breadcrumbs-mobileItemScreen_nFIai > .separator_WqqHG {
  color: transparent;
  margin-left: 0px;
  padding-left: 0px;
  margin-right: 0px;
  padding-right: 0px;
}`, "",{"version":3,"sources":["webpack://./src/theme/Breadcrumbs.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,6BAA6B;EAC7B,mCAAmC;EACnC,iBAAiB;EACjB,sCAAsC;AACxC;AACA;EACE,kDAAkD;EAClD,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,cAAc;EACd,iDAAiD;EACjD,6BAA6B;EAC7B,qBAAqB;EACrB,sBAAsB;EACtB,uBAAuB;EACvB,wBAAwB;AAC1B;AACA;EACE,kDAAkD;EAClD,gCAAgC;AAClC;AACA;EACE,kDAAkD;EAClD,6BAA6B;AAC/B;AACA;EACE,kDAAkD;EAClD,gCAAgC;AAClC;AACA;EACE,6BAA6B;EAC7B,uDAAuD;EACvD,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,6BAA6B;AAC/B;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".breadcrumbs {\n  font: normal normal 12px/24px Inter;\n  color: var( --color-base500 );\n  padding-top: var( --size-padSmall );\n  padding-left: 0px;\n  padding-bottom: var( --size-padSmall );\n}\n.breadcrumbs > .link {\n  font: normal 15px/24px var( --fontFamily-primary );\n  color: var( --color-base500 );\n  padding: 0px;\n  outline: none;\n  display: block;\n  transition: background-color var( --time-medium );\n  background-color: transparent;\n  border-top-width: 0px;\n  border-left-width: 0px;\n  border-right-width: 0px;\n  border-bottom-width: 0px;\n}\n.breadcrumbs > .link:hover {\n  font: normal 15px/24px var( --fontFamily-primary );\n  color: var( --color-linkNormal );\n}\n.breadcrumbs > .link:focus {\n  font: normal 15px/24px var( --fontFamily-primary );\n  color: var( --color-base500 );\n}\n.breadcrumbs > .link:active {\n  font: normal 15px/24px var( --fontFamily-primary );\n  color: var( --color-linkNormal );\n}\n.breadcrumbs > .separator {\n  color: var( --color-base300 );\n  padding: var( --size-padSmall ) var( --size-padMedium );\n  font-size: 16px;\n}\n.breadcrumbs > .current {\n  font: 600 15px/24px Inter;\n  color: var( --color-base600 );\n}\n.breadcrumbs.breadcrumbs-mobileItemScreen > .separator {\n  color: transparent;\n  margin-left: 0px;\n  padding-left: 0px;\n  margin-right: 0px;\n  padding-right: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs": `breadcrumbs_gHBqO`,
	"link": `link_IZlRc`,
	"separator": `separator_WqqHG`,
	"current": `current_D1mtA`,
	"breadcrumbs-mobileItemScreen": `breadcrumbs-mobileItemScreen_nFIai`
};
export default ___CSS_LOADER_EXPORT___;
