import { LinkAction } from '@leverege/molten/routes'

export default LinkAction.create( {
  name : 'CAN', 
  id : 'boat.link.CanBus',
  icon : 'fa-solid fa-network-wired',
  layout : {
    sort : 'c.7'
  },
  matches : {
    client : 'ItemScreen',
    use : 'navBar',
    objectType : 'boat',
    path : 'boat'
  },
  path : '/p/:persona/boat/:boatId/can',
} )
