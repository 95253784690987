module.exports = {
  "hierarchy": {
    "small": [
      "compositeTextInput-small"
    ],
    "search": [
      "compositeTextInput-search"
    ],
    "mobileSearch": [
      "compositeTextInput-search",
      "compositeTextInput-mobileSearch"
    ],
    "password": [
      "compositeTextInput-password"
    ],
    "scan": [
      "compositeTextInput-scan"
    ],
    "pairSearch": [
      "compositeTextInput-fullWidth",
      "compositeTextInput-pairSearch"
    ],
    "fullWidth": [
      "compositeTextInput-fullWidth"
    ],
    "formInput": [
      "compositeTextInput-formInput"
    ],
    "attributeSelectorFilter": [
      "compositeTextInput-search",
      "compositeTextInput-mobileSearch",
      "compositeTextInput-attributeSelectorFilter"
    ],
    "formSearchBar": [
      "compositeTextInput-search",
      "compositeTextInput-mobileSearch",
      "compositeTextInput-formSearchBar"
    ]
  },
  "props": {
    "default": {
      "iconOpts": {
        "style": {
          "padding": ""
        }
      }
    },
    "small": {
      "iconOpts": {
        "style": {
          "padding": ""
        }
      }
    },
    "search": {
      "iconOpts": {
        "style": {
          "padding": ""
        }
      },
      "button": "Search",
      "icon": "fa fa-search"
    },
    "mobileSearch": {
      "iconOpts": {
        "style": {
          "padding": ""
        }
      },
      "button": "",
      "icon": "fa fa-search"
    },
    "password": {
      "iconOpts": {
        "style": {
          "padding": ""
        }
      }
    },
    "scan": {
      "iconOpts": {
        "style": {
          "padding": ""
        }
      },
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "pairSearch": {
      "iconOpts": {
        "style": {
          "padding": ""
        }
      }
    },
    "fullWidth": {
      "iconOpts": {
        "style": {
          "padding": ""
        }
      }
    },
    "formInput": {
      "iconOpts": {
        "style": {
          "padding": ""
        }
      }
    },
    "attributeSelectorFilter": {
      "iconOpts": {
        "style": {
          "fontSize": "",
          "padding": ""
        }
      },
      "button": null,
      "icon": "fa fa-search"
    },
    "formSearchBar": {
      "iconOpts": {
        "style": {
          "padding": ""
        }
      },
      "button": "",
      "icon": "fa fa-search"
    }
  }
}