import React from 'react'
import FirmwareHistoryScreen from './FirmwareHistoryScreen'

export default {
  id : 'siren.boat.FirmwareHistory.GroupScreen',
  matches : { client : 'ItemScreen', path : 'boat', objectType : 'boat' },
  exact : true,
  path : '/p/:persona/boat/:boatId/FirmwareHistory',
  default : false,
  render : ctx => React.createElement( FirmwareHistoryScreen, ctx )
}
