module.exports = {
  "hierarchy": {
    "mainScreenMode": [
      "popup-mainScreenMode"
    ],
    "dashboardCardEditorPopup": [
      "popup-dashboardCardEditorPopup"
    ],
    "mobileSuggestions": [
      "popup-mobileSuggestions"
    ],
    "suggestions": [
      "popup-suggestions"
    ],
    "topNavBar": [
      "popup-topNavBar"
    ],
    "layoutActionBar": [
      "popup-layoutActionBar"
    ],
    "attributeSelector": [
      "popup-attributeSelector"
    ],
    "scheduleSelector": [
      "popup-scheduleSelector"
    ],
    "multiSelector": [
      "popup-attributeSelector",
      "popup-multiSelector"
    ],
    "conditionSelector": [
      "popup-conditionSelector"
    ]
  },
  "props": {
    "default": {
      "checkVariant": "dashboard",
      "checkVariantStyle": {
        "marginRight": ""
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "style": {
          "marginRight": "8px"
        },
        "width": "20px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "default",
      "radioVariantStyle": {
        "marginRight": "8px"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu"
    },
    "mainScreenMode": {
      "checkVariant": "dashboard",
      "checkVariantStyle": {
        "marginRight": ""
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "style": {
          "marginRight": "8px"
        },
        "width": "20px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "default",
      "radioVariantStyle": {
        "marginRight": "8px"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu"
    },
    "dashboardCardEditorPopup": {
      "checkVariant": "dashboard",
      "checkVariantStyle": {
        "marginRight": ""
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "style": {
          "marginRight": "8px"
        },
        "width": "20px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "default",
      "radioVariantStyle": {
        "marginRight": "8px"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu"
    },
    "mobileSuggestions": {
      "checkVariant": "dashboard",
      "checkVariantStyle": {
        "marginRight": ""
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "style": {
          "marginRight": "8px"
        },
        "width": "20px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "default",
      "radioVariantStyle": {
        "marginRight": "8px"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu"
    },
    "suggestions": {
      "checkVariant": "dashboard",
      "checkVariantStyle": {
        "marginRight": ""
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "style": {
          "marginRight": "8px"
        },
        "width": "20px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "default",
      "radioVariantStyle": {
        "marginRight": "8px"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu"
    },
    "topNavBar": {
      "checkVariant": null,
      "checkVariantStyle": {
        "marginRight": ""
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "style": {
          "marginRight": "8px"
        },
        "width": "20px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "default",
      "radioVariantStyle": {
        "marginRight": "8px"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu",
      "checkIconOff": "fa",
      "checkIconOn": "fa"
    },
    "layoutActionBar": {
      "checkVariant": "dashboard",
      "checkVariantStyle": {
        "marginRight": ""
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "height": "24px",
        "style": {
          "marginRight": "8px"
        },
        "width": "24px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "default",
      "radioVariantStyle": {
        "marginRight": "8px"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu"
    },
    "attributeSelector": {
      "checkVariant": "none",
      "checkVariantStyle": {
        "marginRight": "0"
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "style": {
          "marginRight": "8px"
        },
        "width": "20px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "none",
      "radioVariantStyle": {
        "marginRight": "0"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu"
    },
    "scheduleSelector": {
      "checkVariant": "dashboard",
      "checkVariantStyle": {
        "marginRight": ""
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "style": {
          "marginRight": "8px"
        },
        "width": "20px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "default",
      "radioVariantStyle": {
        "marginRight": "8px"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu"
    },
    "multiSelector": {
      "checkVariant": "default",
      "checkVariantStyle": {
        "marginRight": "8px"
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "style": {
          "marginRight": "8px"
        },
        "width": "20px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "none",
      "radioVariantStyle": {
        "marginRight": "0"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu",
      "checkIcon": null
    },
    "conditionSelector": {
      "checkVariant": "dashboard",
      "checkVariantStyle": {
        "marginRight": ""
      },
      "iconOpts": {
        "style": {
          "fontSize": "14px",
          "marginRight": "8px"
        }
      },
      "imageOpts": {
        "style": {
          "marginRight": "8px"
        },
        "width": "20px"
      },
      "menuIcon": "@icon:chevron-right-large",
      "menuIconOpts": {
        "style": {
          "fontSize": "12px"
        }
      },
      "radioIconOffOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioIconOnOpts": {
        "style": {
          "fontSize": "0px",
          "marginRight": "0px"
        }
      },
      "radioImageOnOpts": {
        "height": "0px",
        "style": {
          "marginRight": ""
        },
        "width": "0px"
      },
      "radioVariant": "default",
      "radioVariantStyle": {
        "marginRight": "8px"
      },
      "submenuIcon": "@icon:more",
      "submenuVariant": "layoutActionBarSubmenu"
    }
  }
}