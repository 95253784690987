import React from 'react'
import EventsLogScreen from './EventsLogScreen'

export default {
  id : 'siren.boat.EventsLog.GroupScreen',
  matches : { client : 'ItemScreen', path : 'boat', objectType : 'boat' },
  exact : true,
  path : '/p/:persona/boat/:boatId/eventsLog',
  default : false,
  render : ctx => React.createElement( EventsLogScreen, ctx )
}  
