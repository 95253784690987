module.exports = {
  "hierarchy": {
    "primary": [
      "button-primary"
    ],
    "secondary": [
      "button-secondary"
    ],
    "tertiary": [
      "button-tertiary"
    ],
    "primaryIconic": [
      "button-primary",
      "button-primaryIconic"
    ],
    "secondaryIconic": [
      "button-secondary",
      "button-secondaryIconic"
    ],
    "tertiaryIconic": [
      "button-tertiary",
      "button-tertiaryIconic"
    ],
    "primarySmall": [
      "button-primary",
      "button-primarySmall"
    ],
    "secondarySmall": [
      "button-secondary",
      "button-secondarySmall"
    ],
    "tertiarySmall": [
      "button-tertiary",
      "button-tertiarySmall"
    ],
    "primarySmallIconic": [
      "button-primary",
      "button-primaryIconic",
      "button-primarySmallIconic"
    ],
    "secondarySmallIconic": [
      "button-secondary",
      "button-secondaryIconic",
      "button-secondarySmallIconic"
    ],
    "tertiarySmallIconic": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic"
    ],
    "primaryTinyIconic": [
      "button-primary",
      "button-primaryIconic",
      "button-primarySmallIconic",
      "button-primaryTinyIconic"
    ],
    "secondaryTinyIconic": [
      "button-secondary",
      "button-secondaryIconic",
      "button-secondarySmallIconic",
      "button-secondaryTinyIconic"
    ],
    "tertiaryTinyIconic": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-tertiaryTinyIconic"
    ],
    "link": [
      "button-link"
    ],
    "linkDestructive": [
      "button-link",
      "button-linkDestructive"
    ],
    "linkTable": [
      "button-link",
      "button-linkTable"
    ],
    "primaryDestructive": [
      "button-primary",
      "button-primaryDestructive"
    ],
    "primaryCancel": [
      "button-tertiary",
      "button-primaryCancel"
    ],
    "desktopSearch": [
      "button-desktopSearch"
    ],
    "tableColumnAdd": [
      "button-secondary",
      "button-tableColumnAdd"
    ],
    "tableColumnRemove": [
      "button-primary",
      "button-primaryIconic",
      "button-primarySmallIconic",
      "button-primaryTinyIconic",
      "button-tableColumnRemove"
    ],
    "close": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-tertiaryTinyIconic",
      "button-close"
    ],
    "navLogo": [
      "button-navLogo"
    ],
    "navRefresh": [
      "button-navRefresh"
    ],
    "pagination": [
      "button-secondary",
      "button-secondaryIconic",
      "button-secondarySmallIconic",
      "button-pagination"
    ],
    "mobileAction": [
      "button-tertiary",
      "button-mobileAction"
    ],
    "mobileNavIcon": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-mobileNavIcon"
    ],
    "mobileNavTitle": [
      "button-tertiary",
      "button-mobileNavTitle"
    ],
    "closeToast": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-closeToast"
    ],
    "mapControl": [
      "button-primary",
      "button-primaryIconic",
      "button-primarySmallIconic",
      "button-primaryTinyIconic",
      "button-mapControl"
    ],
    "zoomControl": [
      "button-primary",
      "button-primaryIconic",
      "button-primarySmallIconic",
      "button-primaryTinyIconic",
      "button-mapControl",
      "button-zoomControl"
    ],
    "clearSearch": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-tertiaryTinyIconic",
      "button-clearSearch"
    ],
    "closeWindow": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-tertiaryTinyIconic",
      "button-close",
      "button-closeWindow"
    ],
    "itemBar": [
      "button-tertiary",
      "button-itemBar"
    ],
    "hNavBar": [
      "button-tertiary",
      "button-hNavBar"
    ],
    "sideNavBar": [
      "button-tertiary",
      "button-hNavBar",
      "button-sideNavBar"
    ],
    "actionBar": [
      "button-tertiary",
      "button-actionBar"
    ],
    "cog": [
      "button-primary",
      "button-primaryIconic",
      "button-cog"
    ],
    "mobileNavLogo": [
      "button-navLogo",
      "button-mobileNavLogo"
    ],
    "paginationPrev": [
      "button-secondary",
      "button-secondaryIconic",
      "button-secondarySmallIconic",
      "button-pagination",
      "button-paginationPrev"
    ],
    "paginationNext": [
      "button-secondary",
      "button-secondaryIconic",
      "button-secondarySmallIconic",
      "button-pagination",
      "button-paginationNext"
    ],
    "mobileUserNavBar": [
      "button-mobileUserNavBar"
    ],
    "mobileScreenTitle": [
      "button-tertiary",
      "button-mobileScreenTitle"
    ],
    "mobileActionBar": [
      "button-tertiary",
      "button-mobileActionBar"
    ],
    "mapActionBar": [
      "button-tertiary",
      "button-actionBar",
      "button-mapActionBar"
    ],
    "touchToolbarButton": [
      "button-link",
      "button-touchToolbarButton"
    ],
    "linkNoPad": [
      "button-link",
      "button-linkNoPad"
    ],
    "pairOption": [
      "button-primary",
      "button-pairOption"
    ],
    "unpairOption": [
      "button-secondary",
      "button-unpairOption"
    ],
    "searchBack": [
      "button-link",
      "button-searchBack"
    ],
    "pairClose": [
      "button-link",
      "button-searchBack",
      "button-pairClose"
    ],
    "pairCamera": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-pairCamera"
    ],
    "confirmActionBar": [
      "button-tertiary",
      "button-actionBar",
      "button-confirmActionBar"
    ],
    "rejectActionBar": [
      "button-tertiary",
      "button-actionBar",
      "button-rejectActionBar"
    ],
    "backToLogin": [
      "button-link",
      "button-linkNoPad",
      "button-backToLogin"
    ],
    "expiredActivationTokenSubmit": [
      "button-primary",
      "button-expiredActivationTokenSubmit"
    ],
    "mobileClose": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-tertiaryTinyIconic",
      "button-close",
      "button-mobileClose"
    ],
    "mobileNavBack": [
      "button-tertiary",
      "button-mobileNavBack"
    ],
    "itemBarMobile": [
      "button-tertiary",
      "button-itemBar",
      "button-itemBarMobile"
    ],
    "filterBar": [
      "button-tertiary",
      "button-actionBar",
      "button-filterBar"
    ],
    "mobileNavClose": [
      "button-mobileNavClose"
    ],
    "confirmLayoutActionBar": [
      "button-tertiary",
      "button-actionBar",
      "button-confirmActionBar",
      "button-confirmLayoutActionBar"
    ],
    "rejectLayoutActionBar": [
      "button-tertiary",
      "button-actionBar",
      "button-rejectActionBar",
      "button-rejectLayoutActionBar"
    ],
    "layoutSelector": [
      "button-secondary",
      "button-secondaryIconic",
      "button-layoutSelector"
    ],
    "mobileOptBarMore": [
      "button-link",
      "button-mobileOptBarMore"
    ],
    "multiSelector": [
      "button-link",
      "button-multiSelector"
    ],
    "closeDialog": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-tertiaryTinyIconic",
      "button-closeDialog"
    ],
    "primaryFullWidth": [
      "button-primary",
      "button-primaryFullWidth"
    ],
    "formSearchBarPost": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-tertiaryTinyIconic",
      "button-closeDialog",
      "button-formSearchBarPost"
    ],
    "copyHoursSubmit": [
      "button-secondary",
      "button-copyHoursSubmit"
    ],
    "linkDestructiveNoPad": [
      "button-link",
      "button-linkDestructive",
      "button-linkDestructiveNoPad"
    ],
    "linkNeutral": [
      "button-link",
      "button-linkNeutral"
    ],
    "linkNeutralNoPad": [
      "button-link",
      "button-linkNeutral",
      "button-linkNeutralNoPad"
    ],
    "sortFilterBar": [
      "button-tertiary",
      "button-actionBar",
      "button-filterBar",
      "button-sortFilterBar"
    ],
    "quaternary": [
      "button-tertiary",
      "button-quaternary"
    ],
    "copyToClipboard": [
      "button-secondary",
      "button-secondarySmall",
      "button-copyToClipboard"
    ],
    "copyToClipboardButton": [
      "button-secondary",
      "button-secondarySmall",
      "button-copyToClipboard",
      "button-copyToClipboardButton"
    ]
  },
  "props": {
    "default": {},
    "primary": {},
    "secondary": {},
    "tertiary": {},
    "primaryIconic": {},
    "secondaryIconic": {},
    "tertiaryIconic": {},
    "primarySmall": {},
    "secondarySmall": {},
    "tertiarySmall": {},
    "primarySmallIconic": {},
    "secondarySmallIconic": {},
    "tertiarySmallIconic": {},
    "primaryTinyIconic": {},
    "secondaryTinyIconic": {},
    "tertiaryTinyIconic": {},
    "link": {},
    "linkDestructive": {},
    "linkTable": {},
    "primaryDestructive": {},
    "primaryCancel": {},
    "desktopSearch": {},
    "tableColumnAdd": {
      "icon": ".lv-plus"
    },
    "tableColumnRemove": {},
    "close": {},
    "navLogo": {
      "icon": "https://molten-ui-assets.storage.googleapis.com/leverege-logo.svg",
      "imageOpts": {
        "height": "24px",
        "width": ""
      }
    },
    "navRefresh": {},
    "pagination": {},
    "mobileAction": {},
    "mobileNavIcon": {},
    "mobileNavTitle": {
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "closeToast": {},
    "mapControl": {},
    "zoomControl": {},
    "clearSearch": {},
    "closeWindow": {},
    "itemBar": {
      "iconOpts": {
        "style": {
          "fontSize": "18px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "hNavBar": {},
    "sideNavBar": {},
    "actionBar": {
      "iconOpts": {
        "style": {
          "fontSize": "16px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "cog": {
      "icon": "fa fa-cog"
    },
    "mobileNavLogo": {
      "icon": "https://molten-ui-assets.storage.googleapis.com/leverege-logo.svg",
      "imageOpts": {
        "width": "168px"
      }
    },
    "paginationPrev": {
      "icon": "fa fa-chevron-left fa-fw",
      "iconOpts": {
        "style": {
          "border": "",
          "padding": "0 4px 0 0"
        }
      }
    },
    "paginationNext": {
      "icon": "fa fa-chevron-right",
      "iconOpts": {
        "style": {
          "padding": "0 0 0 4px"
        }
      }
    },
    "mobileUserNavBar": {},
    "mobileScreenTitle": {
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "mobileActionBar": {
      "iconOpts": {
        "style": {
          "fontSize": "18px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "mapActionBar": {
      "iconOpts": {
        "style": {
          "fontSize": "16px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "touchToolbarButton": {},
    "linkNoPad": {},
    "pairOption": {
      "imageOpts": {
        "height": "16px",
        "width": "16px"
      }
    },
    "unpairOption": {
      "iconOpts": {
        "style": {
          "fontSize": "18px"
        }
      },
      "imageOpts": {
        "height": "16px",
        "width": "16px"
      }
    },
    "searchBack": {
      "icon": "fa fa-chevron-left",
      "imageOpts": {
        "height": "18px",
        "width": "18px"
      }
    },
    "pairClose": {
      "icon": "fa fa-close",
      "imageOpts": {
        "height": "18px",
        "width": "18px"
      }
    },
    "pairCamera": {
      "imageOpts": {
        "height": "16px",
        "width": "16px"
      }
    },
    "confirmActionBar": {
      "iconOpts": {
        "style": {
          "color": "18px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "rejectActionBar": {
      "iconOpts": {
        "style": {
          "fontSize": "18px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "backToLogin": {
      "icon": "@icon:arrow-left",
      "iconOpts": {
        "style": {
          "fontSize": "16px"
        }
      }
    },
    "expiredActivationTokenSubmit": {},
    "mobileClose": {
      "icon": "@icon:close",
      "iconOpts": {
        "style": {
          "color": "",
          "fontSize": "16px"
        }
      }
    },
    "mobileNavBack": {
      "icon": "@icon:chevron-left",
      "iconOpts": {
        "style": {
          "fontSize": "16px"
        }
      }
    },
    "itemBarMobile": {
      "iconOpts": {
        "style": {
          "fontSize": "18px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "filterBar": {
      "iconOpts": {
        "style": {
          "fontSize": "16px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "mobileNavClose": {
      "iconOpts": {
        "style": {
          "fontSize": "24px"
        }
      },
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "confirmLayoutActionBar": {
      "iconOpts": {
        "style": {
          "color": "18px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "rejectLayoutActionBar": {
      "iconOpts": {
        "style": {
          "fontSize": "18px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      },
      "icon": "@icon:cancel"
    },
    "layoutSelector": {},
    "mobileOptBarMore": {},
    "multiSelector": {
      "icon": "fak fa-lv-copy",
      "iconOpts": null,
      "iconPosition": "end"
    },
    "closeDialog": {
      "icon": "https://recovr-ui-assets.storage.googleapis.com/close.png",
      "imageOpts": {
        "height": "16px",
        "width": "16px"
      }
    },
    "primaryFullWidth": {},
    "formSearchBarPost": {
      "icon": "https://recovr-ui-assets.storage.googleapis.com/close.png",
      "imageOpts": {
        "height": "16px",
        "width": "16px"
      }
    },
    "copyHoursSubmit": {},
    "linkDestructiveNoPad": {},
    "linkNeutral": {},
    "linkNeutralNoPad": {},
    "sortFilterBar": {
      "iconOpts": {
        "style": {
          "fontSize": "16px"
        }
      },
      "imageOpts": {
        "height": "20px",
        "width": "20px"
      }
    },
    "quaternary": {},
    "copyToClipboard": {
      "icon": "fak fa-lv-copy",
      "iconPosition": "end"
    },
    "copyToClipboardButton": {
      "icon": "fak fa-lv-copy",
      "iconPosition": "end"
    }
  }
}