export default {
  id : 'boat.globalstar.sensor.itemCreateHook',
  matches : {
    objectType : 'globalstar',
    path : 'boat.globalstar',
  },
  hook : async ( device ) => {
    const mobileIdObj = device.data.find( item => item.path === 'mobileId' )
    const mobileId = mobileIdObj.value

    const newName = `Siren Marine Globalstar ${mobileId}`
    device.name = newName

    return device
  }
}
