/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Content, Toast, TableV2, Breadcrumbs, Button } from '@leverege/ui-elements'
import { TitleBar } from '@leverege/ui-plugin'
import { push } from 'connected-react-router'
import { GlobalState } from '@leverege/ui-redux'
import { RoutesUtil as Util } from '@leverege/molten/routes'
import { getConsolidatedDeviceData, getUserName } from '../utils/interface'

function DevicesUnderUserScreen( props ) {
  const { match } = props
  const { userId } = useParams()
  const [ userEmail, setUserEmail ] = useState( '' )
  const [ deviceData, setDeviceData ] = useState( [] )
  const [ loading, setLoading ] = useState( false )

  const rootPath = match ? Util.resolve( '/customers/', match ) : ''
  const overViewPath = match ? Util.resolve( '/customers/devicesUnderUser/:userId/', match ) : ''

  const onClickBreadCrumb = ( ctx ) => {
    const isRoot = ctx.data?.root
    GlobalState.dispatch( isRoot ? push( rootPath ) : push( overViewPath ) )
  }

  const handleNavigate = ( blueprintType, deviceId ) => { 
    GlobalState.dispatch( push( `/p/:persona/${blueprintType}/${deviceId}/` ) )
  }

  useEffect( () => {
    const fetchDeviceData = async () => {
      if ( userId ) {
        setLoading( true )
        try {
          const userData = await getUserName( process.env.PROJECT_ID, userId )
          setUserEmail( userData.email )
          const data = await getConsolidatedDeviceData( userId, process.env.PROJECT_ID )
          setDeviceData( data )
        } catch ( error ) {
          console.error( 'Error fetching device data:', error )
          Toast.error( 'Error fetching device data' )
        }
        setLoading( false )
      }
    };

    fetchDeviceData()
  }, [ userId ] )

  const columns = [
    {
      Header : 'Name',
      accessor : 'name'
    },
    {
      Header : 'Blueprint Type',
      accessor : 'blueprintType'
    },
    {
      Header : 'ID',
      Cell : x => (
        <Button
          variant="linkTable" 
          onClick={() => handleNavigate( x.cell.row.original.blueprintType, x.cell.row.original.id )}>
          {x.cell.row.original.id}
        </Button>
      )
    },
    {
      Header : 'Roles',
      accessor : 'roles',
      Cell : RolesCell
    },
    {
      Header : 'Created',
      accessor : 'created'
    },
    {
      Header : 'Last Modified',
      accessor : 'lastModified'
    },
  ]

  return (
    <Content>
      <Breadcrumbs
        variant="topNavBreadcrumbs"
        onClick={onClickBreadCrumb}
        crumbs={[ 
          { name : 'Customers', root : true }, 
          { name : 'Devices Under user' }
        ]}/>
      <Content.Header>
        <TitleBar
          variant="screenTitle"
          title={`Devices Under ${userEmail}`}
          icon="fa-solid fa-users" />
      </Content.Header>
      <Content.Area>
        <TableV2
          variant="dataTable"
          data={deviceData}
          columns={columns}
          loading={loading}/>
      </Content.Area>
    </Content>

  )
}

function RolesCell( { value } ) {
  return (
    <div style={{ display : 'flex', flexDirection : 'column' }}>
      {value && value.map( ( role, index ) => (
        <span key={index} style={{ lineHeight : 'normal' }}>
          {role}
        </span>
      ) )}
    </div>
  )
}

export default DevicesUnderUserScreen
