import { Dialog } from '@leverege/ui-elements';
import React from 'react';
import BoatCanBusScreen from './BoatAlertRecipientsScreen';

export default {
  id : 'siren.boat.alertRecipients.GroupScreen',
  matches : { client : 'ItemScreen', path : 'boat', objectType : 'boat' },
  exact : true,
  path : '/p/:persona/boat/:boatId/alert-recipients',
  default : false,
  render : ctx => React.createElement( BoatCanBusScreen, ctx ),
  handles : () => true,
  perform : () => Dialog.show()
}
