import { LinkAction } from '@leverege/molten/routes'

export default LinkAction.create( {
  name : 'Alert Recipients', 
  id : 'boat.link.AlertRecipients',
  icon : 'fa-solid fa-warning',
  layout : {
    sort : 'c.3'
  },
  matches : {
    client : 'ItemScreen',
    use : 'navBar',
    objectType : 'boat',
    path : 'boat'
  },
  path : '/p/:persona/boat/:boatId/alert-recipients',
} )
