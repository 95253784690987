module.exports = {
  "hierarchy": {
    "vertical": [
      "propertyGrid-vertical"
    ],
    "twoColumns": [
      "propertyGrid-twoColumns"
    ],
    "operatingHours": [
      "propertyGrid-operatingHours"
    ],
    "operatingHoursTimezone": [
      "propertyGrid-operatingHoursTimezone"
    ],
    "editor": [
      "propertyGrid-editor"
    ],
    "attributeCoordinateEditor": [
      "propertyGrid-editor",
      "propertyGrid-attributeCoordinateEditor"
    ],
    "yAxisEditor": [
      "propertyGrid-yAxisEditor"
    ],
    "xAxisEditor": [
      "propertyGrid-xAxisEditor"
    ],
    "scaleEditor": [
      "propertyGrid-editor",
      "propertyGrid-scaleEditor"
    ],
    "seriesEditor": [
      "propertyGrid-editor",
      "propertyGrid-seriesEditor"
    ]
  },
  "props": {
    "default": {
      "columns": 1,
      "headerVariant": "editorTitle",
      "inline": true,
      "labelVariant": "",
      "subheaderVariant": "subtitle",
      "vertical": true
    },
    "vertical": {
      "columns": 1,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": "noPad",
      "subheaderVariant": "subtitle",
      "vertical": true
    },
    "twoColumns": {
      "columns": 2,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": "noPad",
      "subheaderVariant": "subtitle",
      "vertical": false
    },
    "operatingHours": {
      "columns": 1,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": null,
      "subheaderVariant": "subtitle",
      "vertical": false
    },
    "operatingHoursTimezone": {
      "columns": 1,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": "default",
      "subheaderVariant": "subtitle",
      "vertical": false
    },
    "editor": {
      "columns": 1,
      "headerVariant": "editorTitle",
      "inline": true,
      "labelVariant": null,
      "subheaderVariant": "subtitle",
      "vertical": false
    },
    "attributeCoordinateEditor": {
      "columns": 1,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": null,
      "subheaderVariant": "subtitle",
      "vertical": false
    },
    "yAxisEditor": {
      "columns": 1,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": null,
      "subheaderVariant": "subtitle",
      "vertical": false
    },
    "xAxisEditor": {
      "columns": 1,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": null,
      "subheaderVariant": "subtitle",
      "vertical": false
    },
    "scaleEditor": {
      "columns": 1,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": null,
      "subheaderVariant": "subtitle",
      "vertical": false
    },
    "seriesEditor": {
      "columns": 1,
      "headerVariant": "title",
      "inline": false,
      "labelVariant": null,
      "subheaderVariant": "subtitle",
      "vertical": false
    }
  }
}