import { LinkAction } from '@leverege/molten/routes'

export default LinkAction.create( {
  name : 'Customers', 
  id : 'user.link.Customers',
  icon : 'fa-solid fa-users',
  layout : {
    sort : 'a.6'
  },
  matches : {
    client : 'Main',
    use : 'navBar',
  },
  path : '/customers/',
} )
