import React from 'react';
import BoatCanBusScreen from './BoatCanBusScreen';

export default {
  id : 'siren.boat.can.GroupScreen',
  matches : { client : 'ItemScreen', path : 'boat', objectType : 'boat' },
  exact : true,
  path : '/p/:persona/boat/:boatId/can',
  default : false,
  render : ctx => React.createElement( BoatCanBusScreen, ctx )
  
}
