import { LinkAction } from '@leverege/molten/routes'

export default LinkAction.create( {
  name : 'OTA Flag', 
  id : 'firmware.link.otaFlag',
  icon : 'fa-solid fa-microchip',
  layout : {
    sort : 'a.8'
  },
  matches : {
    client : 'Main',
    use : 'navBar',
  },
  path : '/otaFlag/',
} )
