/* eslint-disable import/no-extraneous-dependencies */
import { GlobalState } from '@leverege/ui-redux'

async function uploadFirmwareVersion( data ) {
  const formData = Object
    .entries( data )
    .reduce( ( prev, curr ) => {
      if ( curr[1] ) {
        prev.append( curr[0], curr[1] )
      }

      return prev
    }, new FormData() )

  const res = await GlobalState.dispatch(
    async ( dispatch, getState, { api } ) => {
      const h = { headers : { contentType : null } }
      const opts = { log : true }
      const json = await api.system( process.env.SYSTEM_ID ).srv( 'firmware' ).post( 'upload', formData, opts, h )
      return json
    }
  )

  return res
}

export default uploadFirmwareVersion
