module.exports = {
  "hierarchy": {
    "small": [
      "portal-small"
    ],
    "dialog": [
      "portal-dialog"
    ],
    "inPlaceAttributeEditor": [
      "portal-inPlaceAttributeEditor"
    ],
    "contextMenu": [
      "portal-contextMenu"
    ]
  },
  "props": {
    "default": {
      "animation": "slideFromTop",
      "position": "top"
    },
    "small": {
      "animation": "slideFromTop",
      "position": "top"
    },
    "dialog": {
      "animation": "slideFromTop",
      "position": "center"
    },
    "inPlaceAttributeEditor": {
      "animation": "slideFromTop",
      "position": "top"
    },
    "contextMenu": {
      "animation": "slideFromTop",
      "position": "top"
    }
  }
}