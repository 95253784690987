module.exports = {
  "hierarchy": {
    "success": [
      "progressBar-success"
    ],
    "danger": [
      "progressBar-danger"
    ]
  },
  "props": {
    "default": {
      "labelVariant": "captionCurrent"
    },
    "success": {
      "labelVariant": "captionCurrent",
      "completeLabelVisible": true,
      "iconComplete": "fa fa-fw fa-check",
      "labelVisible": true
    },
    "danger": {
      "labelVariant": "captionCurrent",
      "completeLabelVisible": true,
      "icon": "",
      "iconComplete": "fa fa-fw fa-times",
      "labelVisible": true
    }
  }
}