import { Dialogs } from '@leverege/ui-elements'
import UploadNewFirmwareMenu from './UploadNewFirmwareMenu'

export default {
  id : 'firmware.upload.firmware',
  name : 'Upload New Firmware',
  matches : {
    client : 'GroupScreen',
    objectType : 'firmware',
    path : 'firmware',
    use : 'contextMenu',
    usedIn : 'toolbar'
  },
  appearance : () => ( {
    icon : 'fa-light fa-plus',
    name : 'Create Firmware Version',
  } ),
  handles : () => true,
  perform : props => Dialogs.show( {
    props,
    component : UploadNewFirmwareMenu
  } )
}
