/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-extraneous-dependencies */
import { 
  Button,
  Content,
  Flex,
  Table,
  Toast,
} from '@leverege/ui-elements'
import React, { useEffect, useState } from 'react'
import { GlobalState } from '@leverege/ui-redux'
import moment from 'moment'
import Molten from '@leverege/molten'
import { TitleBar, Toolbar } from '@leverege/ui-plugin'
import Papa from 'papaparse'
import CSVViewer from './CSVViewer'
import { formatFileName } from '../utils/FormatFileName'

const formatTimeStamp = ( timestamp ) => {
  if ( timestamp == null ) return null
  const timeStamp = new Date( timestamp )
  if ( Number.isNaN( timeStamp.getTime() ) ) return null
  return new Intl.DateTimeFormat( 'en-US', {
    year : 'numeric',
    month : '2-digit',
    day : '2-digit',
    hour : '2-digit',
    minute : '2-digit',
    hour12 : false,
    timeZoneName : 'short'
  } ).format( timeStamp )
}

function DownloadButton( { fetchNewReportUrl, fileId, fileName } ) {

  const handleDownload = async () => {
    const url = await fetchNewReportUrl( fileId )
    fetch( url )
      .then( response => response.text() )
      .then( ( csv ) => {
        const parsedData = Papa.parse( csv, { header : true } ).data
        const formattedData = parsedData.map( row => ( {
          ...row,
          'Last Update' : formatTimeStamp( row['Last Update'] ),
        } ) )
        const formattedCsv = Papa.unparse( formattedData )
        const blob = new Blob( [ formattedCsv ], { type : 'text/csv' } )
        const url = window.URL.createObjectURL( blob )
        const link = document.createElement( 'a' )
        link.href = url;
        link.setAttribute( 'download', fileName )
        document.body.appendChild( link )
        link.click()
        URL.revokeObjectURL( url )
      } )
      .catch( error => console.error( 'Error downloading CSV:', error ) )
  };

  return (
    <Button style={{ margin : 5 }} variant="primary" icon="fa fa-download" onClick={handleDownload}/>
  )
}

function DailyReportScreen( props ) {

  const [ loading, setLoading ] = useState( false )
  const [ data, setData ] = useState( [] )
  const [ showModal, setShowModal ] = useState( false )
  const [ selectedData, setSelectedData ] = useState( null )
  const [ pageIndex, setPageIndex ] = useState( 0 )
  const [ pageSize, setPageSize ] = useState( 50 )
  const [ pages, setPages ] = useState( null )

  const handleOpenModal = ( rowData ) => {
    setSelectedData( rowData )
    setShowModal( true )
  };

  const handleCloseModal = () => {
    setShowModal( false )
  };

  const columns = [
    {
      Header : 'Date',
      Cell : ( { original } ) => `${moment( original.createdAt ).format( 'MM/DD/YYYY HH:mm' )}`, 
    },
    {
      Header : 'Report',
      Cell : ( { original } ) => formatFileName( original ), 
    },
    {
      Header : 'Action',
      Cell : ( { original } ) => (
        <Flex direction="row">
          <DownloadButton 
            fileName={`${formatFileName( original )}.csv`}
            fetchNewReportUrl={fetchNewReportUrl}
            fileId={original.id}/>
          <Button
            style={{ margin : 5 }}
            variant="primary"
            icon="fa fa-eye"
            onClick={() => handleOpenModal( original )} />
        </Flex>
      )
    }
  ]

  const fetchData = async ( pageIndex, pageSize ) => {
    try {
      const systemId = Molten.getConfig( 'api/systemId' )
      setLoading( true );

      const { items, total } = await GlobalState.dispatch( async ( dispatch, getState, { api } ) => {
        return api.system( systemId )
          .resource( 'internal-daily-reports' )
          .list( { limit : pageSize, offset : pageIndex * pageSize, order : [ [ 'createdAt', 'desc' ] ] } )
      } )

      setData( items )
      setPages( Math.ceil( total / pageSize ) )
    } catch ( error ) {
      console.error( 'Error fetching daily reports', error )
      Toast.error( 'Error fetching daily reports' )
    } finally {
      setLoading( false )
    }
  };

  const fetchNewReportUrl = async ( fileId ) => {
    const systemId = Molten.getConfig( 'api/systemId' )
    try {
      const { data : { url } } = await GlobalState.dispatch( async ( dispatch, getState, { api } ) => {
        return api.system( systemId )
          .resource( 'internal-daily-reports' )
          .get( fileId )
      } )

      return url
      
    } catch ( error ) {
      console.error( 'Error fetching daily report', error )
      Toast.error( 'Error fetching daily reports' )
    }
  }

  const handlePageSizeChange = ( newPageSize, newPage ) => {
    setPageSize( newPageSize )
    setPageIndex( newPage )
  };

  const handlePageChange = ( page ) => {
    setPageIndex( page )
  }

  useEffect( () => {
    fetchData( pageIndex, pageSize )
  }, [ pageIndex, pageSize ] )

  return (
    <Content>
      <Content.Header>
        <TitleBar
          variant="screenTitle"
          title="Daily Report"
          icon="fa-solid fa-file">
          <Toolbar
            variant="actionBar"
            prefer="icon"
            matchContext={{}}
            context={props} />
        </TitleBar>
      </Content.Header>
      <Content.Area>
        <Flex variant="colM">
          <Table
            data={data}
            columns={columns}
            loading={loading}
            loadingText={(
              <Flex className="defaultFlex" justify="center">
                <i className="fa fa-spinner fa-spin fa-2x fa-fw" />
              </Flex>
            )}
            defaultPageSize={20}
            pages={pages}
            onPageSizeChange={handlePageSizeChange}
            page={pageIndex}
            pageSizeOptions={[ 20, 50, 100, 200 ]}
            manual
            showPagination
            showPaginationBottom
            onPageChange={handlePageChange}/>
        </Flex>
      </Content.Area>
      {showModal && selectedData && (
        <CSVViewer
          fetchNewReportUrl={fetchNewReportUrl}
          showModal={showModal}
          items={selectedData}
          onClose={handleCloseModal} />
      )}
    </Content>
  )

}

export default DailyReportScreen;
