/* eslint-disable import/no-extraneous-dependencies */
import { Breadcrumbs, Content, Pane } from '@leverege/ui-elements'
import { TitleBar, Toolbar } from '@leverege/ui-plugin'
import React from 'react'
import { RoutesUtil as Util } from '@leverege/molten/routes'
import { GlobalState, } from '@leverege/ui-redux'
import { push } from 'connected-react-router'
import EventsLogTable from '../../components/EventsLogTable'

function EventsLogScreen( props ) {
  const { parentItem, match } = props

  const networkName = parentItem?.data?.networkId || null
  const name = parentItem?.data?.networkAliases?.[networkName]?.esn || parentItem.data.name
  const deviceId = parentItem.id

  const rootPath = match ? Util.resolve( '/p/:persona/boat', match ) : ''
  const overViewPath = match ? Util.resolve( '/p/:persona/boat/:boatId/info', match ) : ''

  const onClickBreadCrumb = ( ctx ) => {
    const isRoot = ctx.data?.root
    GlobalState.dispatch( isRoot ? push( rootPath ) : push( overViewPath ) )
  }

  return (
    <Content>
      <Pane variant="topNavBreadcrumbs">
        <Breadcrumbs
          variant="topNavBreadcrumbs"
          onClick={onClickBreadCrumb}
          crumbs={[
            { name : process.env.BOAT_BREADCRUMB_NAME, root : true }, 
            { name, device : true }, 
            { name : 'Events Log' }
          ]}/>
      </Pane>
      <Content.Header>
        <TitleBar
          variant="screenTitle"
          title="Events Log"
          icon="fa-solid fa-bell">
          <Toolbar
            variant="actionBar"
            prefer="icon"
            matchContext={{}}
            context={props} />
        </TitleBar>
      </Content.Header>
      <Content.Area>
        <EventsLogTable
          deviceId={deviceId}/>
      </Content.Area>
    </Content>
  )
}

export default EventsLogScreen
