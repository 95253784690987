// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.propertyGrid_IVc9m {
  width: 100%;
  border: 1px solid var( --color-base100 );
  padding: var( --size-padLarge );
  background-color: var( --color-base025 );
}
.propertyGrid_IVc9m.propertyGrid-vertical_Eq8e4 {
  background-color: var( --color-background2 );
}
.propertyGrid_IVc9m.propertyGrid-twoColumns_G8AZT {
  background-color: var( --color-background2 );
}
.propertyGrid_IVc9m.propertyGrid-operatingHours_t9nAM {
  padding-left: 0px;
  padding-right: 0px;
}
.propertyGrid_IVc9m.propertyGrid-editor_sGkju {
  width: 100%;
  border: 1.5px solid var( --color-base200 );
  padding: var( --size-padXLarge );
  border-radius: 4px;
  margin-bottom: 30px;
  background-color: var( --color-base025 );
}
.propertyGrid_IVc9m.propertyGrid-editor_sGkju.propertyGrid-attributeCoordinateEditor_E2EVJ {
  padding-top: 0px;
  margin-left: 0px;
  padding-left: 0px;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}`, "",{"version":3,"sources":["webpack://./src/theme/PropertyGrid.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,wCAAwC;EACxC,+BAA+B;EAC/B,wCAAwC;AAC1C;AACA;EACE,4CAA4C;AAC9C;AACA;EACE,4CAA4C;AAC9C;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,0CAA0C;EAC1C,gCAAgC;EAChC,kBAAkB;EAClB,mBAAmB;EACnB,wCAAwC;AAC1C;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,6BAA6B;EAC7B,qBAAqB;EACrB,sBAAsB;EACtB,uBAAuB;EACvB,wBAAwB;AAC1B","sourcesContent":[".propertyGrid {\n  width: 100%;\n  border: 1px solid var( --color-base100 );\n  padding: var( --size-padLarge );\n  background-color: var( --color-base025 );\n}\n.propertyGrid.propertyGrid-vertical {\n  background-color: var( --color-background2 );\n}\n.propertyGrid.propertyGrid-twoColumns {\n  background-color: var( --color-background2 );\n}\n.propertyGrid.propertyGrid-operatingHours {\n  padding-left: 0px;\n  padding-right: 0px;\n}\n.propertyGrid.propertyGrid-editor {\n  width: 100%;\n  border: 1.5px solid var( --color-base200 );\n  padding: var( --size-padXLarge );\n  border-radius: 4px;\n  margin-bottom: 30px;\n  background-color: var( --color-base025 );\n}\n.propertyGrid.propertyGrid-editor.propertyGrid-attributeCoordinateEditor {\n  padding-top: 0px;\n  margin-left: 0px;\n  padding-left: 0px;\n  background-color: transparent;\n  border-top-width: 0px;\n  border-left-width: 0px;\n  border-right-width: 0px;\n  border-bottom-width: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"propertyGrid": `propertyGrid_IVc9m`,
	"propertyGrid-vertical": `propertyGrid-vertical_Eq8e4`,
	"propertyGrid-twoColumns": `propertyGrid-twoColumns_G8AZT`,
	"propertyGrid-operatingHours": `propertyGrid-operatingHours_t9nAM`,
	"propertyGrid-editor": `propertyGrid-editor_sGkju`,
	"propertyGrid-attributeCoordinateEditor": `propertyGrid-attributeCoordinateEditor_E2EVJ`
};
export default ___CSS_LOADER_EXPORT___;
