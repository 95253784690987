import { LinkAction } from '@leverege/molten/routes'

export default LinkAction.create( {
  name : 'Daily Report', 
  id : 'user.link.DailyReports',
  icon : 'fa-solid fa-file',
  layout : {
    sort : 'a.6'
  },
  matches : {
    client : 'Main',
    use : 'navBar',
  },
  path : '/daily-reports/',
} )
