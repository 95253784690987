import React from 'react';
import BoatCommentsCard from './BoatCommentsScreen';

export default {
  id : 'siren.boat.comments.GroupScreen',
  matches : { client : 'ItemScreen', path : 'boat', objectType : 'boat' },
  exact : true,
  path : '/p/:persona/boat/:boatId/comments',
  default : false,
  render : ctx => React.createElement( BoatCommentsCard, ctx )
}  
