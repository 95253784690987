// import plugins from libraries here
import DevicesUnderUserRoute from './DevicesUnderUserRoute'
import CustomersLink from './CustomersLink'
import CustomersRouter from './CustomersRoute'

const install = ( molten ) => {
  molten.addPlugin( 'Route', DevicesUnderUserRoute )
  molten.addPlugin( 'Action', CustomersLink )
  molten.addPlugin( 'Route', CustomersRouter )
}

export default {
  install
}
