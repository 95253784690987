module.exports = {
  "hierarchy": {
    "comparator": [
      "dateTimePicker-comparator"
    ]
  },
  "props": {
    "default": {
      "calendarIcon": "fak fa-lv-calendar",
      "calendarButtonVariant": "link",
      "clearButtonVariant": "link"
    },
    "comparator": {
      "calendarIcon": "fak fa-lv-calendar",
      "calendarButtonVariant": "link",
      "clearButtonVariant": "link"
    }
  }
}