import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  Text,
  TextInput,
  TextArea,
  FileInput,
  Toast,
  Pane
} from '@leverege/ui-elements'
import React, { useState, useEffect } from 'react'
import uploadFirmwareVersion from '../utils/FirmwareVersionActions'

function UploadNewFirmwareMenu( props ) {
  const { show, onClose, context } = props
  const firmwareAuthor = context?.clientProps?.profile?.email ?? ''
  const [ checkedItems, setCheckedItems ] = useState( {} )
  const [ uploadedFiles, setUploadedFiles ] = useState( {} )
  const [ firmwareNotes, setFirmwareNotes ] = useState( '' )
  const [ firmwareName, setFirmwareName ] = useState( '' )
  const [ isUploadButtonEnabled, setUploadButtonEnabled ] = useState( false )
  const [ fileNames, setFileNames ] = useState( {} )

  const firmwareTypes = [
    { name : 'Main', value : 'main' },
    { name : 'Wireless Sensor', value : 'wireless-sensor' },
    { name : 'Wireless Host', value : 'wireless-host' },
    { name : 'Wireless Repeater', value : 'wireless-repeater' }
  ]

  const removeUploadedFile = ( itemValue ) => {
    setUploadedFiles( ( prevUploadedFiles ) => {
      const updatedFiles = { ...prevUploadedFiles }
      updatedFiles[itemValue] = undefined
      return updatedFiles
    } )

    setFileNames( prevState => ( {
      ...prevState,
      [itemValue] : undefined,
    } ) )
  }

  const handleCheckboxChange = ( itemValue, isChecked ) => {
    setCheckedItems( ( prevCheckedItems ) => {
      const updatedCheckedItems = { [itemValue] : isChecked }
      Object.keys( prevCheckedItems ).forEach( ( key ) => {
        if ( key !== itemValue ) {
          updatedCheckedItems[key] = false
          removeUploadedFile( key )
        }
      } );
      return updatedCheckedItems
    } );
  }

  const handleFileChange = ( fileData, itemValue ) => {
    const file = fileData.value[0]
    setUploadedFiles( { ...uploadedFiles, [itemValue] : file } )
    setFileNames( { ...fileNames, [itemValue] : file.name } )
  }

  const onSubmit = async () => {
    try {
      const firmwareData = {
        name : firmwareName,
        notes : firmwareNotes,
        main : uploadedFiles.main,
        leaf : uploadedFiles['wireless-sensor'],
        host : uploadedFiles['wireless-host'],
        repeater : uploadedFiles['wireless-repeater']
      }
      const author = firmwareAuthor

      const response = await uploadFirmwareVersion( { ...firmwareData, author } )
      console.log( 'Device created:', response )
      Toast.success( `Successfully uploading new firmware: ${firmwareName}` )
    } catch ( err ) {
      console.error( 'Error creating device:', err )
      Toast.error( `Error uploading new firmware ${firmwareName}: ${err}` )
    }
  }

  useEffect( () => {
    const isNameFilled = firmwareName.trim() !== ''
    const isAtleastOneFileUploaded = Object.keys( uploadedFiles ).length > 0
    setUploadButtonEnabled( isNameFilled && isAtleastOneFileUploaded )
  }, [ firmwareName, uploadedFiles ] )

  const notesInputStyle = {
    width : '450px',
    maxWidth : '1500px',
    height : '150px',
    minHeight : '100px',
  }

  const dialogBodyStyle = {
    gap : 4,
    padding : 10,
    background : '#f3f5f6',
    boxShadow : '0px 1px 3px rgba(41, 50, 61, 0.16)',
    borderBottom : '1px solid #f3f5f6',
    borderTop : '1px solid #f3f5f6'
  }

  const dialogFooterBody = {
    float : 'right',
    gap : 14
  }

  return (
    <Dialog 
      show={show} 
      onClose={onClose}>
      <Pane>
        <Flex
          justify="space-between"
          style={{
            padding : '8px 16px 8px 16px',
            boxShadow : '0px 2px 3px rgba(41, 50, 61, 0.16)',
            borderBottom : '2px solid #e7eaef'
          }}>
          <Text variant="dialogTitle" icon="fa-solid fa-microchip">Upload New Firmware</Text>
        </Flex>
        <Flex variant="colM">
          <Flex
            variant="col"
            style={dialogBodyStyle}>
            <Flex variant="colS" align="stretch">
              <Text variant="label">Name</Text>
              <TextInput
                value={firmwareName}
                placeHolder="X.X.XX"
                onChange={txt => setFirmwareName( txt.value )}/>
            </Flex>
            <Flex variant="colS" align="stretch">
              <Text variant="label">Firmware Notes</Text>
              <TextArea 
                value={firmwareNotes}
                onChange={txt => setFirmwareNotes( txt.value )}
                style={notesInputStyle}/>
            </Flex>
            {firmwareTypes.map( item => (
              <Flex key={item.value} direction="column" gap={8}>
                <Checkbox
                  label={item.name}
                  value={checkedItems[item.value] || false}
                  onChange={e => handleCheckboxChange( item.value, e.value )}/>
                {checkedItems[item.value] && (
                // file upload button
                  <Flex variant="colS">
                    <FileInput
                      variant="secondary"
                      onChange={e => handleFileChange( e, item.value )}>
                      Chose a file to upload
                    </FileInput>
                    {fileNames[item.value] !== undefined && (
                      <Flex variant="rowS" align="center">
                        <Text variant="label">Uploaded File: {fileNames[item.value]}</Text>
                        <Button 
                          variant="dangerSmallLeftIcon" 
                          onClick={() => removeUploadedFile( item.value )}
                          style={{
                            padding : '4px 8px',
                            fontSize : '14px'
                          }}>
                          Remove
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                )}
              </Flex>
            ) )}
          </Flex>
          <div style={{ padding : '10px 16px 10px 16px' }}>
            <Flex
              alignSelf="flex-end"
              style={dialogFooterBody}>
              <Flex>
                <Button
                  variant="secondary"
                  onClick={onClose}>
                  Cancel
                </Button>
              </Flex>
              <Flex>
                <Button 
                  onClick={onSubmit}
                  disabled={!isUploadButtonEnabled}>
                  Upload
                </Button> 
              </Flex>
            </Flex>
          </div>
        </Flex>
      </Pane>
    </Dialog>
  )
}

export default UploadNewFirmwareMenu
