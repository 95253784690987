import React from 'react'
import { Content, Dialog, Flex, Button, Text, Pane } from '@leverege/ui-elements'

function ConfirmationDialog( props ) {
  const {
    headerText,
    bodyText,
    show,
    onConfirm = () => {},
    onDeny = () => {}
  } = props

  return (
    <Dialog show={show}>
      <Content relative>
        <Pane variant="contentSection" layout="flex:colSCenter">
          <Text variant="dialogTitle">{ headerText }</Text>
          <Text variant="body">{ bodyText }</Text>
        </Pane>
        <Pane variant="contentSection" layout="flex:colS">
          <Flex variant="colM">
            <Button variant="secondary" onClick={onConfirm}>Yes</Button>
            <Button variant="secondary" onClick={onDeny}>No</Button>
          </Flex>
        </Pane>
      </Content>
    </Dialog>
  )

}

export default ConfirmationDialog
