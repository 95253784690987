import { LinkAction } from '@leverege/molten/routes'

export default LinkAction.create( {
  name : 'Events Log', 
  id : 'boat.link.EventsLog',
  icon : 'fa-solid fa-bell',
  layout : {
    sort : 'c.9'
  },
  matches : {
    client : 'ItemScreen',
    use : 'navBar',
    objectType : 'boat',
    path : 'boat'
  },
  path : '/p/:persona/boat/:boatId/eventsLog',
} )
