module.exports = {
  "hierarchy": {
    "title": [
      "text-title"
    ],
    "subtitle": [
      "text-subtitle"
    ],
    "body": [
      "text-body"
    ],
    "bodySmall": [
      "text-bodySmall"
    ],
    "caption": [
      "text-caption"
    ],
    "captionSmall": [
      "text-captionSmall"
    ],
    "navLogoLabel": [
      "text-subtitle",
      "text-navLogoLabel"
    ],
    "pageTitle": [
      "text-title",
      "text-pageTitle"
    ],
    "sectionTitle": [
      "text-title",
      "text-sectionTitle"
    ],
    "sectionBody": [
      "text-bodySmall",
      "text-sectionBody"
    ],
    "cardTitle": [
      "text-subtitle",
      "text-cardTitle"
    ],
    "formTitle": [
      "text-title",
      "text-formTitle"
    ],
    "formSubtitle": [
      "text-subtitle",
      "text-formSubtitle"
    ],
    "formBody": [
      "text-body",
      "text-formBody"
    ],
    "listItemTitle": [
      "text-subtitle",
      "text-listItemTitle"
    ],
    "listItemSubtitle": [
      "text-caption",
      "text-listItemSubtitle"
    ],
    "inputCaptionSmall": [
      "text-captionSmall",
      "text-inputCaptionSmall"
    ],
    "inputCaptionSmallError": [
      "text-captionSmall",
      "text-inputCaptionSmall",
      "text-inputCaptionSmallError"
    ],
    "loginWarning": [
      "text-bodySmall",
      "text-loginWarning"
    ],
    "mobileMapTitle": [
      "text-bodySmall",
      "text-mobileMapTitle"
    ],
    "mobileTitle": [
      "text-bodySmall",
      "text-mobileTitle"
    ],
    "captionBold": [
      "text-captionBold"
    ],
    "captionSmallBold": [
      "text-captionSmallBold"
    ],
    "toastTitle": [
      "text-subtitle",
      "text-toastTitle"
    ],
    "toastMsg": [
      "text-caption",
      "text-toastMsg"
    ],
    "detailTitle": [
      "text-subtitle",
      "text-detailTitle"
    ],
    "detailLabel": [
      "text-bodySmall",
      "text-detailLabel"
    ],
    "detailValue": [
      "text-bodySmall",
      "text-detailValue"
    ],
    "detailSuffix": [
      "text-caption",
      "text-detailSuffix"
    ],
    "screenTitle": [
      "text-title",
      "text-screenTitle"
    ],
    "dialogTitle": [
      "text-title",
      "text-formTitle",
      "text-dialogTitle"
    ],
    "sideNav": [
      "text-sideNav"
    ],
    "loginLogo": [
      "text-loginLogo"
    ],
    "listDetailTitle": [
      "text-subtitle",
      "text-detailTitle",
      "text-listDetailTitle"
    ],
    "mobileUserNavBar": [
      "text-mobileUserNavBar"
    ],
    "groupTitle": [
      "text-captionSmall",
      "text-groupTitle"
    ],
    "dashboardCardTitle": [
      "text-subtitle",
      "text-dashboardCardTitle"
    ],
    "dashboardCardEditorTitle": [
      "text-subtitle",
      "text-dashboardCardTitle",
      "text-dashboardCardEditorTitle"
    ],
    "rootEditorTitle": [
      "text-title",
      "text-formTitle",
      "text-dialogTitle",
      "text-rootEditorTitle"
    ],
    "actionBar": [
      "text-actionBar"
    ],
    "sideNavBarHeader": [
      "text-captionBold",
      "text-sideNavBarHeader"
    ],
    "networkOffline": [
      "text-networkOffline"
    ],
    "sideNavSmall": [
      "text-sideNav",
      "text-sideNavSmall"
    ],
    "linearViewHeaderCell1": [
      "text-linearViewHeaderCell1"
    ],
    "linearViewHeaderCell2": [
      "text-linearViewHeaderCell2"
    ],
    "pairingErrorSubtitle": [
      "text-pairingErrorSubtitle"
    ],
    "bodyCentered": [
      "text-bodyCentered"
    ],
    "resendSuccess": [
      "text-resendSuccess"
    ],
    "twoFactorWarning": [
      "text-twoFactorWarning"
    ],
    "pill": [
      "text-pill"
    ],
    "pillPrimary": [
      "text-pill",
      "text-pillPrimary"
    ],
    "pillSuccess": [
      "text-pill",
      "text-pillSuccess"
    ],
    "pillWarning": [
      "text-pill",
      "text-pillWarning"
    ],
    "pillError": [
      "text-pill",
      "text-pillError"
    ],
    "resendCodeSuccess": [
      "text-resendCodeSuccess"
    ],
    "twoStepTitle": [
      "text-twoStepTitle"
    ],
    "hint": [
      "text-hint"
    ],
    "columnValue": [
      "text-columnLabel",
      "text-columnValue"
    ],
    "columnLabel": [
      "text-columnLabel"
    ],
    "conditionTitle": [
      "text-bodySmall",
      "text-conditionTitle"
    ],
    "missingRelTitle": [
      "text-missingRelTitle"
    ],
    "expressionParen": [
      "text-expressionParen"
    ],
    "operatingHoursTitle": [
      "text-operatingHoursTitle"
    ],
    "hintSmall": [
      "text-hint",
      "text-hintSmall"
    ],
    "aggregationTitle": [
      "text-bodySmall",
      "text-detailLabel",
      "text-aggregationTitle"
    ],
    "aggregationLabel": [
      "text-aggregationLabel"
    ],
    "aggregationValue": [
      "text-subtitle",
      "text-aggregationValue"
    ],
    "overrideExample": [
      "text-overrideExample"
    ],
    "editorTitle": [
      "text-title",
      "text-editorTitle"
    ],
    "conditionAttributeLabel": [
      "text-conditionAttributeLabel"
    ],
    "info": [
      "text-twoFactorWarning",
      "text-info"
    ],
    "conditionPropertyComparatorValue": [
      "text-conditionPropertyComparatorValue"
    ],
    "comparator": [
      "text-comparator"
    ],
    "tagError": [
      "text-pill",
      "text-pillError",
      "text-tagError"
    ],
    "captionCurrent": [
      "text-caption",
      "text-captionCurrent"
    ]
  },
  "props": {
    "default": {},
    "title": {},
    "subtitle": {},
    "body": {},
    "bodySmall": {},
    "caption": {
      "imageOpts": {
        "width": "24px"
      }
    },
    "captionSmall": {},
    "navLogoLabel": {},
    "pageTitle": {},
    "sectionTitle": {},
    "sectionBody": {},
    "cardTitle": {},
    "formTitle": {},
    "formSubtitle": {},
    "formBody": {},
    "listItemTitle": {},
    "listItemSubtitle": {
      "imageOpts": {
        "width": "24px"
      }
    },
    "inputCaptionSmall": {},
    "inputCaptionSmallError": {},
    "loginWarning": {},
    "mobileMapTitle": {},
    "mobileTitle": {},
    "captionBold": {},
    "captionSmallBold": {},
    "toastTitle": {},
    "toastMsg": {
      "imageOpts": {
        "width": "24px"
      }
    },
    "detailTitle": {
      "iconOpts": {
        "style": {
          "fontSize": ""
        }
      }
    },
    "detailLabel": {},
    "detailValue": {},
    "detailSuffix": {
      "imageOpts": {
        "width": "24px"
      }
    },
    "screenTitle": {
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "dialogTitle": {
      "imageOpts": {
        "height": "32"
      }
    },
    "sideNav": {
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "loginLogo": {
      "icon": "https://molten-ui-assets.storage.googleapis.com/leverege-logo.svg",
      "imageOpts": {
        "height": "40px",
        "width": ""
      }
    },
    "listDetailTitle": {
      "iconOpts": {
        "style": {
          "fontSize": ""
        }
      }
    },
    "mobileUserNavBar": {
      "imageOpts": {
        "height": "24px",
        "width": "24px"
      }
    },
    "groupTitle": {},
    "dashboardCardTitle": {},
    "dashboardCardEditorTitle": {},
    "rootEditorTitle": {
      "imageOpts": {
        "height": "32"
      }
    },
    "actionBar": {},
    "sideNavBarHeader": {},
    "networkOffline": {},
    "sideNavSmall": {
      "imageOpts": {
        "width": "0"
      }
    },
    "linearViewHeaderCell1": {},
    "linearViewHeaderCell2": {},
    "pairingErrorSubtitle": {},
    "bodyCentered": {},
    "resendSuccess": {
      "icon": "https://molten-ui-assets.storage.googleapis.com/two-step-success.png",
      "imageOpts": {
        "height": "18px",
        "width": "18px"
      }
    },
    "twoFactorWarning": {
      "icon": "lv lv-system-error",
      "iconOpts": {
        "style": {
          "color": "#E9354D",
          "fontSize": "18px"
        }
      }
    },
    "pill": {},
    "pillPrimary": {},
    "pillSuccess": {},
    "pillWarning": {},
    "pillError": {},
    "resendCodeSuccess": {
      "icon": "@icon:success",
      "iconOpts": {
        "style": {
          "color": "#15b982",
          "fontSize": "20px"
        }
      }
    },
    "twoStepTitle": {},
    "hint": {},
    "columnValue": {},
    "columnLabel": {},
    "conditionTitle": {},
    "missingRelTitle": {},
    "expressionParen": {},
    "operatingHoursTitle": {},
    "hintSmall": {},
    "aggregationTitle": {},
    "aggregationLabel": {},
    "aggregationValue": {},
    "overrideExample": {},
    "editorTitle": {},
    "conditionAttributeLabel": {},
    "info": {
      "icon": "@icon:info-filled",
      "iconOpts": {
        "style": {
          "color": "#0d49e4",
          "fontSize": "14px"
        }
      }
    },
    "conditionPropertyComparatorValue": {},
    "comparator": {},
    "tagError": {},
    "captionCurrent": {
      "imageOpts": {
        "width": "24px"
      }
    }
  }
}