// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiSelector_Mxp6a {
  border: 1px solid var( --color-base200 );
  padding-left: 0.35em;
  padding-right: 0.35em;
  padding-bottom: var( --size-padSmall );
  background-color: var( --color-white );
}
.multiSelector_Mxp6a .tag_u5Trq {
  border: 1px solid var( --color-base100 );
  padding: 0px 0.35em;
  font-size: var( --size-fontButtonSmall );
  margin-top: var( --size-padSmall );
  margin-left: var( --size-padSmall );
  border-radius: var( --size-padSmall );
  background-color: var( --color-base050 );
}
.multiSelector_Mxp6a.multiSelector-conditionText_Ow4_M {
  min-width: 250px;
  max-width: 300px;
}
.multiSelector_Mxp6a.multiSelector-conditionTags_He85x.multiSelector-comparator_O8W2H {
  min-height: 38px;
}`, "",{"version":3,"sources":["webpack://./src/theme/MultiSelector.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,oBAAoB;EACpB,qBAAqB;EACrB,sCAAsC;EACtC,sCAAsC;AACxC;AACA;EACE,wCAAwC;EACxC,mBAAmB;EACnB,wCAAwC;EACxC,kCAAkC;EAClC,mCAAmC;EACnC,qCAAqC;EACrC,wCAAwC;AAC1C;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".multiSelector {\n  border: 1px solid var( --color-base200 );\n  padding-left: 0.35em;\n  padding-right: 0.35em;\n  padding-bottom: var( --size-padSmall );\n  background-color: var( --color-white );\n}\n.multiSelector .tag {\n  border: 1px solid var( --color-base100 );\n  padding: 0px 0.35em;\n  font-size: var( --size-fontButtonSmall );\n  margin-top: var( --size-padSmall );\n  margin-left: var( --size-padSmall );\n  border-radius: var( --size-padSmall );\n  background-color: var( --color-base050 );\n}\n.multiSelector.multiSelector-conditionText {\n  min-width: 250px;\n  max-width: 300px;\n}\n.multiSelector.multiSelector-conditionTags.multiSelector-comparator {\n  min-height: 38px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiSelector": `multiSelector_Mxp6a`,
	"tag": `tag_u5Trq`,
	"multiSelector-conditionText": `multiSelector-conditionText_Ow4_M`,
	"multiSelector-conditionTags": `multiSelector-conditionTags_He85x`,
	"multiSelector-comparator": `multiSelector-comparator_O8W2H`
};
export default ___CSS_LOADER_EXPORT___;
