module.exports = {
  "hierarchy": {
    "inputTitle": [
      "label-inputTitle"
    ],
    "checkboxTitle": [
      "label-inputTitle",
      "label-checkboxTitle"
    ],
    "inputCaption": [
      "label-inputCaption"
    ],
    "input": [
      "label-inputTitle",
      "label-input"
    ],
    "touchToolbarLabel": [
      "label-touchToolbarLabel"
    ],
    "checkboxLabel": [
      "label-checkboxLabel"
    ],
    "mobileUserNavBarNested": [
      "label-mobileUserNavBarNested"
    ],
    "checkboxLabelWrap": [
      "label-checkboxLabelWrap"
    ],
    "subtitle": [
      "label-subtitle"
    ],
    "captionSmallBold": [
      "label-captionSmallBold"
    ]
  },
  "props": {
    "default": {},
    "inputTitle": {},
    "checkboxTitle": {},
    "inputCaption": {},
    "input": {},
    "touchToolbarLabel": {},
    "checkboxLabel": {},
    "mobileUserNavBarNested": {},
    "checkboxLabelWrap": {},
    "subtitle": {},
    "captionSmallBold": {}
  }
}