/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'

import { useSelector } from 'react-redux'

function Component() {
  const { globalstarSubscriptionInfo } = useSelector( state => state.ui )
  
  return (
    <div>{globalstarSubscriptionInfo?.anniversaryDate ? 'Valid' : '-'}</div>
  )
}

export default {
  objectType : 'boat',
  attrName : 'Globalstar Subscription Status',
  renderer : () => React.createElement( Component )
} 
