import React from 'react'
import DailyReportsScreen from './DailyReportsScreen'

export default {
  id : 'siren.dailyReports',
  matches : { client : 'Main' },
  exact : true,
  path : '/daily-reports/',
  default : false,
  render : ctx => React.createElement( DailyReportsScreen, ctx )
}  
