import { LinkAction } from '@leverege/molten/routes'

export default LinkAction.create( {
  name : 'Comments', 
  id : 'boat.link.Comments',
  icon : 'fa-solid fa-book-user',
  layout : {
    sort : 'c.5'
  },
  matches : {
    client : 'ItemScreen',
    use : 'navBar',
    objectType : 'boat',
    path : 'boat'
  },
  path : '/p/:persona/boat/:boatId/comments',
} )
