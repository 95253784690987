import FirmwareSendVersionAction from './FirmwareSendVersionAction'

const install = ( molten ) => {
  molten.addPlugin( 'Action', FirmwareSendVersionAction )

}

export default {
  install,
}
