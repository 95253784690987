import { GlobalState } from '@leverege/ui-redux'

const allowedGroupDataViewers = [ 'map', 'table', 'responsiveTable', 'list' ]

export default function actionPersonaMutator( type, plugin, molten ) {

  if ( type === 'GroupDataViewer' && !allowedGroupDataViewers.includes( plugin.type ) ) {
    console.log( 'ActionPersonaMutator hiding plugin for non-admin: ', plugin )
    return {
      ...plugin,
      handles : ( context ) => {
        return GlobalState.get()?.ui?.activePersona?.name === 'default' && plugin.handles( context )
      }
    }
  }

  if ( type !== 'Action' ) {
    return plugin
  }
  const { id } = plugin

  const mutate = molten.getConfig( 'actions' )?.[id]?.hideForNonAdmin
  if ( mutate ) {
    console.log( 'ActionPersonaMutator hiding plugin for non-admin: ', plugin )
    return { 
      ...plugin,
      handles : ( context ) => {
        // hide for all but steve on mobile
        // const { clientProps : { mobile } } = context
        const hasDefault = !!GlobalState.get()?.ui?.personas?.some( p => p.name === 'default' )
        // only non-custom persona user's when on mobile
        return hasDefault && plugin.handles( context )
      }
    }
  }
  return plugin
}
