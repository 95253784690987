import UploadNewFirmwareAction from './UploadNewFirmwareAction'
import QuickFilterFirmwareAction from './QuickFilterFirmwareAction'
import OtaFlagScreenRoute from './OtaFlagScreenRoute'
import OtaFlagScreenLink from './OtaFlagScreenLink'

const install = ( molten ) => {
  molten.addPlugin( 'Action', UploadNewFirmwareAction )
  molten.addPlugin( 'Action', QuickFilterFirmwareAction )
  if ( process.env.HAS_OTA_FLAG_PAGE === 'true' ) {
    molten.addPlugin( 'Route', OtaFlagScreenRoute )
    molten.addPlugin( 'Action', OtaFlagScreenLink )
  }
}

export default {
  install
}
