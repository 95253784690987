// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast_d4U9r {
  font: normal 15px/24px Inter;
  color: var( --color-base700 );
  border-top: 0px solid var( --color-base100 );
  box-shadow: 0 4px 8px 1px var( --color-shadowBase );
  border-left: 0px solid var( --color-base100 );
  border-right: 0px solid var( --color-base100 );
  padding-left: var( --size-padLarge );
  border-bottom: 5px solid var( --color-base100 );
  border-radius: 4px;
  padding-right: var( --size-padMedium );
}
.toast_d4U9r > .Toastify__progress-bar {
  background: none;
}
.toast_d4U9r.toast-success_SLv1U {
  background: var( --color-white );
  border-top-color: var( --color-success500 );
  border-left-color: var( --color-success500 );
  border-right-color: var( --color-success500 );
  border-bottom-color: var( --color-success500 );
}
.toast_d4U9r.toast-error_pWcnE {
  background: var( --color-white );
  border-top-color: var( --color-danger500 );
  border-left-color: var( --color-danger500 );
  border-right-color: var( --color-danger500 );
  border-bottom-color: var( --color-danger500 );
}
.toast_d4U9r.toast-warn_uWcaX {
  background: var( --color-white );
  border-top-color: var( --color-warning500 );
  border-left-color: var( --color-warning500 );
  border-right-color: var( --color-warning500 );
  border-bottom-color: var( --color-warning500 );
}
.toast_d4U9r.toast-info_rpx0E {
  border-bottom-color: var( --color-brand500 );
}`, "",{"version":3,"sources":["webpack://./src/theme/Toast.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,6BAA6B;EAC7B,4CAA4C;EAC5C,mDAAmD;EACnD,6CAA6C;EAC7C,8CAA8C;EAC9C,oCAAoC;EACpC,+CAA+C;EAC/C,kBAAkB;EAClB,sCAAsC;AACxC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gCAAgC;EAChC,2CAA2C;EAC3C,4CAA4C;EAC5C,6CAA6C;EAC7C,8CAA8C;AAChD;AACA;EACE,gCAAgC;EAChC,0CAA0C;EAC1C,2CAA2C;EAC3C,4CAA4C;EAC5C,6CAA6C;AAC/C;AACA;EACE,gCAAgC;EAChC,2CAA2C;EAC3C,4CAA4C;EAC5C,6CAA6C;EAC7C,8CAA8C;AAChD;AACA;EACE,4CAA4C;AAC9C","sourcesContent":[".toast {\n  font: normal 15px/24px Inter;\n  color: var( --color-base700 );\n  border-top: 0px solid var( --color-base100 );\n  box-shadow: 0 4px 8px 1px var( --color-shadowBase );\n  border-left: 0px solid var( --color-base100 );\n  border-right: 0px solid var( --color-base100 );\n  padding-left: var( --size-padLarge );\n  border-bottom: 5px solid var( --color-base100 );\n  border-radius: 4px;\n  padding-right: var( --size-padMedium );\n}\n.toast > :global( .Toastify__progress-bar ) {\n  background: none;\n}\n.toast.toast-success {\n  background: var( --color-white );\n  border-top-color: var( --color-success500 );\n  border-left-color: var( --color-success500 );\n  border-right-color: var( --color-success500 );\n  border-bottom-color: var( --color-success500 );\n}\n.toast.toast-error {\n  background: var( --color-white );\n  border-top-color: var( --color-danger500 );\n  border-left-color: var( --color-danger500 );\n  border-right-color: var( --color-danger500 );\n  border-bottom-color: var( --color-danger500 );\n}\n.toast.toast-warn {\n  background: var( --color-white );\n  border-top-color: var( --color-warning500 );\n  border-left-color: var( --color-warning500 );\n  border-right-color: var( --color-warning500 );\n  border-bottom-color: var( --color-warning500 );\n}\n.toast.toast-info {\n  border-bottom-color: var( --color-brand500 );\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": `toast_d4U9r`,
	"toast-success": `toast-success_SLv1U`,
	"toast-error": `toast-error_pWcnE`,
	"toast-warn": `toast-warn_uWcaX`,
	"toast-info": `toast-info_rpx0E`
};
export default ___CSS_LOADER_EXPORT___;
