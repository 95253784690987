module.exports = {
  "hierarchy": {
    "overlay": [
      "pane-overlay"
    ],
    "navHeader": [
      "pane-navHeader"
    ],
    "loginPane": [
      "pane-loginPane"
    ],
    "contentSection": [
      "pane-contentSection"
    ],
    "contentBorder": [
      "pane-contentSection",
      "pane-contentBorder"
    ],
    "formHeader": [
      "pane-formHeader"
    ],
    "formBody": [
      "pane-formBody"
    ],
    "formButtons": [
      "pane-formHeader",
      "pane-formButtons"
    ],
    "dialogHeader": [
      "pane-dialogHeader"
    ],
    "dialogBody": [
      "pane-dialogBody"
    ],
    "dialogFooter": [
      "pane-dialogFooter"
    ],
    "dialog": [
      "pane-dialog"
    ],
    "loginWarning": [
      "pane-loginWarning"
    ],
    "formGeoshape": [
      "pane-formGeoshape"
    ],
    "contentSectionWhite": [
      "pane-contentSection",
      "pane-contentSectionWhite"
    ],
    "card": [
      "pane-card"
    ],
    "mobileTabPortal": [
      "pane-mobileTabPortal"
    ],
    "mobileActions": [
      "pane-mobileActions"
    ],
    "mobileTabsHeader": [
      "pane-mobileTabsHeader"
    ],
    "mobileList": [
      "pane-mobileList"
    ],
    "mobileMapToggle": [
      "pane-mobileMapToggle"
    ],
    "mobileItemHeader": [
      "pane-mobileItemHeader"
    ],
    "mobileItemBody": [
      "pane-mobileItemBody"
    ],
    "mobileListSettings": [
      "pane-mobileList",
      "pane-mobileListSettings"
    ],
    "mobileDtrHeader": [
      "pane-mobileTabsHeader",
      "pane-mobileDtrHeader"
    ],
    "mobileDtrContent": [
      "pane-mobileDtrContent"
    ],
    "sidebar": [
      "pane-sidebar"
    ],
    "tablePagination": [
      "pane-tablePagination"
    ],
    "editPageSizeOverlay": [
      "pane-editPageSizeOverlay"
    ],
    "rootHeader": [
      "pane-rootHeader"
    ],
    "dataCard": [
      "pane-dataCard"
    ],
    "graphContainer": [
      "pane-card",
      "pane-graphContainer"
    ],
    "detailCard": [
      "pane-detail",
      "pane-detailCard"
    ],
    "detailHeader": [
      "pane-dialogHeader",
      "pane-detailHeader"
    ],
    "detailContent": [
      "pane-detailContent"
    ],
    "detailFooter": [
      "pane-detailFooter"
    ],
    "collectionEditorListSection": [
      "pane-collectionEditorListSection"
    ],
    "collectionEditorListHeader": [
      "pane-collectionEditorListHeader"
    ],
    "collectionEditorListContent": [
      "pane-collectionEditorListContent"
    ],
    "collectionEditorListFooter": [
      "pane-collectionEditorListFooter"
    ],
    "collectionEditorSelectionSection": [
      "pane-collectionEditorSelectionSection"
    ],
    "collectionEditorSelectionHeader": [
      "pane-collectionEditorSelectionHeader"
    ],
    "collectionEditorSelectionContent": [
      "pane-collectionEditorSelectionContent"
    ],
    "collectionEditorSelectionFooter": [
      "pane-collectionEditorSelectionFooter"
    ],
    "dialogContent": [
      "pane-dialogBody",
      "pane-dialogContent"
    ],
    "rootEditorContent": [
      "pane-rootEditorContent"
    ],
    "editorDialog": [
      "pane-editorDialog"
    ],
    "editor": [
      "pane-editor"
    ],
    "formFooter": [
      "pane-formFooter"
    ],
    "rootEditor": [
      "pane-rootEditor"
    ],
    "screenTitle": [
      "pane-screenTitle"
    ],
    "topNav": [
      "pane-topNav"
    ],
    "tableFilter": [
      "pane-tableFilter"
    ],
    "sideNav": [
      "pane-sideNav"
    ],
    "screenContent": [
      "pane-screenContent"
    ],
    "actionBar": [
      "pane-actionBar"
    ],
    "aggregation": [
      "pane-aggregation"
    ],
    "dialogTitle": [
      "pane-dialogTitle"
    ],
    "rootEditorHeader": [
      "pane-rootEditorHeader"
    ],
    "rootEditorFooter": [
      "pane-rootEditorFooter"
    ],
    "collectionEditor": [
      "pane-collectionEditor"
    ],
    "listDetail": [
      "pane-detail",
      "pane-listDetail"
    ],
    "loginBackground": [
      "pane-loginBackground"
    ],
    "mobileDetail": [
      "pane-mobileDetail"
    ],
    "cowList": [
      "pane-cowList"
    ],
    "navSecondaryMobile": [
      "pane-navSecondaryMobile"
    ],
    "mapActionBar": [
      "pane-mapActionBar"
    ],
    "mobileMainNav": [
      "pane-mobileMainNav"
    ],
    "mobileNavRoutes": [
      "pane-mobileNavRoutes"
    ],
    "mobileNavUser": [
      "pane-mobileNavUser"
    ],
    "dashboardCardEditor": [
      "pane-dashboardCardEditor"
    ],
    "dashboardCardEditorHeader": [
      "pane-dashboardCardEditorHeader"
    ],
    "dashboardCardEditorArea": [
      "pane-dashboardCardEditorArea"
    ],
    "itemDashboard": [
      "pane-itemDashboard"
    ],
    "dashboardPlaceholderCard": [
      "pane-dashboardPlaceholderCard"
    ],
    "dashboardPlaceholderCardHighlight": [
      "pane-dashboardPlaceholderCard",
      "pane-dashboardPlaceholderCardHighlight"
    ],
    "dashboardNoData": [
      "pane-dashboardNoData"
    ],
    "dashboardCardHeader": [
      "pane-dashboardCardEditorHeader",
      "pane-dashboardCardHeader"
    ],
    "dashboardCardArea": [
      "pane-dashboardCardArea"
    ],
    "dashboardCard": [
      "pane-dashboardCardEditor",
      "pane-dashboardCard"
    ],
    "dashboardTransparentCard": [
      "pane-dashboardCardEditor",
      "pane-dashboardCard",
      "pane-dashboardTransparentCard"
    ],
    "dashboardTransparentCardEditor": [
      "pane-dashboardCardEditor",
      "pane-dashboardTransparentCardEditor"
    ],
    "dashboardGridCard": [
      "pane-dashboardCardEditor",
      "pane-dashboardCard",
      "pane-dashboardGridCard"
    ],
    "dashboardCardEditorTarget": [
      "pane-dashboardCardEditor",
      "pane-dashboardCardEditorTarget"
    ],
    "suggestions": [
      "pane-suggestions"
    ],
    "itemForm": [
      "pane-itemForm"
    ],
    "mobileItemForm": [
      "pane-mobileItemForm"
    ],
    "mobileItemFormBody": [
      "pane-formBody",
      "pane-mobileItemFormBody"
    ],
    "mobileItemFormHeader": [
      "pane-formHeader",
      "pane-mobileItemFormHeader"
    ],
    "navSecondaryMobileContainer": [
      "pane-navSecondaryMobileContainer"
    ],
    "mobileSearchHeader": [
      "pane-mobileSearchHeader"
    ],
    "mobileMainHeader": [
      "pane-mobileMainHeader"
    ],
    "dashboardGridCardEditorArea": [
      "pane-dashboardGridCardEditorArea"
    ],
    "itemDashboardContent": [
      "pane-itemDashboardContent"
    ],
    "actionBarDashboard": [
      "pane-actionBarDashboard"
    ],
    "aggregationItem": [
      "pane-aggregationItem"
    ],
    "mobileSimpleSearchArea": [
      "pane-mobileSimpleSearchArea"
    ],
    "itemFormHeader": [
      "pane-itemFormHeader"
    ],
    "navBar": [
      "pane-navBar"
    ],
    "embededScreenHeader": [
      "pane-rootEditorHeader",
      "pane-embededScreenHeader"
    ],
    "attributeTableInlineEditor": [
      "pane-attributeTableInlineEditor"
    ],
    "navBarDivider": [
      "pane-navBarDivider"
    ],
    "sideNavBarDivider": [
      "pane-sideNavBarDivider"
    ],
    "dashboardGridCardEditorHeader": [
      "pane-dashboardGridCardEditorHeader"
    ],
    "linearViewHeaderCell1": [
      "pane-linearViewHeaderCell1"
    ],
    "linearViewHeaderCell2": [
      "pane-linearViewHeaderCell2"
    ],
    "linearViewBlock": [
      "pane-linearViewBlock"
    ],
    "linearViewBlockTooltip": [
      "pane-dialog",
      "pane-linearViewBlockTooltip"
    ],
    "linearViewRow": [
      "pane-linearViewRow"
    ],
    "pairContent": [
      "pane-pairContent"
    ],
    "imageDetailValue": [
      "pane-imageDetailValue"
    ],
    "dashboardTransparentCardArea": [
      "pane-dashboardTransparentCardArea"
    ],
    "dashboardGridCardArea": [
      "pane-dashboardGridCardArea"
    ],
    "twoStep": [
      "pane-dialog",
      "pane-twoStep"
    ],
    "twoStepHeader": [
      "pane-twoStepHeader"
    ],
    "twoStepDialogTitle": [
      "pane-dialogTitle",
      "pane-twoStepDialogTitle"
    ],
    "twoStepFooter": [
      "pane-dialogFooter",
      "pane-twoStepFooter"
    ],
    "twoStepContent": [
      "pane-dialogBody",
      "pane-dialogContent",
      "pane-twoStepContent"
    ],
    "twoStepMethodsCard": [
      "pane-twoStepMethodsCard"
    ],
    "securityScreenContent": [
      "pane-screenContent",
      "pane-securityScreenContent"
    ],
    "twoStepMobileContent": [
      "pane-dialogBody",
      "pane-dialogContent",
      "pane-twoStepContent",
      "pane-twoStepMobileContent"
    ],
    "twoStepMobile": [
      "pane-dialog",
      "pane-twoStep",
      "pane-twoStepMobile"
    ],
    "rememberMeInfo": [
      "pane-rememberMeInfo"
    ],
    "expiredActivationToken": [
      "pane-loginPane",
      "pane-expiredActivationToken"
    ],
    "radio": [
      "pane-radio"
    ],
    "twoStepRadioContainer": [
      "pane-twoStepRadioContainer"
    ],
    "xyChartEditor": [
      "pane-xyChartEditor"
    ],
    "dashboardTransparentCardHeader": [
      "pane-dashboardTransparentCardHeader"
    ],
    "mobileOptBarDivider": [
      "pane-mobileOptBarDivider"
    ],
    "mobileOptNavTitle": [
      "pane-mobileOptNavTitle"
    ],
    "mobileOptNav": [
      "pane-mobileOptNav"
    ],
    "mobileNavTitle": [
      "pane-mobileNavTitle"
    ],
    "sortFilterBar": [
      "pane-screenTitle",
      "pane-sortFilterBar"
    ],
    "dashboardGridCardHeader": [
      "pane-dashboardGridCardHeader"
    ],
    "navSecondaryMobileTitle": [
      "pane-navSecondaryMobileTitle"
    ],
    "mobileTopNavBarDivider": [
      "pane-mobileTopNavBarDivider"
    ],
    "mobileFooterNav": [
      "pane-mobileFooterNav"
    ],
    "sortToggleBackground": [
      "pane-sortToggleBackground"
    ],
    "maintenanceEvent": [
      "pane-maintenanceEvent"
    ],
    "maintenanceEventDegradation": [
      "pane-maintenanceEvent",
      "pane-maintenanceEventDegradation"
    ],
    "maintenanceEventDowntime": [
      "pane-maintenanceEvent",
      "pane-maintenanceEventDowntime"
    ],
    "infoCard": [
      "pane-infoCard"
    ],
    "privacyNoticeContent": [
      "pane-privacyNoticeContent"
    ],
    "copyHoursWidget": [
      "pane-dialog",
      "pane-copyHoursWidget"
    ],
    "largeDialogBody": [
      "pane-dialogBody",
      "pane-largeDialogBody"
    ],
    "BAD": [
      "pane-BAD"
    ],
    "formSearchBar": [
      "pane-formSearchBar"
    ],
    "formBreakdownItem": [
      "pane-formBreakdownItem"
    ],
    "operatingHoursEditor": [
      "pane-operatingHoursEditor"
    ],
    "operatingHoursEditorContent": [
      "pane-operatingHoursEditorContent"
    ],
    "operatingHoursWeeklyGrid": [
      "pane-operatingHoursWeeklyGrid"
    ],
    "copyHoursWidgetHeader": [
      "pane-copyHoursWidgetHeader"
    ],
    "copyHoursWidgetContent": [
      "pane-copyHoursWidgetHeader",
      "pane-copyHoursWidgetContent"
    ],
    "copyHoursWidgetFooter": [
      "pane-copyHoursWidgetHeader",
      "pane-copyHoursWidgetFooter"
    ],
    "detailItemContent": [
      "pane-detailItemContent"
    ],
    "detail": [
      "pane-detail"
    ],
    "detailItemCard": [
      "pane-detailList",
      "pane-detailItemCard"
    ],
    "detailList": [
      "pane-detailList"
    ],
    "theftRecoveryCardContent": [
      "pane-theftRecoveryCardContent"
    ],
    "dialogFormBody": [
      "pane-dialogBody",
      "pane-dialogFormBody"
    ],
    "conditionEditor": [
      "pane-conditionEditor"
    ],
    "preConditionBar": [
      "pane-preConditionBar"
    ],
    "conditionBar": [
      "pane-conditionBar"
    ],
    "form": [
      "pane-form"
    ],
    "simpleFilterEditor": [
      "pane-simpleFilterEditor"
    ],
    "simpleFilterEditorWrapper": [
      "pane-simpleFilterEditorWrapper"
    ],
    "pill": [
      "pane-pill"
    ],
    "sortFilterBarHorizontalDivider": [
      "pane-sortFilterBarHorizontalDivider"
    ],
    "conditionsList": [
      "pane-conditionsList"
    ],
    "conditionFilterEditor": [
      "pane-conditionFilterEditor"
    ],
    "dashboardGridCardEditor": [
      "pane-dashboardGridCardEditor"
    ],
    "questionBody": [
      "pane-dialogBody",
      "pane-dialogContent",
      "pane-questionBody"
    ],
    "dashboardGridCardEditorAreaTarget": [
      "pane-dashboardGridCardEditorAreaTarget"
    ],
    "dashboardPlaceholderCardTarget": [
      "pane-dashboardPlaceholderCardTarget"
    ],
    "userEditorContent": [
      "pane-userEditorContent"
    ],
    "userEditorHeader": [
      "pane-userEditorHeader"
    ],
    "dialogBodyAlt": [
      "pane-dialogBodyAlt"
    ],
    "conditionRow": [
      "pane-conditionRow"
    ],
    "conditionRowEmpty": [
      "pane-conditionRow",
      "pane-conditionRowEmpty"
    ],
    "vehicleImageDetailValue": [
      "pane-vehicleImageDetailValue"
    ]
  },
  "props": {
    "default": {},
    "overlay": {},
    "navHeader": {},
    "loginPane": {},
    "contentSection": {},
    "contentBorder": {},
    "formHeader": {},
    "formBody": {},
    "formButtons": {},
    "dialogHeader": {},
    "dialogBody": {},
    "dialogFooter": {},
    "dialog": {},
    "loginWarning": {},
    "formGeoshape": {},
    "contentSectionWhite": {},
    "card": {},
    "mobileTabPortal": {},
    "mobileActions": {},
    "mobileTabsHeader": {},
    "mobileList": {},
    "mobileMapToggle": {},
    "mobileItemHeader": {},
    "mobileItemBody": {},
    "mobileListSettings": {},
    "mobileDtrHeader": {},
    "mobileDtrContent": {},
    "sidebar": {},
    "tablePagination": {},
    "editPageSizeOverlay": {},
    "rootHeader": {},
    "dataCard": {},
    "graphContainer": {},
    "detailCard": {},
    "detailHeader": {},
    "detailContent": {},
    "detailFooter": {},
    "collectionEditorListSection": {},
    "collectionEditorListHeader": {},
    "collectionEditorListContent": {},
    "collectionEditorListFooter": {},
    "collectionEditorSelectionSection": {},
    "collectionEditorSelectionHeader": {},
    "collectionEditorSelectionContent": {},
    "collectionEditorSelectionFooter": {},
    "dialogContent": {},
    "rootEditorContent": {},
    "editorDialog": {},
    "editor": {},
    "formFooter": {},
    "rootEditor": {},
    "screenTitle": {},
    "topNav": {},
    "tableFilter": {},
    "sideNav": {},
    "screenContent": {},
    "actionBar": {},
    "aggregation": {},
    "dialogTitle": {},
    "rootEditorHeader": {},
    "rootEditorFooter": {},
    "collectionEditor": {},
    "listDetail": {},
    "loginBackground": {},
    "mobileDetail": {},
    "cowList": {},
    "navSecondaryMobile": {},
    "mapActionBar": {},
    "mobileMainNav": {},
    "mobileNavRoutes": {},
    "mobileNavUser": {},
    "dashboardCardEditor": {},
    "dashboardCardEditorHeader": {},
    "dashboardCardEditorArea": {},
    "itemDashboard": {},
    "dashboardPlaceholderCard": {},
    "dashboardPlaceholderCardHighlight": {},
    "dashboardNoData": {},
    "dashboardCardHeader": {},
    "dashboardCardArea": {},
    "dashboardCard": {},
    "dashboardTransparentCard": {},
    "dashboardTransparentCardEditor": {},
    "dashboardGridCard": {},
    "dashboardCardEditorTarget": {},
    "suggestions": {},
    "itemForm": {},
    "mobileItemForm": {},
    "mobileItemFormBody": {},
    "mobileItemFormHeader": {},
    "navSecondaryMobileContainer": {},
    "mobileSearchHeader": {},
    "mobileMainHeader": {},
    "dashboardGridCardEditorArea": {},
    "itemDashboardContent": {},
    "actionBarDashboard": {},
    "aggregationItem": {},
    "mobileSimpleSearchArea": {},
    "itemFormHeader": {},
    "navBar": {},
    "embededScreenHeader": {},
    "attributeTableInlineEditor": {},
    "navBarDivider": {},
    "sideNavBarDivider": {},
    "dashboardGridCardEditorHeader": {},
    "linearViewHeaderCell1": {},
    "linearViewHeaderCell2": {},
    "linearViewBlock": {},
    "linearViewBlockTooltip": {},
    "linearViewRow": {},
    "pairContent": {},
    "imageDetailValue": {},
    "dashboardTransparentCardArea": {},
    "dashboardGridCardArea": {},
    "twoStep": {},
    "twoStepHeader": {},
    "twoStepDialogTitle": {},
    "twoStepFooter": {},
    "twoStepContent": {},
    "twoStepMethodsCard": {},
    "securityScreenContent": {},
    "twoStepMobileContent": {},
    "twoStepMobile": {},
    "rememberMeInfo": {},
    "expiredActivationToken": {},
    "radio": {},
    "twoStepRadioContainer": {},
    "xyChartEditor": {},
    "dashboardTransparentCardHeader": {},
    "mobileOptBarDivider": {},
    "mobileOptNavTitle": {},
    "mobileOptNav": {},
    "mobileNavTitle": {},
    "sortFilterBar": {},
    "dashboardGridCardHeader": {},
    "navSecondaryMobileTitle": {},
    "mobileTopNavBarDivider": {},
    "mobileFooterNav": {},
    "sortToggleBackground": {},
    "maintenanceEvent": {},
    "maintenanceEventDegradation": {},
    "maintenanceEventDowntime": {},
    "infoCard": {},
    "privacyNoticeContent": {},
    "copyHoursWidget": {},
    "largeDialogBody": {},
    "BAD": {},
    "formSearchBar": {},
    "formBreakdownItem": {},
    "operatingHoursEditor": {},
    "operatingHoursEditorContent": {},
    "operatingHoursWeeklyGrid": {},
    "copyHoursWidgetHeader": {},
    "copyHoursWidgetContent": {},
    "copyHoursWidgetFooter": {},
    "detailItemContent": {},
    "detail": {},
    "detailItemCard": {},
    "detailList": {},
    "theftRecoveryCardContent": {},
    "dialogFormBody": {},
    "conditionEditor": {},
    "preConditionBar": {},
    "conditionBar": {},
    "form": {},
    "simpleFilterEditor": {},
    "simpleFilterEditorWrapper": {},
    "pill": {},
    "sortFilterBarHorizontalDivider": {},
    "conditionsList": {},
    "conditionFilterEditor": {},
    "dashboardGridCardEditor": {},
    "questionBody": {},
    "dashboardGridCardEditorAreaTarget": {},
    "dashboardPlaceholderCardTarget": {},
    "userEditorContent": {},
    "userEditorHeader": {},
    "dialogBodyAlt": {},
    "conditionRow": {},
    "conditionRowEmpty": {},
    "vehicleImageDetailValue": {}
  }
}